import axiosInstance from '../api';

const api = '/doc-received-tasks';

const	headers = {
	headers: {
		Authorization: `Bearer ${sessionStorage.getItem('token')}`
	}
};

export const docReceivedTaskApi = {
	getList: (data) => axiosInstance.post(`${api}/list`, data, headers),
}
