import React, { useState } from 'react';
import { Form } from 'react-bootstrap';

import { suggestionApi } from '../../../services/suggestionService';

import Translate from '../../../components/lang';
import Loading from '../../../components/loading';
import CustomModal from '../../../components/customModal';

import ft from '../../../components/mainStyles.module.scss';

const Create = ({ lang, show, setShow, getList }) => {
    const [loading, setLoading] = useState(false);

    const onSubmit = (e) => {
       e.preventDefault();

       const { name, type, description } = e.target.elements;

       const data = {
          name: name.value,
          type: type.value,
          description: description.value
       };

       setLoading(true);

       suggestionApi
          .create(data)
          .then(res => {
             if (res.data.success) {
                getList();
                setShow(false);
             }
          })
          .catch(err => {
             console.log(err);
          })
          .finally(() => {
             setLoading(false);
          });
    };

    return (
      <CustomModal
        title={Translate(lang, 'global.add')}
        setShow={setShow}
        show={show}
        size='md'
      >
        <Form className={ft.form} onSubmit={onSubmit}>
          <Form.Label className='w-100'>
            <div className={ft.lab}>
              {Translate(lang,'global.name')}
            </div>
            <Form.Control name='name' type='text' required />
          </Form.Label>

           <Form.Label className='w-100'>
              <div className={ft.lab}>{Translate(lang, 'global.type')}</div>
              <select name='type'>
                 <option value='PROBLEM'>{Translate(lang, 'enum.problem')}</option>
                 <option value='SPACE'>{Translate(lang, 'enum.space')}</option>
                 <option value='CONFLICT'>{Translate(lang, 'enum.conflict')}</option>
              </select>
           </Form.Label>

          <Form.Label className='w-100'>
            <div className={ft.lab}>
              {Translate(lang, 'global.description')}
            </div>
            <Form.Control name='description' as='textarea' required />
          </Form.Label>

          <div className='d-flex justify-content-end mt-2'>
            <button
              className={`${ft.close__btn} mr-2`}
              onClick={() => setShow(false)}
              type='button'
            >
              {Translate(lang, 'global.cancel')}
            </button>

            <button className={ft.save__btn} type='submit'>
              {Translate(lang, 'global.save')}{' '}
              {loading ? <Loading cl='ml-1' size='sm' /> : ''}
            </button>
          </div>
        </Form>
      </CustomModal>
    );
};

export default Create;
