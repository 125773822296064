import axiosInstance from "./api";

const api = "/advocacy";
//advocacy/update/:id
const headers = {
    headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
}

export const legalStatusApi = {
    getAll: (data) => {
        return axiosInstance.post(`${api}/results-client `, data, headers)
    },
    all: (data) => {
        return axiosInstance.post(`${api}/all`, data, headers)
    },
    add: (data) => {
        return axiosInstance.post(`${api}/create`, data, headers)
    },
    del: id => {
        return axiosInstance.delete(`${api}/delete/${id}`, headers)
    },
    edit: (id, data) => {
        return axiosInstance.put(`${api}/update/${id}`, data, headers)
    },
    delFile: (id, file) => {
        return axiosInstance.put(`${api}/edit/${file}/${id}`, headers)
    },
    filter: (d) => {
        return axiosInstance.post(`/assignments/filter-client`, d, headers)
    },
    task: (id, data) => {
        return axiosInstance.put(`/assignments/create-response/${id}`, data, headers)
    }
}
