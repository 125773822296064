import ft from '../../../components/mainStyles.module.scss'
import {Form} from "react-bootstrap";
import CustomModal from "../../../components/customModal";
import Translate from "../../../components/lang";
import UploadFiles from "../../../components/uploadFiles";
import {useEffect, useState} from "react"
import {taskApi} from "../../../services/taskService";
import Loading from "../../../components/loading";

function TaskModal(props) {
    const [newFiles, setNewFiles] = useState([]),
        [prots, setProts] = useState([]),
        [load, setLoad] = useState(false),
        [errFiles, setErrFiles] = useState(false),
        [uploadedFiles, setUploadedFiles] = useState([]),
        [errFilesLength, setErrFilesLength] = useState(false),
        {lang, setLoading, setData, setShow, show, id, selPer, periods, setPageNum, setPc, page} = props;

    useEffect(() => {
        setProts([]);
        setNewFiles([]);
        setUploadedFiles([]);
    }, [show]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (uploadedFiles.length) {
            setLoad(true);
            setErrFiles(false);
            const file = [];

            uploadedFiles.map((f) => {
                file.push({file: f._id, path: f.path, size: f.size})
            });

            const data = {
                status: 1,
                fullDescription: e.target[0].value,
                respondFiles: file,
            };

            taskApi
                .send(id, data)
                .then(res => {
                    if (res.data.success) {
                        setLoad(false);
                        setLoading(true);
                        taskApi
                            .all({
                                limit: 200,
                                page: 1,
                                period: periods[selPer]?._id,
                                start: periods[selPer]?.start?.slice(0, 10),
                                end: periods[selPer]?.end?.slice(0, 10)
                            })
                            .then((res) => {
                                setPageNum(Math.ceil(res.data.num));
                                setPc((page.page - 1) * page.limit);
                                setData(res.data.data)
                                setLoading(false)
                            })
                            .catch((err) => {
                                console.log(err.message)
                                setLoading(false)
                            })
                        setShow(false);
                    }
                });
        } else setErrFiles(true);
    }

    return (
        <CustomModal
            show={show}
            setShow={setShow}
            size={"lg"}
        >

            <form action="" className="row" onSubmit={handleSubmit}>
                <div className={`col-7 ${ft.form}`}>
                    <Form.Label className="w-100" style={{marginTop: "-30px"}}>
                        <div className={ft.lab}>{Translate(lang, "global.fullInfo")}</div>
                        <Form.Control as="textarea" rows={4} required/>
                    </Form.Label>
                </div>

                <div className={`col-5 ${ft.form}`}>
                    <div className="mb-3 w-100">
                        <UploadFiles
                            {...props}
                            newFiles={newFiles}
                            setNewFiles={setNewFiles}
                            setErrFiles={setErrFiles}
                            setUploadedFiles={setUploadedFiles}
                            load={load}
                            setLoad={setLoad}
                            prots={prots}
                            setProts={setProts}
                            setErrFilesLength={setErrFilesLength}
                        />
                        {errFiles && (
                            <span className={ft.err__mes}>Fayl yoki rasm yuklanmadi!</span>
                        )}
                    </div>
                </div>
                <div className="col-12 d-flex justify-content-end mt-5">
                    <button type="button" className={ft.close__btn} onClick={() => setShow(false)}>
                        {Translate(lang, "global.cancel")}
                    </button>
                    <button className={`${ft.save__btn} ml-3`} type="submit">
                        {Translate(lang, "global.save")}{' '}
                        {load ? <Loading size='sm' cl='ml-1'/> : ''}
                    </button>
                </div>
            </form>

        </CustomModal>
    );
}

export default TaskModal;
