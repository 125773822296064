import ruDict from './ru.json'
import uzDict from './uz.json'
import ozDict from './oz.json'

export default function Translate(lang, langRoute) {
    let text;
    switch(lang){
        case 'ru':
            text = getText(ruDict, langRoute);
            break;
        case 'uz':
            text = getText(uzDict, langRoute);
            break;
        case 'oz':
            text = getText(ozDict, langRoute);
            break;
        default:
    }
    return text;
}

function getText(obj, keys){
    const textRoute = keys.split(".");
    let nested = {...obj};
    for(let i = 0; i < textRoute.length; i++){
        nested = nested[textRoute[i]];
    }
    return nested;
}