import st from './container.module.scss';

const Container = (props) => {
    return (
        <div className={`${st.mainContainer}`}>
            {props.children}
        </div>

    )
}

export default Container;