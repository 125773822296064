import st from './pagination.module.scss'

export default function Pagin(props) {
	const { pageNum, setPage, page } = props

	const handleSelect = (e, i) => {
		e.preventDefault()
		const items = document.querySelectorAll(`.${st.pagin__item}`)
		items.forEach((index) => {
			if (index.classList.contains(st.pagin__item__active)) {
				index.classList.remove(st.pagin__item__active)
			} else {
				index.classList.contains(st.pagin__item__active)
				e.target.classList.add(st.pagin__item__active)
			}
		})
		setPage({ ...page, page: i })
	}
	return (
		<div className={st.pagin}>
			<div
				onClick={() => {
					if (page.page !== 1) setPage((prev) => ({ ...prev, page: prev.page - 1 }))
				}}
				className={st.pagin__item}>
				{'<'}
			</div>

			{[...Array(pageNum)].map((b, i) => (
				<div
					className={`${st.pagin__item} ${page.page === i + 1 ? st.pagin__item__active : ''}`}
					onClick={(e) => handleSelect(e, i + 1)}
					key={i}
				>
					{i + 1}
				</div>
			))}
			<div
				style={{ marginRight: '4vw' }}
				onClick={() => {
					if (page.page !== pageNum) setPage((prev) => ({ ...prev, page: prev.page + 1 }))
				}}
				className={st.pagin__item}>
				{'>'}
			</div>
		</div>
	)
}
