import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';

import { suggestionApi } from '../../services/suggestionService';

import Del from '../../components/del';
import Filter from '../../components/filter';
import Translate from '../../components/lang';
import Loading from '../../components/loading';
import Container from '../../components/container';
import Pagination from '../../components/pagination';

import { edit, del } from '../../components/icons';

import { BeautyFullTime } from '../../utils';

import Create from './components/Create';
import Update from './components/Update';

import ft from '../../components/mainStyles.module.scss';
import cls from './suggestion.module.scss';

const Suggestion = ({ lang }) => {
	const [id, setId] = useState(null);
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [load, setLoad] = useState(false);
	const [editData, setEditData] = useState('');
	const [showDel, setShowDel] = useState(false);
	const [showEdit, setShowEdit] = useState(false);
	const [showCreate, setShowCreate] = useState(false);
	const [filter, setFilter] = useState({});
	const [showFilter, setShowFilter] = useState(false);
	const [pc, setPc] = useState(0);
	const [pageNum, setPageNum] = useState(1);
	const [page, setPage] = useState({ limit: 10, page: 1 });

	const handleDelete = (id) => {
		setLoad(true);

		suggestionApi.delete(id)
			.then(res => {
				if (res.data.success) {
					setLoad(false);
					setShowDel(false);
					getList();
				}
			})
			.catch(err => {
				console.log(err);
			})
			.finally(() => {
				setLoad(false);
			});

		setId(null);
	};

	const handleFilter = (e) => {
		e.preventDefault();

		const { name, type, start, end } = e.target.elements;

		const newFilter = {};

		if (name.value) newFilter.name = name.value;
		if (type.value !== 'all') newFilter.type = type.value;
		if (start.value) newFilter.start = start.value;
		if (end.value) newFilter.end = end.value;

		setFilter(newFilter);

		setShowFilter(false);

		setPage({ ...page, page: 1 });
	}

	const getList = () => {
		setLoading(true);

		suggestionApi
			.getList({ ...page, ...filter })
			.then(res => {
				if (res.data.success) {
					setPageNum(Math.ceil(res.data.num));
					setPc((page.page - 1) * page.limit);
					setData(res.data.data);
				}
			})
			.catch(err => {
				console.log(err);
			})
			.finally(() => {
				setLoading(false);
			});
	}

	useEffect(() => {
		getList();
	}, [page]);

	return (
		<div>
			<Container>
				<div className={ft.content__head}>
					<h5 className={ft.title}>
						{Translate(lang, 'suggestion.title')}
					</h5>

					<div className={ft.btns}>
						<button
							className={`${ft.primary__btn} ml-2`}
							onClick={() => setShowFilter(true)}
						>
							{Translate(lang, 'global.filter')}
						</button>

						<button
							className={ft.add__btn}
							onClick={() => setShowCreate(!showCreate)}
						>
							<span className={ft.icon}>+</span>
							{Translate(lang, 'global.add')}
						</button>
					</div>
				</div>

				<div className={ft.table}>
					<table className={ft.table__main}>
						<thead>
							<tr>
								<td>#</td>
								<td>{Translate(lang, 'global.name')}</td>
								<td className='text-center'>{Translate(lang, 'global.type')}</td>
								<td>{Translate(lang, 'global.description')}</td>
								<td>{Translate(lang, 'table.createdAt')}</td>
								<td className='text-center'>{Translate(lang, 'global.amal')}</td>
							</tr>
						</thead>

						<tbody>
							{loading ? (
								<tr>
									<td colSpan={6}>
										<Loading cl='mt-2' size='md' big />
									</td>
								</tr>
							) : data?.length ? (
								<>
									{data.map((item, index) => (
										<tr key={item._id}>
											<td>{pc + index + 1}</td>
											<td>{item?.name}</td>
											<td className='text-center'>
												<div className={cls.type}>
													{Translate(lang, `enum.${item?.type?.toLowerCase()}`)}
												</div>
											</td>
											<td>{item?.description}</td>
											<td>
												<div style={{width: 180}}>{BeautyFullTime(item?.createdAt)}</div>
											</td>
											<td>
												<div className='d-flex justify-content-center'>
													<button
														className={ft.edit__btn}
														onClick={() => {
															setId(item?._id);
															setEditData(item);
															setShowEdit(true);
														}}
													>
														{edit}
													</button>

													<button
														className={ft.del__btn}
														onClick={() => {
															setId(item?._id);
															setShowDel(true);
														}}
													>
														{del}
													</button>
												</div>
											</td>
										</tr>
									))}
								</>
							) : (
								<tr>
									<td colSpan={6}>
										<h6 className={ft.noData}>
											{Translate(lang, 'global.noInfo')}
										</h6>
									</td>
								</tr>
							)}
						</tbody>
					</table>

					{!loading && (
						<Pagination pageNum={pageNum} page={page} setPage={setPage} />
					)}
				</div>
			</Container>

			<Filter show={showFilter} setShow={setShowFilter}>
				<Form className={ft.form} onSubmit={handleFilter}>
					<Form.Label>
						<div className={ft.lab}>{Translate(lang, 'global.name')}</div>
						<Form.Control name='name' defaultValue={filter.name} type='text' />
					</Form.Label>

					<Form.Label>
						<div className={ft.lab}>{Translate(lang, 'global.type')}</div>
						<select name='type' defaultValue={filter.type}>
							<option value='all'>{Translate(lang, 'global.all')}</option>
							<option value='PROBLEM'>{Translate(lang, 'enum.problem')}</option>
							<option value='SPACE'>{Translate(lang, 'enum.space')}</option>
							<option value='CONFLICT'>{Translate(lang, 'enum.conflict')}</option>
						</select>
					</Form.Label>

					<Form.Label>
						<div className={ft.lab}>{Translate(lang, 'global.from')}</div>
						<Form.Control name='start' defaultValue={filter.start} type='date' />
					</Form.Label>

					<Form.Label>
						<div className={ft.lab}>{Translate(lang, 'global.to')}</div>
						<Form.Control name='end' defaultValue={filter.end} type='date' />
					</Form.Label>

					<button className={`${ft.filter__btn} w-100 mt-2`} type='submit'>
						{Translate(lang, 'global.search')}
					</button>
				</Form>
			</Filter>

			<Create
				lang={lang}
				show={showCreate}
				setShow={setShowCreate}
				getList={getList}
			/>

			<Update
				id={id}
				lang={lang}
				setId={setId}
				show={showEdit}
				editData={editData}
				setShow={setShowEdit}
				getList={getList}
			/>

			<Del
				id={id}
				lang={lang}
				load={load}
				setId={setId}
				show={showDel}
				setShow={setShowDel}
				handleDelete={handleDelete}
			/>
		</div>
	);
};

export default Suggestion;
