import ft from '../mainStyles.module.scss';
import ReactToPrint from 'react-to-print';
import Translate from '../lang';
import { exp } from '../icons';
export default function ExportPdf({ id, title, lang, rey }) {
	return rey ? (
		<ReactToPrint
			documentTitle={title}
			trigger={() => (
				<button
					className={`${ft.export__btn} mr-3`}
					style={{
						marginLeft: '80%',
						background: 'transparent',
						color: '#29a0e3',
					}}>
					<span className='mr-1'>
						{Translate(lang, 'global.export')}
					</span>
					{exp}
				</button>
			)}
			content={() => document.querySelector(`#${id}`)}
		/>
	) : (
		<ReactToPrint
			documentTitle={title}
			trigger={() => (
				<button className={`${ft.export__btn} mr-3`}>
					<span className='mr-1'>
						{Translate(lang, 'global.export')}
					</span>
					{exp}
				</button>
			)}
			content={() => document.querySelector(`#${id}`)}
		/>
	);
}
