import CustomModal from "../../../components/customModal";
import {Form} from "react-bootstrap";
import ft from "../../../components/mainStyles.module.scss";
import {structureApi} from "../../../services/structureService";
import {useState} from "react";
import Translate from "../../../components/lang";
import Loading from "../../../components/loading";
import { TextValidate } from "../../../utils";

const StructureAdd = (props) => {
    const {show, setShow, setLoading, setData, lang} = props,
        [load, setLoad] = useState(false),
        [numErr, setNumErr] = useState(false);

    const submitHandler = (e) => {
        e.preventDefault();

        const val = e.target[4].value.match(/^(\+)([998]{3})([\d]{2})(\d+)/);
        if (val && val[4].length === 7) {
            setLoad(true);
            setNumErr(false);
            const data = {
                name: {
                    uz: e.target[0].value,
                    ru: e.target[1].value,
                },
                leader: e.target[2].value,
                legalAddress: e.target[3].value,
                phone: e.target[4].value,
                email: e.target[5].value,
            };

            structureApi
                .add(data)
                .then((res) => {
                    if (res.data.success) {
                        setLoad(false)
                        setLoading(true);
                        structureApi
                            .all()
                            .then((res) => {
                                const data = res.data;
                                setData(data.data);
                                setLoading(false);
                            })
                            .catch((err) => {
                                console.log(err.message);
                                setLoading(false);
                            });
                        setShow(false);
                    }
                })
                .catch((err) => {
                });
        } else {
            setNumErr(true);
        }
    };
    return (
      <CustomModal
        show={show}
        setShow={setShow}
        title={Translate(lang, "global.addStructure")}
        size={"md"}
      >
        <Form className={ft.form} onSubmit={submitHandler}>
          <Form.Label className="w-100">
            <div className={ft.lab}>
              {Translate(lang, "structure.structureName")} (Uzb)
            </div>
            <Form.Control type="text" placeholder="" required onKeyUp={(e) => TextValidate(e.target)}/>
          </Form.Label>
          <Form.Label className="w-100">
            <div className={ft.lab}>
              {Translate(lang, "structure.structureName")}(Рус)
            </div>
            <Form.Control type="text" placeholder="" required onKeyUp={(e) => TextValidate(e.target)}/>
          </Form.Label>
          <Form.Label className="w-100">
            <div className={ft.lab}> {Translate(lang, "structure.leader")}</div>
            <Form.Control type="text" placeholder="" required onKeyUp={(e) => TextValidate(e.target)}/>
          </Form.Label>
          <Form.Label className="w-100">
            <div className={ft.lab}>
              {" "}
              {Translate(lang, "structure.address")}
            </div>
            <Form.Control type="text" placeholder="" required />
          </Form.Label>
          <Form.Label className="w-100">
            <div className={ft.lab}>{Translate(lang, "global.phone")}</div>
            <Form.Control
              type="text"
              placeholder=""
              required
              style={
                numErr
                  ? {
                      backgroundColor: "rgba(255, 82, 82, 0.1)",
                    }
                  : {}
              }
            />
          </Form.Label>
          {numErr ? (
            <span className={ft.err__mes}>
              {Translate(lang, "global.phoneError")}
            </span>
          ) : (
            ""
          )}
          <Form.Label className="w-100">
            <div className={ft.lab}> {Translate(lang, "structure.mail")}</div>
            <Form.Control type="email" placeholder="" required />
          </Form.Label>
          <div className="d-flex justify-content-end mt-2">
            <button
              className={`${ft.close__btn} mr-2`}
              type="button"
              onClick={() => setShow(false)}
            >
              {Translate(lang, "global.cancel")}
            </button>
            <button className={ft.save__btn} type="submit">
              {Translate(lang, "global.save")}{" "}
              {load ? <Loading size="sm" cl="ml-1" /> : ""}
            </button>
          </div>
        </Form>
      </CustomModal>
    );
};

export default StructureAdd;
