import { useState, useEffect } from 'react';
import ft from '../../../components/mainStyles.module.scss';
import Container from '../../../components/container';
import Back from '../../../components/back';
import Del from '../../../components/del';
import Comments from '../comments';
import AddLS9 from './AddLS9';
import { Link } from 'react-router-dom';
import { edit, del } from '../../../components/icons';
import { legalStatusApi } from '../../../services/legalStatusService';
import Loading from '../../../components/loading';
import { BeautyDate, BeautyTime } from '../../../utils';
import Translate from '../../../components/lang';
import EditLS9 from './EditLS9/EditLS9';

const LS9 = (props) => {
	const [showDel, setShowDel] = useState(false),
		[showAdd, setShowAdd] = useState(false),
		[showEdit, setShowEdit] = useState(false),
		[loading, setLoading] = useState(false),
		[load, setLoad] = useState(false),
		[data, setData] = useState([]),
		[id, setId] = useState(null),
		[editData, setEditData] = useState([]),
		[comment, setComment] = useState([]),
		{ lang, periods, selPer, markingStarted } = props;

	useEffect(() => {
		setLoading(true);
		legalStatusApi
			.all({
				code: 9,
				start: periods[selPer]?.start?.slice(0, 10),
				end: periods[selPer]?.end?.slice(0, 10),
				period: periods[selPer]?._id,
			})
			.then((res) => {
				setData(res.data.data);
				setComment(res.data.result);
				setLoading(false);
			})
			.catch((err) => {
				console.log(err.message);
			});
	}, []);

	const deleteHandler = (id) => {
		setLoad(true);
		legalStatusApi.del(id).then((res) => {
			if (res.data.success) {
				setLoad(false);
				setLoading(true);
				legalStatusApi
					.all({
						code: 9,
						start: periods[selPer]?.start?.slice(0, 10),
						end: periods[selPer]?.end?.slice(0, 10),
						period: periods[selPer]?._id,
					})
					.then((res) => {
						setData(res.data.data);
						setLoading(false);
					})
					.catch((err) => {
						setLoading(false);
					});
				setShowDel(false);
			}
		});
		setId(null);
	};

	let tableData;

	if (data) {
		tableData = data?.map((tableRow, index) => (
			<tr
				style={{
					wordBreak: 'break-all',
				}}
				key={tableRow._id}>
				<td>{index + 1}</td>
				<td>{tableRow?.nameOne ? tableRow?.nameOne : '...'}</td>
				<td>
					{tableRow?.link ? (
						<a target='_blank' href={tableRow?.link}>
							{tableRow?.link}
						</a>
					) : (
						'---'
					)}
				</td>
				<td style={{ whiteSpace: 'nowrap' }}>{`${BeautyDate(tableRow?.updatedAt)} ${BeautyTime(
					tableRow?.updatedAt
				)}`}</td>
				{periods[selPer]?.status === 1 ? (
					markingStarted?.status === 1 ? null : (
						<td>
							<div className='d-flex justify-content-center'>
								<button
									onClick={() => {
										setShowEdit(true);
										setId(tableRow?._id);
										setEditData(tableRow);
									}}
									className={ft.edit__btn}>
									{edit}
								</button>
								<button
									onClick={() => {
										setId(tableRow?._id);
										setShowDel(true);
									}}
									className={ft.del__btn}>
									{del}
								</button>
							</div>
						</td>
					)
				) : null}
			</tr>
		));
	}

	return (
		<div style={{ fontSize: '0.8em' }}>
			<div className='mb-3'>
				<Link to='/legal-status'>
					<Back lang={lang} />
				</Link>
			</div>

			<Container>
				<div className={`${ft.content__head} p-0`}>
					<h5 className={ft.title}>{Translate(lang, 'L9.title')}</h5>
					{periods[selPer]?.status === 1 ? (
						markingStarted?.status === 1 ? null : (
							<div className={ft.btns}>
								<button type='button' className={ft.add__btn} onClick={() => setShowAdd(true)}>
									<span className={ft.icon}>+</span>
									{Translate(lang, 'global.add')}
								</button>
							</div>
						)
					) : null}
				</div>

				<div className={ft.table}>
					<table className={ft.table__main}>
						<thead>
							<tr>
								<td>#</td>
								<td>{Translate(lang, 'L9.video')}</td>
								<td>{Translate(lang, 'L1.link')}</td>
								<td>{Translate(lang, 'L1.timeCompleted')}</td>
								{periods[selPer]?.status === 1 ? (
									markingStarted?.status === 1 ? null : (
										<td className='text-center'>{Translate(lang, 'global.amal')}</td>
									)
								) : null}
							</tr>
						</thead>
						<tbody>
							{loading ? <Loading big size='md' cl='mt-2' /> : data?.length ? tableData : <h6></h6>}
						</tbody>
					</table>
					{!loading && !data?.length && <h6 className={ft.noData}> {Translate(lang, 'global.noInfo')}</h6>}
				</div>
			</Container>

			{markingStarted?.status === 1
				? null
				: !loading &&
				  comment && (
						<Comments
							num={comment?.score}
							expert={comment?.expert?.fullName}
							position={comment?.expert?.position[lang]}>
							{comment.comment}
						</Comments>
				  )}

			<AddLS9
				lang={lang}
				show={showAdd}
				setData={setData}
				selPer={selPer}
				periods={periods}
				setShow={setShowAdd}
				setLoading={setLoading}
			/>

			<EditLS9
				id={id}
				lang={lang}
				show={showEdit}
				selPer={selPer}
				periods={periods}
				data={editData}
				setData={setData}
				setShow={setShowEdit}
				setLoading={setLoading}
			/>

			<Del
				lang={lang}
				id={id}
				setId={setId}
				load={load}
				show={showDel}
				setShow={setShowDel}
				handleDelete={deleteHandler}
			/>
		</div>
	);
};

export default LS9;
