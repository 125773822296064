import axiosInstance from "./api";

const api = "/recommendations";

export const advicesApi = {
    all: () =>
        axiosInstance.get(`${api}/get-all`, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
        }),
    getById: (id) =>
        axiosInstance
            .get(`${api}/get/${id}`, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("token")}`,
                },
            })
}