import ft from '../../components/mainStyles.module.scss';
import TasksModal from './taskModal';
import { useState, useEffect } from 'react';
import Container from '../../components/container';
import Filter from '../../components/filter';
import { svg } from '../../components/icons';
import Translate from '../../components/lang';
import { taskApi } from '../../services/taskService';
import { orgApi } from '../../services/orgListService';
import Loading from '../../components/loading';
import { Form } from 'react-bootstrap';
import TaskEdit from './taskEdit/taskEdit';
import Pagin from '../../components/pagination';
import { APIFile } from '../../services/api';
import Limit from '../../components/limit';

export default function Tasks(props) {
	const [show, setShow] = useState(false),
		[status, setStatus] = useState(10),
		[data, setData] = useState([]),
		[loading, setLoading] = useState(true),
		[options, setOptions] = useState([]),
		[selected, setSelected] = useState([]),
		[pageNum, setPageNum] = useState(1),
		[showEdit, setShowEdit] = useState(false),
		[page, setPage] = useState({ limit: 10, page: 1 }),
		[editData, setEditData] = useState([]),
		[id, setId] = useState(null),
		[showFilter, setShowFilter] = useState(false),
		[pc, setPc] = useState(0),
		[filter, setFilter] = useState({
			start: '2021-01-01',
			end: '2022-06-30',
		}),
		{ lang, selPer, periods, markingStarted } = props;

	useEffect(() => {
		orgApi.getAll().then((res) => {
			if (res.data.success) {
				const DATA = [];
				res.data.data.map((d) => {
					DATA.push({
						value: d._id,
						label: d.name.uz,
					});
				});
				setOptions(DATA);
			}
		});
	}, []);

	useEffect(() => {
		setLoading(true);
		taskApi
			.all({
				limit: page.limit,
				page: page.page,
				start: periods[selPer]?.start?.slice(0, 10),
			})
			.then((res) => {
				setData(res.data.data);
				setPageNum(Math.ceil(res.data.num));
				setPc((page.page - 1) * page.limit);
				setLoading(false);
			})
			.catch((e) => {
				setLoading(false);
				console.log(e.message);
			});
	}, [page]);

	const handleFilter = (e) => {
		e.preventDefault();
		const selId = [];
		selected.map((s) => {
			selId.push(s.value);
		});
		const data = {
			start: e.target[2].value,
			end: e.target[3].value,
			start: periods[selPer]?.start?.slice(0, 10),
		};
		if (e.target[0].value.length) data.docName = e.target[0].value;
		if (e.target[1].value !== 'all') data.status = +e.target[1].value;

		setFilter(data);
		setShowFilter(false);
		setLoading(true);
		taskApi.all({ ...page, ...data }).then((res) => {
			if (res.data.success) {
				const DATA = res.data.data;
				const temp = [];
				DATA.forEach((d) => {
					temp.push(d);
				});
				setPageNum(Math.ceil(res.data.num));
				setPc(0);
				setData(temp);
				setLoading(false);
			}
		});
	};
	return (
		<div>
			<Container>
				<div className={ft.content__head}>
					<h5 className={ft.title}>{Translate(lang, 'navbar.tasks')}</h5>
					<div className={ft.btns}>
						<div className='mx-2'>
							<Limit
								{...props}
								langTask={true}
								setPage={setPage}
								setLoading={setLoading}
								setFilter={setFilter}
							/>
						</div>
						<button className={`${ft.primary__btn} ml-2`} onClick={() => setShowFilter(true)}>
							{Translate(lang, 'global.filter')}
						</button>
					</div>
				</div>

				<div className={ft.table}>
					<table className={`${ft.table__main}`}>
						<thead>
							<tr>
								<td>#</td>
								<td>{Translate(lang, 'tasks.taskMeaning')}</td>
								<td>{Translate(lang, 'tasks.duration')}</td>
								<td>{Translate(lang, 'tasks.fileName')}</td>
								<td>{Translate(lang, 'tasks.file')}</td>
								<td>{Translate(lang, 'tasks.dateCom')}</td>
								<td>{Translate(lang, 'tasks.ijroFile')}</td>
								<td>{Translate(lang, 'tasks.status')}</td>
								{periods[selPer]?.status === 1 ? (
									markingStarted?.status === 1 ? null : (
										<td className='text-center'>{Translate(lang, 'global.amal')}</td>
									)
								) : null}
							</tr>
						</thead>
						<tbody>
							{loading ? (
								<Loading className='mt-2' big={true} size='md' cl='mt-2' />
							) : data?.length ? (
								data?.map((item, index) => (
									<tr key={item._id}>
										<td>{pc + index + 1}</td>
										<td>{item?.description}</td>
										<td>
											{item?.deadline
												?.toString()
												.split('')
												.slice(0, 10)
												.join('')
												.split('-')
												.reverse()
												.join('.')}{' '}
										</td>

										<td>{item?.docName}</td>

										<td>
											{item?.files?.map((file) => (
												<a
													target='_blank'
													rel='noreferrer'
													href={`${APIFile}${file.path}`}
													style={{ display: 'block' }}>
													{file?.path?.slice(15)} ({file?.size}mb) {svg}
												</a>
											))}
										</td>
										<td>
											{item?.complete
												? item?.complete
														?.toString()
														.split('')
														.slice(0, 10)
														.join('')
														.split('-')
														.reverse()
														.join('.')
												: '---'}
										</td>
										<td>
											{item?.respondFiles?.length
												? item?.respondFiles?.map((file) => (
														<a
															target='_blank'
															rel='noreferrer'
															href={`${APIFile}${file.path}`}
															style={{ display: 'block' }}>
															{file?.path?.slice(15)} ({file?.size}mb) {svg}
														</a>
												  ))
												: '---'}
										</td>
										<td>
											{item?.status === 0 ? (
												<span
													style={{
														color: '#ff5252',
														fontWeight: 500,
													}}
													className={ft.isNotDone}>
													{Translate(lang, 'home.unfulfilled')}
												</span>
											) : item?.status === 1 ? (
												<span
													style={{
														color: '#FFBA52',
														fontWeight: 500,
													}}
													className={ft.isWaiting}>
													{Translate(lang, 'tasks.sent')}
												</span>
											) : item?.status === 2 ? (
												<span
													span
													style={{
														color: '#07a287',
														fontWeight: 500,
													}}
													className={ft.isDone}>
													{Translate(lang, 'tasks.accepted')}
												</span>
											) : (
												<span
													style={{
														color: '#ff5252',
														fontWeight: 500,
													}}
													className={ft.isNotDone}>
													{Translate(lang, 'tasks.unaccepted')}
												</span>
											)}
										</td>
										{periods[selPer]?.status === 1 ? (
											markingStarted?.status === 1 ? null : (
												<td>
													<div className='text-center'>
														{item?.status === 0 ? (
															<button
																style={{ fontSize: '13px' }}
																className={ft.info__btn}
																onClick={() => {
																	setShow(true);
																	setId(item?._id);
																}}>
																{Translate(lang, 'global.do')}
															</button>
														) : item?.status === 1 ? (
															<button
																style={{ fontSize: '13px' }}
																className={ft.info__btn}
																onClick={() => {
																	setShowEdit(true);
																	setEditData(item);
																	setId(item?._id);
																}}>
																{Translate(lang, 'global.edit')}
															</button>
														) : item?.status === 2 ? (
															'---'
														) : (
															'---'
														)}
													</div>
												</td>
											)
										) : null}
									</tr>
								))
							) : (
								<h6></h6>
							)}
						</tbody>
					</table>
					{!loading && !data?.length && (
						<h6 className={ft.noData}> {Translate(lang, 'global.taskNotFound')}</h6>
					)}
					{!loading ? data?.length ? <Pagin setPage={setPage} page={page} pageNum={pageNum} /> : '' : ''}
				</div>
			</Container>

			<Filter show={showFilter} setShow={setShowFilter}>
				<Form className={ft.form} onSubmit={handleFilter}>
					<Form.Label className='my-2'>
						<Form.Control
							type='text'
							placeholder={Translate(lang, 'global.fileName')}
							defaultValue={filter?.docName}
						/>
					</Form.Label>

					<Form.Control
						as='select'
						className='my-2'
						onChange={(e) => setStatus(e.target.value)}
						defaultChecked={filter?.status}>
						<option value='all'>{Translate(lang, 'global.all')}</option>
						<option value={1}>{Translate(lang, 'homeAll.fileSent')}</option>
						<option value={2}>{Translate(lang, 'homeAll.received')}</option>
						<option value={3}>{Translate(lang, 'homeAll.notReceived')}</option>
					</Form.Control>
					<Form.Label>
						<div className={ft.lab}>{Translate(lang, 'global.from')}</div>
						<Form.Control type='date' defaultValue={filter?.start} required />
					</Form.Label>
					<Form.Label>
						<div className={ft.lab}>{Translate(lang, 'global.to')}</div>
						<Form.Control type='date' defaultValue={filter?.end} required />
					</Form.Label>
					<button className={`${ft.filter__btn} w-100 mt-2`} type='submit'>
						{Translate(lang, 'global.placeholder')}
					</button>
				</Form>
			</Filter>

			<TasksModal
				id={id}
				show={show}
				lang={lang}
				page={page}
				periods={periods}
				selPer={selPer}
				setShow={setShow}
				setData={setData}
				setLoading={setLoading}
				setPageNum={setPageNum}
				setPc={setPc}
			/>

			<TaskEdit
				id={id}
				lang={lang}
				page={page}
				periods={periods}
				selPer={selPer}
				show={showEdit}
				data={editData}
				setData={setData}
				setShow={setShowEdit}
				setLoading={setLoading}
				setPageNum={setPageNum}
				setPc={setPc}
			/>
		</div>
	);
}
