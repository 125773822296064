import CustomModal from "../../../components/customModal";
import { svg } from "../../../components/icons";
import React from "react";
import Loading from "../../../components/loading";
import { APIFile } from "../../../services/api";
import Translate from "../../../components/lang";

function AdvicesModal(props) {
  const { show, setShow, viewData, load, lang } = props;

  return (
    <CustomModal
      show={show}
      setShow={setShow}
      size={"md"}
      title={Translate(lang, "navbar.advices")}
    >
      {load ? (
        <div className="d-flex justify-content-center align-items-center">
          <Loading size="md" />
        </div>
      ) : (
        <div>
          <p style={{ color: "#07A287", marginBottom: "2px" }}>
            {Translate(lang, "advices.theme")}
          </p>
          <p style={{ fontSize: "12px" }}>{viewData?.description}</p>
          <p
            style={{
              color: "#B0B7CE",
              fontSize: "12px",
              margin: "25px 0 15px",
            }}
          >
            Ilovalar ({viewData?.files?.length} ta fayl)
          </p>
          <div>
            {viewData?.files?.map((file) => (
              <a
                target="_blank"
                rel="noreferrer"
                href={`${APIFile}${file.path}`}
                download
                style={{
                  color: "#29A0E3",
                  background: "none",
                  marginBottom: "10px",
                }}
                className="d-flex align-items-center"
              >
                <p className="mr-1">{file?.path.slice(15)}</p>
                {svg}
              </a>
            ))}
          </div>
        </div>
      )}
    </CustomModal>
  );
}

export default AdvicesModal;
