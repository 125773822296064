import axiosInstance from '../api';

const api = '/doc-receiveds';

const	headers = {
	headers: {
		Authorization: `Bearer ${sessionStorage.getItem('token')}`
	}
};

export const docReceivedApi = {
	getList: (data) => axiosInstance.post(`${api}/list`, data, headers),
	getById: (id) => axiosInstance.get(`${api}/${id}`, headers)
}
