import {useEffect, useState} from "react";
import st from "../home.module.scss";
import CardItem from "./cardItem";

const HomeCard = (props) => {
    const {title, label, itemInfo} = props;
    const [classNameAnime, setClassAnime] = useState();

    const setCLASS = () => {
        if (props.index == 0) setClassAnime(st.info0);
        else if (props.index == 3) setClassAnime(st.info1);
        else {
            setClassAnime(st.info);
        }
    };

    useEffect(() => {
        setCLASS();
    }, [classNameAnime]);
    let cardItem = itemInfo.map((item) => <CardItem itemInfo={item}/>);

    return (
        <div className={st.info__card}>
            <div className="d-flex justify-content-between mb-3">
                <h6 className={st.p}>{title}</h6>
                {label && <span className={classNameAnime}>{label}</span>}
            </div>
            <div className="d-flex justify-content-between">{cardItem}</div>
        </div>
    );
};

export default HomeCard;
