import st from "./back.module.scss";
import { back } from "../icons";
import Translate from "../lang";

export default function Back(props) {
  const {lang } = props;

  return (
    <button className={st.button}>
      {back} {Translate(lang, "global.back")}
    </button>
  );
}
