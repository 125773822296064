import ft from "../../components/mainStyles.module.scss";
import AdvicesModal from "./advicesModal";
import { useEffect, useState } from "react";
import Container from "../../components/container";
import { svg } from "../../components/icons";
import Translate from "../../components/lang";
import Loading from "../../components/loading";
import { advicesApi } from "../../services/advicesService";
import { APIFile } from "../../services/api";

export default function Advices(props) {
  const [show, setShow] = useState(false),
    [loading, setLoading] = useState(false),
    [data, setData] = useState([]),
    [viewData, setViewData] = useState([]),
    [load, setLoad] = useState(false),
    { lang } = props;

  useEffect(() => {
    if (!show) {
      setLoading(true);
      advicesApi
        .all()
        .then((res) => {
          setData(res.data.data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err.message);
        });
    }
  }, [show]);

  const viewHandler = (id) => {
    setLoad(true);
    advicesApi
      .getById(id)
      .then((res) => {
        setViewData(res.data.data);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  let tableData = null;

  if (data) {
    tableData = data.map((item, index) => (
      <tr>
        <td>{index + 1}</td>
        <td>{item?.expert ? item?.expert?.fullName : "..."}</td>
        <td style={{ width: "250px" }}>
          {item?.description?.split(" ").length >= 10
            ? item?.description?.split(" ").slice(0, 10).join(" ") + "..."
            : item?.description}
        </td>
        <td>{item?.createdAt.slice(0, 10).split("-").reverse().join(".")}</td>
        <td>
          {item?.seenDate ? (
            item?.seenDate.slice(0, 10).split("-").reverse().join(".")
          ) : (
            <span style={{ color: "#FF5252" }}>Hali ko'rilmagan</span>
          )}
        </td>
        <td>
          {item.files.map((file) => (
            <a
              target='_blank'
              rel="noreferrer"
              href={`${APIFile}${file.path}`}
              download
              style={{ color: "#29A0E3", background: "none" }}
              className="d-flex align-items-center"
            >
              <p className="mr-1">{file?.path.slice(15)}</p>
              {svg}
            </a>
          ))}
        </td>
        <td className={"text-center"}>
          <button
            style={{ fontSize: "13px" }}
            className={ft.info__btn}
            onClick={() => {
              viewHandler(item?._id);
              setShow(true);
            }}
          >
            {Translate(lang, "global.see")}
          </button>
        </td>
      </tr>
    ));
  }

  return (
    <div>
      <Container>
        <div className={ft.content__head}>
          <h5 className={ft.title}> {Translate(lang, "navbar.advices")}</h5>
        </div>
        <div className={ft.table}>
          <table className={`${ft.table__main}`} style={{ fontSize: "13px" }}>
            <thead>
              <tr>
                <td>#</td>
                <td> {Translate(lang, "advices.expert")}</td>
                <td> {Translate(lang, "advices.theme")}</td>
                <td> {Translate(lang, "advices.datePost")}</td>
                <td> {Translate(lang, "advices.seenDate")}</td>
                <td> {Translate(lang, "advices.file")}</td>
                <td style={{ textAlign: "center" }}>
                  {" "}
                  {Translate(lang, "global.amal")}
                </td>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <Loading big size="md" cl="mt-2" />
              ) : data?.length ? (
                tableData
              ) : (
                <h6></h6>
              )}
            </tbody>
          </table>
          {!loading && !data?.length && (
            <h6 className={ft.noData}>
              {" "}
              {Translate(lang, "global.adviceNotFound")}
            </h6>
          )}
        </div>
      </Container>
      <AdvicesModal
        load={load}
        show={show}
        setShow={setShow}
        viewData={viewData}
        lang={lang}
      />
    </div>
  );
}
