import CustomModal from "../../../../components/customModal";
import UploadFiles from "../../../../components/uploadFiles";
import ft from "../../../../components/mainStyles.module.scss";
import { Form } from "react-bootstrap";
import { useEffect, useState } from "react";
import Translate from "../../../../components/lang";
import { legalStatusApi } from "../../../../services/legalStatusService";
import Loading from "../../../../components/loading";

const AddLS1 = (props) => {
    const [newFiles, setNewFiles] = useState([]),
        [prots, setProts] = useState([]),
        [load, setLoad] = useState(false),
        [uploadedFiles, setUploadedFiles] = useState([]),
        [errFiles, setErrFiles] = useState(false),
        [errFilesLength, setErrFilesLength] = useState(false),
        {lang, setLoading, setData, setShow, show, periods, selPer} = props

    useEffect(() => {
        setProts([])
        setNewFiles([])
        setUploadedFiles([])
        setErrFilesLength(false)
    }, [show])

    const handleSubmit = (e) => {
        e.preventDefault()
        if (uploadedFiles.length <= 3) {
            setErrFilesLength(false)
            if (uploadedFiles.length) {
                setLoad(true)
                setErrFiles(false)

                const file = []

                uploadedFiles.map((f) => {
                    file.push({file: f._id, path: f.path, size: f.size})
                })

                const data = {
                    code: 1,
                    nameOne: e.target[0].value,
                    date: e.target[1].value,
                    link: e.target[2].value,
                    description: e.target[3].value,
                    files: file,
                }

                legalStatusApi
                    .add(data)
                    .then((res) => {
                        if (res.data.success) {
                            setLoad(false)
                            setLoading(true)
                            legalStatusApi
                                .all({code: 1,  period: periods[selPer]?._id, start: periods[selPer]?.start?.slice(0, 10), end: periods[selPer]?.end?.slice(0, 10)})
                                .then((res) => {
                                    setData(res.data.data)
                                    setLoading(false)
                                })
                                .catch((err) => {
                                    console.log(err.message)
                                    setLoading(false)
                                })
                            setShow(false)
                        }
                    })
            } else setErrFiles(true)
        } else setErrFilesLength(true)

    }


    return (
      <CustomModal
        title={Translate(lang, "L1.title")}
        show={show}
        setShow={setShow}
        size={"lg"}
      >
        <form action="" className="row" onSubmit={handleSubmit}>
          <div className={`col-7 ${ft.form}`}>
            <Form.Label className="w-100">
              <div className={ft.lab}>{Translate(lang, "L1.aksiya")}</div>
              <Form.Control type="text" placeholder="" required />
            </Form.Label>
            <Form.Label className="w-50">
              <div className={ft.lab}>{Translate(lang, "L1.dateEvent")}</div>
              <Form.Control type="date" placeholder="" required />
            </Form.Label>
            <Form.Label className="w-100">
              <div className={ft.lab}>{Translate(lang, "L1.link1")}</div>
              <Form.Control type="text" placeholder="" />
            </Form.Label>
            <Form.Label className="w-100">
              <div className={ft.lab}>
                {Translate(lang, "L1.fullInfo1")}
              </div>
              <Form.Control as="textarea" rows={4} required />
            </Form.Label>
          </div>

          <div className={`col-5 ${ft.form}`}>
            <div className="mb-3 w-100">
              <UploadFiles
                {...props}
                newFiles={newFiles}
                setNewFiles={setNewFiles}
                setErrFiles={setErrFiles}
                setUploadedFiles={setUploadedFiles}
                load={load}
                setLoad={setLoad}
                prots={prots}
                setProts={setProts}
                setErrFilesLength={setErrFilesLength}
              />
              {errFiles && (
                <span className={ft.err__mes}>
                  {Translate(lang, "global.notUploading")}
                </span>
              )}

              {errFilesLength && (
                <span className={ft.err__mes}>
                  Fayllar soni 3tadan ko‘p bo‘lmasligi kerak
                </span>
              )}
            </div>
          </div>
          <div className="col-12 d-flex justify-content-end my-3">
            <button
              type="button"
              className={ft.close__btn}
              onClick={() => setShow(false)}
            >
              {Translate(lang, "global.cancel")}
            </button>
            <button className={`${ft.save__btn} ml-3`} type="submit">
              {Translate(lang, "global.save")}
              {load ? <Loading size="sm" cl="ml-1" /> : ""}
            </button>
          </div>
        </form>
      </CustomModal>
    );
}

export default AddLS1;
