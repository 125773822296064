import React, { useState } from 'react';
import { Form } from 'react-bootstrap';

import { docReceivedAnswerApi } from '../../../../services/doc/docReceivedAnswerService';

import Translate from '../../../../components/lang';
import UploadFiles from '../../../../components/uploadFiles';

import ft from '../../../../components/mainStyles.module.scss';

const SendAnswer = ({ lang, taskId, onSuccess }) => {
   const [load, setLoad] = useState(false);
   const [errFiles, setErrFiles] = useState(false);
   const [prots, setProts] = useState([]);
   const [newFiles, setNewFiles] = useState([]);
   const [uploadedFiles, setUploadedFiles] = useState([]);

   const sendAnswer = (e) => {
      e.preventDefault();

      const files = uploadedFiles.map(file => ({
         file: file._id,
         path: file.path,
         size: file.size
      }));

      const { link } = e.target.elements;

      const data = {
         taskId: taskId,
         files: files,
         link: link.value
      };

      docReceivedAnswerApi.create(data)
        .then(res => {
           if (res.data.success) {
              onSuccess();
           }
        })
        .catch(err => {
           console.log(err);
        });
   }

   return (
      <Form className={ft.form} onSubmit={sendAnswer}>
         <Form.Label>
            <div className={ft.lab}>{Translate(lang, 'global.link')}</div>
            <Form.Control name='link' type='text' />
         </Form.Label>

         <Form.Label>
            <div className={ft.lab}>{Translate(lang, 'global.filesAndImages')}</div>
            <UploadFiles
               setUploadedFiles={setUploadedFiles}
               setNewFiles={setNewFiles}
               setErrFiles={setErrFiles}
               setProts={setProts}
               newFiles={newFiles}
               setLoad={setLoad}
               prots={prots}
               load={load}
               lang={lang}
            />
            {errFiles && (
               <span className={ft.err__mes}>
                  {Translate(lang, 'global.notUploaded')}
               </span>
            )}
         </Form.Label>

         <button className={`${ft.filter__btn} w-100 mt-2`} type='submit'>
            {Translate(lang, 'global.send')}
         </button>
      </Form>
   )
}

export default SendAnswer;