import ft from "../../../components/mainStyles.module.scss";
import {Form} from "react-bootstrap";
import CustomModal from "../../../components/customModal";
import {useState} from "react";
import {passportApi} from "../../../services/passportService";
import Translate from "../../../components/lang";
import Loading from "../../../components/loading";
import {TextValidate} from "../../../utils";

const DivisionEdit = (props) => {
    const {show, setShow, data, setData, setLoading, lang} = props,
        [load, setLoad] = useState(false),
        [numErr, setNumErr] = useState(false);

    const submitHandler = (e) => {
        e.preventDefault();

        setNumErr(false);

        const val = e.target[3].value.match(/^(\+)([998]{3})([\d]{2})(\d+)/);
        if (val && val[4].length === 7) {
            setLoad(true);
            const data = {
                divisionName: {
                    uz: e.target[0].value,
                    ru: e.target[1].value,
                },
                divisionLeader: e.target[2].value,
                leaderPhone: e.target[3].value,
                leaderEmail: e.target[4].value,
                employeesNumber: e.target[5].value,
                responsiblePerson: e.target[6].value,
            };

            passportApi
                .editProfile(data, {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
                    },
                })
                .then((res) => {
                    if (res.data.success) {
                        setLoad(false);
                        setLoading(true);
                        passportApi
                            .profile({
                                headers: {
                                    Authorization: `Bearer ${sessionStorage.getItem("token")}`,
                                },
                            })
                            .then((res) => {
                                setData(res.data.data);
                                setLoading(false);
                            })
                            .catch((e) => {
                                setLoading(false);
                            });
                        setShow(false);
                    }
                });
        } else {
            setNumErr(true);
        }
    };

    return (
        <CustomModal
            show={show}
            setShow={setShow}
            title={Translate(lang, "passport.modalTitle2")}
        >
            <div>
                <form action="" className="row" onSubmit={submitHandler}>
                    <div className={`p-3 ${ft.form}`}>
                        <Form.Label className="w-100">
                            <div className={ft.lab}>
                                {Translate(lang, "passport.depName")} (Uzb)
                            </div>
                            <Form.Control
                                type="text"
                                placeholder=""
                                required
                                defaultValue={data?.divisionName?.uz}
                                onKeyUp={(e) => TextValidate(e.target)}
                            />
                        </Form.Label>
                        <Form.Label className="w-100">
                            <div className={ft.lab}>
                                {" "}
                                {Translate(lang, "passport.depName")} (Рус)
                            </div>
                            <Form.Control
                                type="text"
                                placeholder=""
                                required
                                defaultValue={data?.divisionName?.ru}
                                onKeyUp={(e) => TextValidate(e.target)}
                            />
                        </Form.Label>
                        <Form.Label className="w-100">
                            <div className={ft.lab}>
                                {" "}
                                {Translate(lang, "passport.depLeader")} (Uzb)
                            </div>
                            <Form.Control
                                type="text"
                                placeholder=""
                                required
                                defaultValue={data?.divisionLeader}
                                onKeyUp={(e) => TextValidate(e.target)}
                            />
                        </Form.Label>

                        <div className="d-flex align-text-top">
                            <Form.Label style={{width: "45%", marginRight: "5%"}}>
                                <div className={ft.lab}>
                                    {" "}
                                    {Translate(lang, "global.phone")}{" "}
                                </div>
                                <Form.Control
                                    type="tel"
                                    placeholder=""
                                    required
                                    defaultValue={data?.leaderPhone ? `${data?.leaderPhone}` : ""}
                                    style={
                                        numErr
                                            ? {
                                                backgroundColor: "rgba(255, 82, 82, 0.1)",
                                            }
                                            : {}
                                    }
                                />

                                {numErr ? (
                                    <span className={ft.err__mes}>
                    {Translate(lang, "global.phoneError")}
                  </span>
                                ) : (
                                    ""
                                )}
                            </Form.Label>

                            <Form.Label className="w-50">
                                <div className={ft.lab}>{Translate(lang, "passport.mail")}</div>
                                <Form.Control
                                    type="email"
                                    placeholder=""
                                    defaultValue={data?.leaderEmail}
                                    maxLength="20"
                                />
                            </Form.Label>
                        </div>

                        <Form.Label style={{width: "45%"}}>
                            <div className={ft.lab}>
                                {Translate(lang, "passport.workerNum")}
                            </div>
                            <Form.Control
                                type="number"
                                placeholder=""
                                required
                                defaultValue={data?.employeesNumber}
                                min="0"
                            />
                        </Form.Label>
                        <Form.Label className="w-100">
                            <div className={ft.lab}>
                                {Translate(lang, "passport.responsiblePerson")}
                            </div>
                            <Form.Control
                                type="text"
                                placeholder=""
                                required
                                defaultValue={data?.responsiblePerson}
                                onKeyUp={(e) => TextValidate(e.target)}
                                maxLength="30"
                            />
                        </Form.Label>
                    </div>
                    <div className="col-12 d-flex justify-content-end my-3">
                        <button
                            type="button"
                            className={ft.close__btn}
                            onClick={() => setShow(false)}
                        >
                            {Translate(lang, "global.cancel")}
                        </button>
                        <button className={`${ft.save__btn} ml-3`} type="submit">
                            {Translate(lang, "global.save")}{" "}
                            {load ? <Loading size="sm" cl="ml-1"/> : ""}
                        </button>
                    </div>
                </form>
            </div>
        </CustomModal>
    );
};

export default DivisionEdit;
