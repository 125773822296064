import axiosInstance from "./api";

const api = "/structures";

export const structureApi = {
    all: () =>
        axiosInstance.get(`${api}/all`, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
        }),
    add: (data) =>
        axiosInstance.post(`${api}/create`, data, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
        }),
    del: (id) =>
        axiosInstance.delete(`${api}/delete/${id}`, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
        }),
    edit: (id, data) => {
        return axiosInstance.put(`${api}/update/${id}`, data,{
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
        })
    }
};
