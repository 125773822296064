import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';

import config from '../../../config';

import { docReceivedTaskApi } from '../../../services/doc/docReceivedTaskService';

import Filter from '../../../components/filter';
import Translate from '../../../components/lang';
import Loading from '../../../components/loading';
import Pagin from '../../../components/pagination';
import Container from '../../../components/container';
import CustomModal from '../../../components/customModal';

import { edit, eye } from '../../../components/icons';

import { BeautyFullTime } from '../../../utils';

import Answers from './components/Answers';
import SendAnswer from './components/SendAnswer';

import ft from '../../../components/mainStyles.module.scss';
import cls from './ForExecution.module.scss';

const ForExecution = ({ lang, getBadge }) => {
   const [data, setData] = useState([]);
   const [filter, setFilter] = useState({});
   const [loading, setLoading] = useState(true);
   const [showFilter, setShowFilter] = useState(false);
   const [pc, setPc] = useState(0);
   const [pageNum, setPageNum] = useState(1);
   const [page, setPage] = useState({ limit: 10, page: 1 });
   const [show, setShow] = useState(false);
   const [taskId, setTaskId] = useState(null);
   const [showAnswer, setShowAnswer] = useState(false);

   const handleFilter = (e) => {
      e.preventDefault();

      const { docType, status, number, start, end } = e.target.elements;

      const newFilter = {};

      if (docType.value !== 'all') newFilter.docType = docType.value;
      if (status.value !== 'all') newFilter.status = status.value;
      if (number.value) newFilter.number = number.value;
      if (start.value) newFilter.start = start.value;
      if (end.value) newFilter.end = end.value;

      setFilter(newFilter);

      setShowFilter(false);

      setPage({ ...page, page: 1 });
   }

   const getList = () => {
      setLoading(true);

      const data = {
         ...page,
         ...filter
      }

      docReceivedTaskApi
         .getList(data)
         .then(res => {
            if (res.data.success) {
               setPageNum(Math.ceil(res.data.num));
               setPc((page.page - 1) * page.limit);
               setData(res.data.data);
            }
         })
         .catch(err => {
            console.log(err);
         })
         .finally(() => {
            setLoading(false);
         });
   }

   useEffect(() => {
      getList();
   }, [page]);

   return (
      <>
         <Container>
            <div className={ft.content__head}>
               <h5 className={ft.title}>
                  {Translate(lang, 'forExecution.title')}
               </h5>

               <div className={ft.btns}>
                  <button
                     className={`${ft.primary__btn} ml-2`}
                     onClick={() => setShowFilter(true)}
                  >
                     {Translate(lang, 'global.filter')}
                  </button>
               </div>
            </div>

            <div className={ft.table}>
               <table id='table' className={ft.table__main}>
                  <thead>
                     <tr>
                        <td>#</td>
                        <td>{Translate(lang, 'global.taskMeaning')}</td>
                        <td>{Translate(lang, 'table.deadline')}</td>
                        <td>{Translate(lang, 'doc.number')}</td>
                        <td>{Translate(lang, 'doc.description')}</td>
                        <td>{Translate(lang, 'doc.type')}</td>
                        <td>{Translate(lang, 'table.link')}</td>
                        <td>{Translate(lang, 'table.file')}</td>
                        <td>{Translate(lang, 'table.createdAt')}</td>
                        <td>{Translate(lang, 'table.status')}</td>
                        <td className='text-center'>{Translate(lang, 'table.action')}</td>
                     </tr>
                  </thead>

                  <tbody>
                     {!loading ? (
                        data.map((item, index) => (
                          <tr key={item._id}>
                             <td>{pc + index + 1}</td>
                             <td>{item.description}</td>
                             <td>
                                <div style={{ width: 120 }}>
                                   {item.deadline}
                                </div>
                             </td>
                             <td>{item.doc.number}</td>
                             <td>{item.doc.description}</td>
                             <td>
                                <div className={cls.type}>
                                   {Translate(lang, `doc.${item.doc.type?.toLowerCase()}`)}
                                </div>
                             </td>
                             <td>{item.doc.link}</td>
                             <td className='text-nowrap'>
                                {item?.doc?.files.map((file, index) => (
                                  <div key={index}>
                                     <a href={`${config.api.baseUrl}${file?.path}`} target='_blank'>
                                        {file?.path?.slice(15)}
                                     </a>
                                  </div>
                                ))}
                             </td>
                             <td>
                                <div style={{ width: 180 }}>
                                   {BeautyFullTime(item.createdAt)}
                                </div>
                             </td>
                             <td>{Translate(lang, `status.${item.status}`)}</td>
                             <td>
                                <div className='d-flex justify-content-center'>
                                   <button
                                     className={ft.eye__btn}
                                     onClick={() => {
                                        setTaskId(item._id);
                                        setShowAnswer(true);
                                     }}
                                   >
                                      {eye}
                                   </button>
                                   {(item.status !== 'DONE' && item.status !== 'CLOSE') && (
                                     <button
                                       className={ft.edit__btn}
                                       onClick={() => {
                                          setTaskId(item._id);
                                          setShow(true);
                                       }}
                                     >
                                        {edit}
                                     </button>
                                   )}
                                </div>
                             </td>
                          </tr>
                        ))
                     ) : (
                       <tr>
                          <td colSpan={11}>
                             <Loading cl='mt-3' big={true} size='md'/>
                          </td>
                        </tr>
                     )}
                  </tbody>
               </table>

               {!loading && !data.length && (
                  <h6 className={ft.noData}>
                     {Translate(lang, 'table.notFound')}
                  </h6>
               )}

               {!loading && (
                  <Pagin pageNum={pageNum} page={page} setPage={setPage}/>
               )}
            </div>
         </Container>

         <Filter show={showFilter} setShow={setShowFilter}>
            <Form className={ft.form} onSubmit={handleFilter}>
               <Form.Label>
                  <div className={ft.lab}>{Translate(lang, 'doc.type')}</div>
                  <select name='docType'>
                     <option value='all'>{Translate(lang, 'global.all')}</option>
                     <option value='DECISION'>{Translate(lang, 'doc.decision')}</option>
                     <option value='DECREE'>{Translate(lang, 'doc.decree')}</option>
                     <option value='ORDER'>{Translate(lang, 'doc.order')}</option>
                     <option value='LAW'>{Translate(lang, 'doc.law')}</option>
                  </select>
               </Form.Label>

               <Form.Label>
                  <div className={ft.lab}>{Translate(lang, 'table.status')}</div>
                  <select name='status'>
                     <option value='all'>{Translate(lang, 'global.all')}</option>
                     <option value='NEW'>{Translate(lang, 'status.NEW')}</option>
                     <option value='SEEN'>{Translate(lang, 'status.SEEN')}</option>
                     <option value='DONE'>{Translate(lang, 'status.DONE')}</option>
                     <option value='REJECT'>{Translate(lang, 'status.REJECT')}</option>
                     <option value='CLOSE'>{Translate(lang, 'status.CLOSE')}</option>
                  </select>
               </Form.Label>

               <Form.Label>
                  <div className={ft.lab}>{Translate(lang, 'global.number')}</div>
                  <Form.Control name='number' defaultValue={filter.name} type='text'/>
               </Form.Label>

               <Form.Label>
                  <div className={ft.lab}>{Translate(lang, 'global.from')}</div>
                  <Form.Control name='start' defaultValue={filter.start} type='date'/>
               </Form.Label>

               <Form.Label>
                  <div className={ft.lab}>{Translate(lang, 'global.to')}</div>
                  <Form.Control name='end' defaultValue={filter.end} type='date'/>
               </Form.Label>

               <button className={`${ft.filter__btn} w-100 mt-2`} type='submit'>
                  {Translate(lang, 'global.search')}
               </button>
            </Form>
         </Filter>

         <CustomModal
            title={Translate(lang, 'modal.sendAnswer')}
            {...{show, setShow, lang}}
         >
            <SendAnswer
               lang={lang}
               taskId={taskId}
               onSuccess={() => {
                  getBadge();
                  getList();
                  setShow(false);
                  setTaskId(null);
               }}
            />
         </CustomModal>

         <CustomModal
            title={Translate(lang, 'modal.answerAssignment')}
            setShow={setShowAnswer}
            show={showAnswer}
            lang={lang}
            size='xl'
         >
            <Answers {...{ lang, taskId }} />
         </CustomModal>
      </>
   )
}

export default ForExecution;