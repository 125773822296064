import CustomModal from "../../../../components/customModal";
import UploadFiles from "../../../../components/uploadFiles";
import ft from "../../../../components/mainStyles.module.scss";
import {Form} from "react-bootstrap";
import {useEffect, useState} from "react";
import {legalStatusApi} from "../../../../services/legalStatusService";
import Loading from "../../../../components/loading";
import Translate from "../../../../components/lang";

const EditLS5 = (props) => {
    const [selFiles, setSelFiles] = useState([]),
        [newFiles, setNewFiles] = useState([]),
        [uploadedFiles, setUploadedFiles] = useState([]),
        [prots, setProts] = useState([]),
        [load, setLoad] = useState(false),
        [errFiles, setErrFiles] = useState(false),
        [errFilesLength, setErrFilesLength] = useState(false),
        {lang, id, data, setLoading, setData, show, setShow, selPer, periods} = props

    useEffect(() => {
        const DATA = []
        data?.files?.forEach((d) => {
            DATA.push({
                name: d.path.slice(15),
                path: d.path,
                file: d.file,
                _id: d._id,
                success: true,
                size: d.size
            })
        })
        setSelFiles(DATA)
        setErrFiles(false)
        setProts([])
        setNewFiles([])
        setUploadedFiles([])
        setErrFilesLength(false)
    }, [show, id])

    const handleSubmit = (e) => {
        e.preventDefault()

        if ((uploadedFiles.length + selFiles.length) <= 3) {
            setErrFilesLength(false)
            if (uploadedFiles.length || selFiles.length) {
                setErrFiles(false)
                const dataFiles = []
                selFiles.map((f) => {
                    if (f.success) dataFiles.push({file: f._id, path: f.path, size: f.size})
                })
                uploadedFiles.map((f) =>
                    dataFiles.push({file: f._id, path: f.path, size: f.size})
                )
                const dataSend = {
                    code: 5,
                    nameOne: e.target[0].value,
                    countOne: e.target[1].value,
                    date: e.target[2].value,
                    files: dataFiles,
                }
                setLoad(true)

                legalStatusApi
                    .edit(id, dataSend)
                    .then(res => {
                        if (res.data.success) {
                            setLoad(false)
                            setLoading(true)
                            legalStatusApi
                                .all({code: 5, period: periods[selPer]?._id, start: periods[selPer]?.start?.slice(0, 10), end: periods[selPer]?.end?.slice(0, 10)})
                                .then((res) => {
                                    setData(res.data.data)
                                    setLoading(false)
                                })
                                .catch((err) => {
                                    console.log(err.message)
                                    setLoading(false)
                                })
                            setShow(false)
                        }
                    })
            } else setErrFiles(true)
        } else setErrFilesLength(true)
    }

    const handleDeleteFile = (e) => {
        const temp = window.confirm(`${Translate(lang, 'global.delFile')}`)
        if (temp) {
            setLoad(true)
            legalStatusApi
                .delFile(e, data._id)
                .then((res) => {
                    if (res.data.success) {
                        setSelFiles((prev) => {
                            let temp = [...prev]
                            return temp.filter((t) => t?._id !== e)
                        })
                        setLoad(false)
                    }
                })
        }
    }

    return (
        <CustomModal
            title={Translate(lang, "L5.title")}
            show={show}
            setShow={setShow}
            size={"lg"}
        >
            <form action="" className="row" onSubmit={handleSubmit}>
                <div className={`col-6 ${ft.form}`}>
                    <Form.Label className="w-100">
                        <div className={ft.lab}>{Translate(lang, "L5.guideName")}</div>
                        <Form.Control
                            type="text"
                            placeholder=""
                            required
                            defaultValue={data?.nameOne}
                        />
                    </Form.Label>
                    <Form.Label className="w-50">
                        <div className={ft.lab}>{Translate(lang, "L5.number")}</div>
                        <Form.Control
                            type="number"
                            placeholder=""
                            required
                            defaultValue={data?.countOne}
                            min='0'
                        />
                    </Form.Label>
                    <br/>
                    <Form.Label className="w-50">
                        <div className={ft.lab}>{Translate(lang, "L5.printDate")}</div>
                        <Form.Control
                            type="date"
                            placeholder=""
                            required
                            defaultValue={data?.date?.slice(0, 10)}
                        />
                    </Form.Label>
                </div>

                <div className={`col-6 ${ft.form}`}>
                    <div className="mb-3 w-100">
                        <UploadFiles
                            {...props}
                            selFiles={selFiles}
                            setSelFiles={setSelFiles}
                            newFiles={newFiles}
                            setNewFiles={setNewFiles}
                            setUploadedFiles={setUploadedFiles}
                            setErrFiles={setErrFiles}
                            load={load}
                            setLoad={setLoad}
                            prots={prots}
                            setProts={setProts}
                            handleDeleteFile={handleDeleteFile}
                            setErrFilesLength={setErrFilesLength}
                        />
                        {errFiles && (
                            <span className={ft.err__mes}>
                  {Translate(lang, "L5.notUploading")}
                </span>
                        )}

                        {errFilesLength && (
                            <span className={ft.err__mes}>
                                Fayllar soni 3tadan ko'p bo'lmasligi kerak
							</span>
                        )}
                    </div>
                </div>
                <div className="col-12 d-flex justify-content-end my-3 mt-5">
                    <button type="button" className={ft.close__btn} onClick={() => setShow(false)}>
                        {Translate(lang, "global.cancel")}
                    </button>
                    <button className={`${ft.save__btn} ml-3`} type="submit">
                        {Translate(lang, "global.save")}
                        {load ? <Loading size="sm" cl="ml-1"/> : ""}
                    </button>
                </div>
            </form>
        </CustomModal>
    );
};

export default EditLS5;
