import axiosInstance from "./api";

const api = "/propaganda-days";

export const propagandaDayApi = {
   getList: (data) =>
      axiosInstance.post(`${api}/list`, data, {
         headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`
         }
      }),
   create: (data) =>
      axiosInstance.post(api, data, {
         headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`
         }
      }),
   update: (id, data) => {
      return axiosInstance.put(`${api}/${id}`, data,{
         headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`
         }
      })
   },
   delete: (id) =>
      axiosInstance.delete(`${api}/${id}`, {
         headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`
         }
      })
};
