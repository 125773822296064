import CustomModal from '../../../../components/customModal'
import ft from '../../../../components/mainStyles.module.scss'
import {Form} from 'react-bootstrap'
import Translate from '../../../../components/lang'
import {legalStatusApi} from '../../../../services/legalStatusService'
import Loading from '../../../../components/loading'
import {useState, useEffect} from 'react'

const EditLS8 = (props) => {
    const [load, setLoad] = useState(false),
        {lang, setLoading, setData, setShow, show, id, data, periods, selPer} =
            props;

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoad(true)
        const data = {
            code: 8,
            nameOne: e.target[0].value,
            link: e.target[1].value,
        }

        legalStatusApi
            .edit(id, data)
            .then(res => {
                if (res.data.success) {
                    setLoad(false)
                    setLoading(true)
                    legalStatusApi
                        .all({
                            code: 8,
                            start: periods[selPer]?.start?.slice(0, 10),
                            end: periods[selPer]?.end?.slice(0, 10),
                            period: periods[selPer]?._id
                        })
                        .then((res) => {
                            setData(res.data.data);
                            setLoading(false);
                        })
                        .catch((err) => {
                            console.log(err.message);
                            setLoading(false);
                        });
                    setShow(false)
                }
            })
    }

    return (
        <CustomModal
            title={Translate(lang, "L8.title")}
            show={show}
            setShow={setShow}
        >
            <form action="" className="row" onSubmit={handleSubmit}>
                <div className={`col-12 ${ft.form}`}>
                    <Form.Label className="w-100">
                        <div className={ft.lab}>{Translate(lang, "L8.video")}</div>
                        <Form.Control
                            type="text"
                            placeholder=""
                            required
                            defaultValue={data?.nameOne}
                        />
                    </Form.Label>
                    <Form.Label className="w-100">
                        <div className={ft.lab}>{Translate(lang, "L1.link")}</div>
                        <Form.Control
                            type="text"
                            placeholder=""
                            required
                            defaultValue={data?.nameOne}
                        />
                    </Form.Label>
                </div>
                <div className="col-12 d-flex justify-content-end my-3">
                    <button
                        type="button"
                        className={ft.close__btn}
                        onClick={() => setShow(false)}
                    >
                        {Translate(lang, "global.cancel")}
                    </button>
                    <button className={`${ft.save__btn} ml-3`} type="submit">
                        {Translate(lang, "global.save")}
                        {load ? <Loading size="sm" cl="ml-1"/> : ""}
                    </button>
                </div>
            </form>
        </CustomModal>
    );
}

export default EditLS8
