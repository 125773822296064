import React from "react";
import st from "./filter.module.scss";
import { closeIcon } from "../icons";
import Translate from "../lang";

export default function Filter(props) {
  const { show, setShow, children, lang } = props;

  return (
    <div className={st.filter}>
      <div
        className={`${st.filter__main} ${show ? st.filter__main__active : ""}`}
      >
        <div className="text-right">
          <span style={{ cursor: "pointer" }} onClick={() => setShow(false)}>
            {closeIcon}
          </span>
        </div>
        <div>
          <h5 className={st.filter__title}>
            {Translate(lang, "global.filter")}
          </h5>
          {children}
        </div>
      </div>
      <div className={show ? st.filter__back : "d-none"}></div>
    </div>
  );
}
