const config = {
   app: {
      isDev: 'development',
   },
   api: {
      // baseUrl: 'http://api.tujjor.org',
      baseUrl: 'https://cdn.huquqiyportal.uz',
      // baseUrl: 'http://localhost:6600',
   },
   list: {
      limit: 10
   },
};

export default config;