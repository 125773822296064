import CustomModal from "../../../../components/customModal";
import ft from "../../../../components/mainStyles.module.scss";
import { legalStatusApi } from "../../../../services/legalStatusService";
import Loading from "../../../../components/loading";
import Translate from "../../../../components/lang";

const Description = (props) => {
  const { show, setShow, description, load,lang } = props;

  return (
    <CustomModal
      show={show}
      setShow={setShow}
      size={"md"}
      title={Translate(lang, "global.fullInfos")}
    >
      {load ? (
        <div className="d-flex justify-content-center align-items-center">
          <Loading size="md" />
        </div>
      ) : (
        <p style={{ fontSize: "15px", wordWrap: "break-word" }}>
          {description?.description}
        </p>
      )}
    </CustomModal>
  );
};

export default Description;
