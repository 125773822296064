import Container from '../../components/container';
import { Tabs, Tab } from 'react-bootstrap';
import LeftSide from './leftSide';
import RightSide from './rightSide';
import Translate from '../../components/lang';
import { Route, Redirect, Switch } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { legalStatusApi } from '../../services/legalStatusService';
import Loading from '../../components/loading';
import st from './legalStatus.module.scss';

import LS1 from './LS1';
import LS2 from './LS2';
import LS3 from './LS3';
import LS4 from './LS4';
import LS5 from './LS5';
import LS6 from './LS6';
import LS7 from './LS7';
import LS8 from './LS8';
import LS9 from './LS9';
import LS10 from './LS10';
import LS11 from './LS11';
import LS12 from './LS12';
import LS13 from './LS13';
import Comments from './comments';

export default function LegalStatus(props) {
  const [pageUrl, setPageUrl] = useState(null),
    [loading, setLoading] = useState(true),
    { markingStarted } = props;
  return (
    <div>
      <Switch>
        <Route
          path='/legal-status/1'
          exact
          render={() => <LS1 {...props} />}
        />
        <Route
          path='/legal-status/2'
          exact
          render={() => <LS2 {...props} />}
        />
        <Route
          path='/legal-status/3'
          exact
          render={() => <LS3 {...props} />}
        />
        <Route
          path='/legal-status/4'
          exact
          render={() => <LS4 {...props} />}
        />
        <Route
          path='/legal-status/5'
          exact
          render={() => <LS5 {...props} />}
        />
        <Route
          path='/legal-status/6'
          exact
          render={() => <LS6 {...props} />}
        />
        <Route
          path='/legal-status/7'
          exact
          render={() => <LS7 {...props} />}
        />
        <Route
          path='/legal-status/8'
          exact
          render={() => <LS8 {...props} />}
        />
        <Route
          path='/legal-status/9'
          exact
          render={() => <LS9 {...props} />}
        />
        <Route
          path='/legal-status/10'
          exact
          render={() => <LS10 {...props} />}
        />
        <Route
          path='/legal-status/11'
          exact
          render={() => <LS11 {...props} />}
        />
        <Route
          path='/legal-status/12'
          exact
          render={() => <LS12 {...props} />}
        />
        <Route
          path='/legal-status/13'
          exact
          render={() => <LS13 {...props} />}
        />
        <Route
          path='/legal-status'
          exact
          render={() => (
            <LegalStatusMain
              setPageUrl={setPageUrl}
              {...props}
              loading={loading}
              setLoading={setLoading}
              markingStarted={markingStarted}
            />
          )}
        />
        <Redirect to='/' />
      </Switch>
    </div>
  );
}

function LegalStatusMain(props) {
  const {
    lang,
    setPageUrl,
    periods,
    selPer,
    loading,
    setLoading,
    markingStarted,
  } = props,
    [data2, setData2] = useState([]),
    [comment, setComment] = useState([]),
    [leftData, setLeftData] = useState([]),
    [isHund, setIsHund] = useState(true),
    [loading2, setLoading2] = useState(true),
    items = [
      {
        uz: 'Huquqiy savodxonlikni oshirishga doir aksiyalar, fleshmoblar va boshqa ommaviy tadbirlar tashkil etilishi',
        oz: 'Ҳуқуқий саводхонликни оширишга доир акциялар, флешмоблар ва бошқа оммавий тадбирлар ташкил этилиши',
        ru: 'Организация акций, флешмобов и других массовых мероприятий по повышению правовой грамотности',
      },
      {
        uz: 'Huquqiy savodxonlik darajasini oshirish bo‘yicha o‘qitish',
        oz: 'Ҳуқуқий саводхонлик даражасини ошириш бўйича ўқитиш',
        ru: 'Обучение юридической грамотности',
      },
      {
        uz: 'Mahallalarda o‘tkazilgan manzilli targ‘ibot tadbirlari',
        oz: 'Маҳаллаларда ўтказилган манзилли тарғибот тадбирлари',
        ru: 'Целенаправленная пропагандистская деятельность в махаллях',
      },
      {
        uz: 'Tadbirkorlik subyektlari o‘rtasida o‘tkazilgan manzilli targ‘ibot tadbirlari',
        oz: 'Тадбиркорлик субъектлари ўртасида ўтказилган манзилли тарғибот тадбирлари',
        ru: 'Целенаправленная информационно-пропагандистская деятельность среди субъектов хозяйственной деятельности',
      },
      {
        uz: 'Huquqiy qo‘llanmalarning yaratilishi va tarqatilishi',
        oz: 'Ҳуқуқий қўлланмаларнинг яратилиши ва тарқатилиши',
        ru: 'Создание и распространение юридических применение (справочника)',
      },
      {
        uz: 'Huquqni targ‘ib qilishga doir ko‘rgazmali targ‘ibot materiallari (flayer, buklet, infografika) tayyorlanishi',
        oz: 'Ҳуқуқни тарғиб қилишга доир кўргазмали тарғибот материаллари (флайер, буклет, инфографика) тайёрланиши',
        ru: 'Подготовка наглядных агитационных материалов (листовки, буклеты, инфографика) по пропаганде правовых актов',
      },
      {
        uz: 'Huquqiy savodxonlikni oshirishga doir multiplikatsion filmlar yaratilishi',
        oz: 'Ҳуқуқий саводхонликни оширишга доир мултипликацион филмлар яратилиши',
        ru: 'Создание анимационных фильмов для повышения правовой грамотности',
      },
      {
        uz: 'Sohaga oid ijtimoiy huquqiy tusdagi videoroliklar, film va seriallar yaratilishi',
        oz: 'Соҳага оид ижтимоий ҳуқуқий тусдаги видеороликлар, филм ва сериаллар яратилиши',
        ru: 'Создание социально-правовых видеороликов, фильмов и сериалов на местах',
      },
      {
        uz: 'Korrupsiyaga qarshi kurashishni targ‘ib qilishga doir videoroliklar yaratilishi',
        oz: 'Коррупцияга қарши курашишни тарғиб қилишга доир видеороликлар яратилиши',
        ru: 'Создание видеороликов для пропаганды борьбы против коррупции',
      },
      {
        uz: 'Davlat organlarining ijtimoiy tarmoqlardagi sahifalari va kanallari foydalanuvchilari',
        oz: 'Давлат органларининг ижтимоий тармоқлардаги саҳифалари ва каналлари фойдаланувчилари',
        ru: 'Пользователи социальных сетей и каналов государственных органов',
      },
      {
        uz: 'Huquqiy targ‘ibotni amalga oshirishda yangi targ‘ibot turlaridan foydalanganlik darajasi',
        oz: 'Ҳуқуқий тарғиботни амалга оширишда янги тарғибот турларидан фойдаланганлик даражаси',
        ru: 'Уровень использования новых видов осведомления при осуществлении правовой пропаганды',
      },
      {
        uz: 'Ommaviy axborot vositalari bilan amalga oshirilgan brifinglar',
        oz: 'Оммавий ахборот воситалари билан амалга оширилган брифинглар',
        ru: 'Средства проведения брифингов для СМИ',
      },
      {
        uz: "Boshqa davlat organlari, ta'lim muassasalari, sudlar, huquqni muhofaza qiluvchi organlar hamkorligidagi tadbirlar",
        oz: 'Бошқа давлат органлари, таълим муассасалари, судлар, ҳуқуқни муҳофаза қилувчи органлар ҳамкорлигидаги тадбирлар',
        ru: 'Сотрудничество с другими государственными органами, образовательными учреждениями, судами, правоохранительными органами',
      },
      {
        uz: '',
        oz: '',
        ru: '',
      },
    ],
    isHundred = [6, 9, 10, 10, 8, 5, 9, 10, 10, 5, 6, 6, 6],
    isSeventy = [4, 6, 7, 7, 5, 4, 7, 7, 7, 4, 4, 4, 4];

  useEffect(() => {
    setLoading(true);
    setLoading2(true);
    legalStatusApi
      .filter({
        limit: 200,
        page: 1,
        start: periods[selPer]?.start,
        end: periods[selPer]?.end,
        period: periods[selPer]?._id,
      })

      .then((res) => {
        if (res.data.success) {
          setData2(res.data);
          const DATA = res.data.data;
          
          legalStatusApi
            .getAll({
              start: periods[selPer]?.start,
              end: periods[selPer]?.end,
              period: periods[selPer]?._id,
            })
            .then((res) => {
              if (res.data.success) {
                let DATA2 = res.data.data,
									temp = [];
								DATA2.forEach((d) => {
									if (d?.code == 14) {
										temp.push({
											score: d?.score,
											expert: d?.expert?.fullName,
											role: d?.expert?.position[lang],
											comment: d?.comment,
										});
									}
								});
								setComment(temp);
                
                const LEFTDATA = res.data.data;
                if (DATA.length) {
                  setIsHund(false);
                  setLeftData(() => {
                    const temp = [];
                    items.forEach((item, i) => {
                      let d = LEFTDATA.filter((d) => +d.code === i + 1);
                      temp.push({
                        title: {
                          uz: item.uz,
                          ru: item.ru,
                          oz: item.oz,
                        },
                        maxNum: isSeventy[i],
                        expert: d[0]?.expert.fullName ? d[0].expert : "",
                        score: `${d[0]?.score}.length` ? d[0]?.score : -1,
                      });
                    });
                    return temp;
                  });
                } else {
                  setLeftData(() => {
                    const temp = [];
                    items.forEach((item, i) => {
                      let d = LEFTDATA.filter((d) => +d.code === i + 1);
                      temp.push({
                        title: {
                          uz: item.uz,
                          ru: item.ru,
                          oz: item.oz,
                        },
                        maxNum: isHundred[i],
                        expert: d[0]?.expert ? d[0].expert : "",
                        score: d[0]?.score ? d[0].score : -1,
                        // date: d[0]?.updatedAt ? d[0].updatedAt : '',
                      });
                    });
                    return temp;
                  });
                }
              }
              setLoading(false);
              setLoading2(false);
            })

            .catch((err) => {
              console.log(err.message);
              setLoading(false);
            });
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);
  if (loading) return <Loading size='lg' big={true} />;
  return (
    <div>
      <div>
        <Tabs defaultActiveKey='first' id='uncontrolled-tab-example'>
          <Tab
            eventKey='first'
            title={Translate(lang, 'legalStatus.left')}>
            <Container className={st.rounded}>
              <LeftSide
                {...props}
                loading2={loading2}
                loading={loading}
                data={leftData}
                isHund={isHund}
                markingStarted={markingStarted}
              />
            </Container>
          </Tab>

          <Tab
            eventKey='sec'
            title={Translate(lang, 'legalStatus.right')}>
            <Container>
              <RightSide
                {...props}
                data2={data2}
                setData2={setData2}
                loading={loading}
                setLoading={setLoading}
              />
            </Container>
            {comment.length ? (
              <Comments
                num={comment[0]?.score}
                expert={comment[0]?.expert}
                position={comment[0]?.role}>
                {comment[0]?.comment}
              </Comments>
            ) : null}
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}
