import ft from "../../../../components/mainStyles.module.scss";
import {Form} from "react-bootstrap";
import CustomModal from "../../../../components/customModal";
import Translate from "../../../../components/lang";
import UploadFiles from "../../../../components/uploadFiles";
import {useEffect, useState} from "react"
import {legalStatusApi} from "../../../../services/legalStatusService";
import Loading from "../../../../components/loading";

function RightSideEdit(props) {
    const [selFiles, setSelFiles] = useState([]),
        [newFiles, setNewFiles] = useState([]),
        [uploadedFiles, setUploadedFiles] = useState([]),
        [prots, setProts] = useState([]),
        [load, setLoad] = useState(false),
        [errFiles, setErrFiles] = useState(false),
        [errFilesLength, setErrFilesLength] = useState(false),
        {lang, id, data, setLoading, setData2, show, setShow, periods, selPer} = props;

    useEffect(() => {
        const DATA = []
        data?.respondFiles?.forEach((d) => {
            DATA.push({
                name: d.path.slice(15),
                path: d.path,
                file: d.file,
                _id: d._id,
                success: true,
                size: d.size
            })
        })
        setSelFiles(DATA)
        setErrFiles(false)
        setProts([])
        setNewFiles([])
        setUploadedFiles([])
    }, [show, id])

    const handleSubmit = (e) => {
        e.preventDefault();
        if (uploadedFiles.length || selFiles.length) {
            setErrFiles(false);
            const dataFiles = []
            selFiles.map((f) => {
                if (f.success) dataFiles.push({file: f._id, path: f.path, size: f.size})
            })
            uploadedFiles.map((f) =>
                dataFiles.push({file: f._id, path: f.path, size: f.size})
            )
            const dataSend = {
                status: 1,
                countOne: e.target[0].value,
                respondFiles: dataFiles,                
            }

            setLoad(true);
            legalStatusApi
                .task(id, dataSend)
                .then(res => {
                    if (res.data.success) {
                        setLoad(false);
                        setLoading(true);
                        legalStatusApi
                            .filter({
                                limit: 200, page: 1, start: periods[selPer]?.start,
                                end: periods[selPer]?.end,
                                period: periods[selPer]?._id,

                            })
                            .then((res) => {
                                setData2(res.data)
                                setLoading(false)
                            })
                            .catch((err) => {
                                console.log(err.message)
                                setLoading(false)
                            })
                        setShow(false);
                    }
                });
        } else setErrFiles(true);
    }

    const handleDeleteFile = (e) => {
        const temp = window.confirm(`${Translate(lang, 'global.delFile')}`)
        if (temp) {
            setLoad(true)
            legalStatusApi
                .delFile(e, data._id)
                .then((res) => {
                    if (res.data.success) {
                        setSelFiles((prev) => {
                            let temp = [...prev]
                            return temp.filter((t) => t?._id !== e)
                        })
                        setLoad(false)
                    }
                })
        }
    }

    return (
        <CustomModal
            show={show}
            setShow={setShow}
            size={"lg"}
        >

            <form action="" className="row" onSubmit={handleSubmit}>
                <div className={`col-7 ${ft.form}`}>
                    <Form.Label className="w-100" style={{marginTop: "-30px"}}>
                        <div className={ft.lab}>{Translate(lang, "global.fullInfo")}</div>
                        <Form.Control
                            as="textarea"
                            rows={4}
                            required
                            defaultValue={data?.fullDescription}
                        />
                    </Form.Label>
                </div>

                <div className={`col-5 ${ft.form}`}>
                    <div className="mb-3 w-100">
                        <UploadFiles
                            {...props}
                            selFiles={selFiles}
                            setSelFiles={setSelFiles}
                            newFiles={newFiles}
                            setNewFiles={setNewFiles}
                            setUploadedFiles={setUploadedFiles}
                            setErrFiles={setErrFiles}
                            load={load}
                            setLoad={setLoad}
                            prots={prots}
                            setProts={setProts}
                            handleDeleteFile={handleDeleteFile}
                            setErrFilesLength={setErrFilesLength}
                        />
                        {errFiles && (
                            <span className={ft.err__mes}>Fayl yoki rasm yuklanmadi!</span>
                        )}
                        {errFilesLength && (
                            <span className={ft.err__mes}>
                                Fayllar soni 3tadan ko‘p bo‘lmasligi kerak
							</span>
                        )}
                    </div>
                </div>
                <div className="col-12 d-flex justify-content-end mt-5">
                    <button type="button" className={ft.close__btn} onClick={() => setShow(false)}>
                        {Translate(lang, "global.cancel")}
                    </button>
                    <button className={`${ft.save__btn} ml-3`} type="submit">
                        {Translate(lang, "global.save")}{' '}
                        {load ? <Loading size='sm' cl='ml-1'/> : ''}
                    </button>
                </div>
            </form>

        </CustomModal>
    );
}

export default RightSideEdit;
