import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';

import config from '../../../config';

import { docReceivedApi } from '../../../services/doc/docReceivedService';

import Pagin from '../../../components/pagination';
import Filter from '../../../components/filter';
import Translate from '../../../components/lang';
import Loading from '../../../components/loading';
import Container from '../../../components/container';
import CustomModal from '../../../components/customModal';

import { BeautyFullTime } from '../../../utils';

import { eye } from '../../../components/icons';

import View from './components/View';

import ft from '../../../components/mainStyles.module.scss';
import cls from '../forExecution/ForExecution.module.scss';

const ForInformation = ({ lang, user, getBadge }) => {
   const [data, setData] = useState([]);
   const [filter, setFilter] = useState({});
   const [loading, setLoading] = useState(true);
   const [show, setShow] = useState(false);
   const [docReceived, setDocReceived] = useState(null);
   const [showFilter, setShowFilter] = useState(false);
   const [pc, setPc] = useState(0);
   const [pageNum, setPageNum] = useState(1);
   const [page, setPage] = useState({ limit: 10, page: 1 });

   const onUpdateStatus = () => {
      if (docReceived?.status === 'NEW') {
         getBadge();
         getList();
      }
   }

   const handleFilter = (e) => {
      e.preventDefault();

      const { docType, type, number, start, end } = e.target.elements;

      const newFilter = {};

      if (docType.value !== 'all') newFilter.docType = docType.value;
      if (type.value !== 'all') newFilter.type = type.value;
      if (number.value) newFilter.number = number.value;
      if (start.value) newFilter.start = start.value;
      if (end.value) newFilter.end = end.value;

      setFilter(newFilter);

      setShowFilter(false);

      setPage({ ...page, page: 1 });
   }

   const getList = () => {
      setLoading(true);

      const data = {
         ...page,
         ...filter,
         orgId: user._id
      }

      docReceivedApi
        .getList(data)
        .then(res => {
           if (res.data.success) {
              setPageNum(Math.ceil(res.data.num));
              setPc((page.page - 1) * page.limit);
              setData(res.data.data);
           }
        })
        .catch(err => {
           console.log(err);
        })
        .finally(() => {
           setLoading(false);
        });
   }

   useEffect(() => {
      getList();
   }, [page]);

   return (
      <div>
         <Container>
            <div className={ft.content__head}>
               <h5 className={ft.title}>
                  {Translate(lang, 'forInformation.title')}
               </h5>

               <div className={ft.btns}>
                  <button
                     className={`${ft.primary__btn} ml-2`}
                     onClick={() => setShowFilter(true)}
                  >
                     {Translate(lang, 'global.filter')}
                  </button>
               </div>
            </div>

            <div className={ft.table}>
               <table id='table' className={ft.table__main}>
                  <thead>
                     <tr>
                        <td>#</td>
                        <td>{Translate(lang, 'doc.number')}</td>
                        <td>{Translate(lang, 'doc.description')}</td>
                        <td>{Translate(lang, 'doc.type')}</td>
                        <td>{Translate(lang, 'doc.link')}</td>
                        <td>{Translate(lang, 'table.file')}</td>
                        <td>{Translate(lang, 'doc.sendType')}</td>
                        <td>{Translate(lang, 'table.createdAt')}</td>
                        <td>{Translate(lang, 'table.status')}</td>
                        <td className='text-center'>{Translate(lang, 'table.action')}</td>
                     </tr>
                  </thead>

                  <tbody>
                     {!loading ? (
                        data.map((item, index) => (
                          <tr key={item._id}>
                             <td>{pc + index + 1}</td>
                             <td>{item.doc?.number}</td>
                             <td>{item.doc?.description}</td>
                             <td>
                                <div className={cls.type}>
                                   {Translate(lang, `doc.${item.doc.type?.toLowerCase()}`)}
                                </div>
                             </td>
                             <td>{item.doc?.link}</td>
                             <td className='text-nowrap'>
                                {item.doc?.files.map((file, index) => (
                                  <div key={index}>
                                     <a target='_blank' href={`${config.api.baseUrl}${file?.path}`}>
                                        {file?.path?.slice(15)}
                                     </a>
                                  </div>
                                ))}
                             </td>
                             <td>
                                <div className={cls.type}>
                                   {Translate(lang, `doc.${item.type?.toLowerCase()}`)}
                                </div>
                             </td>
                             <td>
                                <div style={{width: 180}}>
                                   {BeautyFullTime(item.createdAt)}
                                </div>
                             </td>
                             <td>{Translate(lang, `status.${item.status}`)}</td>
                             <td>
                                <button
                                  className={ft.eye__btn}
                                  onClick={() => {
                                     setDocReceived(item);
                                     setShow(true);
                                  }}
                                >
                                   {eye}
                                </button>
                             </td>
                          </tr>
                        ))
                     ) : (
                       <tr>
                          <td colSpan={10}>
                             <Loading cl='mt-3' big={true} size='md'/>
                          </td>
                       </tr>
                     )}
                  </tbody>
               </table>

               {!loading && !data.length && (
                 <h6 className={ft.noData}>
                     {Translate(lang, 'table.notFound')}
                  </h6>
               )}

               {!loading && (
                  <Pagin pageNum={pageNum} page={page} setPage={setPage} />
               )}
            </div>
         </Container>

         <Filter show={showFilter} setShow={setShowFilter}>
            <Form className={ft.form} onSubmit={handleFilter}>
               <Form.Label>
                  <div className={ft.lab}>{Translate(lang, 'doc.type')}</div>
                  <select name='docType'>
                     <option value='all'>{Translate(lang, 'global.all')}</option>
                     <option value='DECISION'>{Translate(lang, 'doc.decision')}</option>
                     <option value='DECREE'>{Translate(lang, 'doc.decree')}</option>
                     <option value='ORDER'>{Translate(lang, 'doc.order')}</option>
                     <option value='LAW'>{Translate(lang, 'doc.law')}</option>
                  </select>
               </Form.Label>

               <Form.Label>
                  <div className={ft.lab}>{Translate(lang, 'doc.sendType')}</div>
                  <select name='type'>
                     <option value='all'>{Translate(lang, 'global.all')}</option>
                     <option value='MAIN'>{Translate(lang, 'doc.main')}</option>
                     <option value='ADDITIONAL'>{Translate(lang, 'doc.additional')}</option>
                  </select>
               </Form.Label>

               <Form.Label>
                  <div className={ft.lab}>{Translate(lang, 'global.number')}</div>
                  <Form.Control name='number' defaultValue={filter.name} type='text' />
               </Form.Label>

               <Form.Label>
                  <div className={ft.lab}>{Translate(lang, 'global.from')}</div>
                  <Form.Control name='start' defaultValue={filter.start} type='date' />
               </Form.Label>

               <Form.Label>
                  <div className={ft.lab}>{Translate(lang, 'global.to')}</div>
                  <Form.Control name='end' defaultValue={filter.end} type='date' />
               </Form.Label>

               <button className={`${ft.filter__btn} w-100 mt-2`} type='submit'>
                  {Translate(lang, 'global.search')}
               </button>
            </Form>
         </Filter>

         <CustomModal
           title='Hujjat'
           setShow={setShow}
           show={show}
           lang={lang}
           size='xl'
         >
            <View
              lang={lang}
              docReceived={docReceived}
              onUpdateStatus={onUpdateStatus}
            />
         </CustomModal>
      </div>
   )
}

export default ForInformation;