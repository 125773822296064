import CustomModal from '../../../components/customModal'
import ft from '../../../components/mainStyles.module.scss'
import { Form } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import Translate from '../../../components/lang'
import Loading from '../../../components/loading'
import Circle from '../../../components/circle'
import { chevronUp, chevronDown, equal } from '../../../components/icons'
import ExportPdf from '../../../components/exportPdf'
const EksportModal = (props) => {
	const [prots, setProts] = useState([]),
		[load, setLoad] = useState(false),
		{ lang, loading, setLoading, data, setShow, show, periods, selPer } = props

	return (
		<CustomModal title={Translate(lang, 'pdfTitle.title')} show={show} setShow={setShow} size={'lg'}>
			<ExportPdf rey='true' {...props} title={Translate(lang, 'pdfTitle.title')} id='table2' />
			<div className={ft.table}>
				<table className={`${ft.table__main}`} style={{ fontSize: '13px' }} id='table2'>
					<thead>
						<tr>
							<td className='pl-3'>#</td>
							<td> {Translate(lang, 'rating.orgName')}</td>
							<td> {Translate(lang, 'rating.orgType')}</td>
							<td className='text-center'>{Translate(lang, 'rating.byEvents')}</td>
							<td className='text-center'>{Translate(lang, 'rating.byTask')}</td>
							<td className='text-center mr-3'>{Translate(lang, 'rating.totalScore')}</td>
						</tr>
					</thead>
					<tbody>
						{!loading ? (
							data.map((d, index) => (
								// d?.id !== user?._id ? (

								<tr>
									<td className='pl-3'>{index + 1}</td>
									<td>
										<div className='d-flex align-items-center'>
											{d.placeIcon === 'up' ? chevronUp : d.placeIcon === 'down' ? chevronDown : equal}
											{d.org[lang]}
										</div>
									</td>
									<td>{d.type === 0 ? Translate(lang, 'passport.orgName1') : Translate(lang, 'passport.orgName2')}</td>
									<td>
										<div className='d-flex justify-content-center'>
											<Circle
												num={periods?.length === 1 ? 0 : d?.left ? Math.round(d?.left * 10) / 10 : 0}
												col='88, 85, 214'
												size='40px'
											/>
										</div>
									</td>
									<td>
										<div className='d-flex justify-content-center'>
											<Circle
												num={periods?.length === 1 ? 0 : d?.right ? Math.round(d?.right * 10) / 10 : 0}
												col='239, 93, 168'
												size='40px'
											/>
										</div>
									</td>
									<td className='mr-3'>
										<div className='d-flex justify-content-center'>
											<Circle num={periods?.length === 1 ? 0 : Math.round(d?.total * 10) / 10} col='7, 162, 135' size='40px' />
										</div>
									</td>
								</tr>
								// ) : ''
							))
						) : (
							<Loading big={true} size='md' cl='mt-3' />
						)}
					</tbody>
				</table>
			</div>
		</CustomModal>
	)
}

export default EksportModal
