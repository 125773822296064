import axiosInstance from './api'

const api = '/organizations/image/'
const api2 = '/auth/users/reset-password'
export const changeImgApi = {
		orgImg: (data ,headers) => {
			return axiosInstance.put(
				`${api}`,data,headers
			)
		},
		
}

export const passwordApi = {
	password: ( data, headers ) => {
		return axiosInstance.put(`${api2}`, data, headers)
	}
}
