export function BeautyDate(num) {
	return num?.toString().split('').slice(0, 10).join('').split('-').reverse().join('.');
}

export function BeautyTime(num) {
	return num?.toString().split('').slice(11, 19).join('');
}

export function BeautyFullTime(num) {
	return BeautyDate(num) + ' ' + BeautyTime(num);
}

export function GetStat(data) {
	const temp = [...data];
	data.forEach((d, i) => {
		let sum = 0;
		d.stat.forEach((s) => {
			if (s._id === 0) {
				temp[i].stat0 = s.count;
				sum += s.count;
			} else if (s._id === 1) {
				temp[i].stat1 = s.count;
				sum += s.count;
			} else if (s._id === 2) {
				temp[i].stat2 = s.count;
				sum += s.count;
			} else if (s._id === 3) {
				temp[i].stat3 = s.count;
				sum += s.count;
			}
		});
		temp[i].sum = sum;
	});
	return temp;
}

export function TextValidate(text) {
	const regex = /[^0-9]/g;
	let word = text.value;

	text.value = word
		?.split('')
		.filter((letter) => letter.match(regex))
		.join('');
}

export function SortReyt(newData, oldData) {
	const temp = [];
	const	newDATA = JamesSort(newData);
	const	oldDATA = JamesSort(oldData);

	newDATA.forEach(d => {
		const f = oldDATA.filter(o => o.organization[0]?._id === d.organization[0]?._id);

		if (f[0]?.place > d.place) {
			temp.push({ ...d, placeIcon: 'up' });
		} else if (f[0]?.place < d.place) {
			temp.push({ ...d, placeIcon: 'down' });
		} else {
			temp.push({ ...d, placeIcon: 'equal' });
		}
	});

	return temp;
}

function JamesSort(data) {
	const temp = JamesFormatter(data);

	const mas = temp.sort((a, b) => {
		if (a.totalBall > b.totalBall) return 1;
		if (a.totalBall < b.totalBall) return -1;
		if (a?.organization[0]?.name?.uz < b?.organization[0]?.name?.uz) return 1;
		if (a?.organization[0]?.name?.uz > b?.organization[0]?.name?.uz) return -1;
		return 0;
	});

	let count = 1;
	let lastTotal = mas[0].totalBall;

	return mas.map((item) => {
		if (lastTotal === item.totalBall) {
			return { ...item, place: count };
		} else {
			count++;
			lastTotal = item.totalBall;
			return { ...item, place: count };
		}
	}).reverse();
}

function JamesFormatter(data) {
	const temp = [];

	data.forEach((item) => {
		temp.push({
			...item,
			totalBall: item?.ball?.all || 0
		});
	});

	return temp;
}

function GeneralSort(data) {
	const temp = [];

	for (let i = 0; i < data.length; i++) {
		const arr = [
			{
				...data[i],
				place: i + 1,
				totalBall: data[i].res?.total ? data[i].res.total : data[i].res,
			},
		];
		let j = i + 1;
		for (; j < data.length; j++) {
			if (
				(data[i].res?.total ? data[i].res.total : data[i].res) >
				(data[j].res?.total ? data[j].res.total : data[j].res)
			) {
				break;
			}
			arr.push({
				...data[j],
				place: i + 1,
				totalBall: data[j].res?.total ? data[j].res.total : data[j].res,
			});
		}
		i = j - 1;
		temp.push(
			...arr.sort(function (a, b) {
				let nameA = a.name.uz.toLowerCase(),
					nameB = b.name.uz.toLowerCase();
				if (nameA < nameB) return -1;
				if (nameA > nameB) return 1;
				return 0;
			})
		);
	}
	return temp;
}

export function ValidatePassword(text) {
	if (!text.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/)?.length) return false;
	return true;
}

export function GeneratePassword() {
	var length = 8,
		charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%,&*();',
		retVal = '';
	for (var i = 0, n = charset.length; i < length; ++i) {
		retVal += charset.charAt(Math.floor(Math.random() * n));
	}
	if (!retVal.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/)?.length)
		return GeneratePassword();
	return retVal;
}
