import React, { useState } from 'react';
import { Form } from 'react-bootstrap';

import { propagandaDayApi } from '../../../services/propagandaDayService';

import Translate from '../../../components/lang';
import Loading from '../../../components/loading';
import CustomModal from '../../../components/customModal';
import UploadFiles from '../../../components/uploadFiles';

import ft from '../../../components/mainStyles.module.scss';

const Create = ({ lang, show, setShow, getList }) => {
    const [load, setLoad] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errFiles, setErrFiles] = useState(false);
    const [prots, setProts] = useState([]);
    const [newFiles, setNewFiles] = useState([]);
    const [uploadedFiles, setUploadedFiles] = useState([]);

    const onClose = () => {
      setShow(false);
      setErrFiles(false);
      setProts([]);
      setNewFiles([]);
      setUploadedFiles([]);
    }

    const onSubmit = (e) => {
       e.preventDefault();

       const { topic, shape } = e.target.elements;

        const files = uploadedFiles.map(file => ({
            file: file._id,
            path: file.path,
            size: file.size
        }));

       const data = {
          topic: topic.value,
          shape: shape.value,
          files: files
       };

       setLoading(true);

        propagandaDayApi
          .create(data)
          .then(res => {
             if (res.data.success) {
               onClose();
               getList();
             }
          })
          .catch(err => {
             console.log(err);
          })
          .finally(() => {
             setLoading(false);
          });
    };

    return (
      <CustomModal
        title={Translate(lang, 'global.add')}
        setShow={setShow}
        show={show}
        size='md'
      >
          <Form className={ft.form} onSubmit={onSubmit}>
              <Form.Label className='w-100'>
                  <div className={ft.lab}>{Translate(lang,'global.topic')}</div>
                  <Form.Control name='topic' type='text' required />
              </Form.Label>

              <Form.Label className='w-100'>
                  <div className={ft.lab}>{Translate(lang, 'global.shape')}</div>
                  <Form.Control name='shape' type='text' required />
              </Form.Label>

              <Form.Label className='w-100'>
                  <div className={ft.lab}>{Translate(lang, 'global.filesAndImages')}</div>
                  <UploadFiles
                      setUploadedFiles={setUploadedFiles}
                      setNewFiles={setNewFiles}
                      setErrFiles={setErrFiles}
                      setProts={setProts}
                      newFiles={newFiles}
                      setLoad={setLoad}
                      prots={prots}
                      load={load}
                      lang={lang}
                  />
                  {errFiles && (
                      <span className={ft.err__mes}>
                          {Translate(lang, 'global.notUploaded')}
                      </span>
                  )}
              </Form.Label>

              <div className='d-flex justify-content-end mt-2'>
                  <button
                      className={`${ft.close__btn} mr-2`}
                      onClick={onClose}
                      type='button'
                  >
                      {Translate(lang, 'global.cancel')}
                  </button>

                  <button className={ft.save__btn} type='submit'>
                      {Translate(lang, 'global.save')}{' '}
                      {loading ? <Loading cl='ml-1' size='sm' /> : ''}
                  </button>
              </div>
          </Form>
      </CustomModal>
    );
};

export default Create;
