import { useState, useEffect, useRef } from 'react';
import st from './structure.module.scss';
import ft from '../../components/mainStyles.module.scss';
import { Form } from 'react-bootstrap';
import Container from '../../components/container';
import Del from '../../components/del';
import { edit, del, search } from '../../components/icons';
import Translate from '../../components/lang';
import { structureApi } from '../../services/structureService';
import Loading from '../../components/loading';
import StructureAdd from './structureAdd';
import StructureEdit from './structureEdit';

const Structure = (props) => {
	const [data, setData] = useState(null),
		[showDel, setShowDel] = useState(false),
		[showEdit, setShowEdit] = useState(false),
		[show, setShow] = useState(false),
		[loading, setLoading] = useState(false),
		[load, setLoad] = useState(false),
		[id, setId] = useState(null),
		{ lang } = props,
		[editData, setEditData] = useState(''),
		[filteredData, setFilteredData] = useState([]),
		[inputValue, setInputValue] = useState(''),
		inputRef = useRef();

	useEffect(() => {
		setLoading(true);
		// setLoad(true);
		structureApi
			.all()
			.then((res) => {
				setData(res.data.data);
				setLoading(false);
				// setLoad(false);
			})
			.catch((err) => {
				setLoading(false);
				setLoad(false);
			});
	}, []);

	const openDel = (id) => {
		setShowDel(true);
		setId(id);
	};

	const deleteHandler = (id) => {
		setLoad(true);
		structureApi.del(id).then((res) => {
			if (res.data.success) {
				setLoad(false);
				setLoading(true);
				structureApi
					.all()
					.then((res) => {
						const data = res.data;
						setData(data.data);
						setLoading(false);
					})
					.catch((err) => {
						setLoading(false);
					});
				setShowDel(false);
			}
		});
		setId(null);
	};

	//
	// useEffect(() => {
	//     if (inputValue === inputRef.current.value) {
	//
	//         setFilteredData(data?.filter(e =>
	//             (
	//                 String(e.name[lang]).toLowerCase().includes(inputValue.toLowerCase()) ||
	//                 String(e.name[lang]).toLowerCase().includes('')
	//             )))
	//     }
	// }, [])

	useEffect(() => {
		setInputValue('');
		setFilteredData(data);
	}, [data]);

	useEffect(() => {
		if (inputValue === '') {
			setFilteredData(data);
		} else {
			var timer = setTimeout(() => {
				if (inputValue === inputRef.current.value) {
					setFilteredData(
						data?.filter((e) => String(e.name[lang]).toLowerCase().includes(inputValue.toLowerCase()))
					);
				}
			}, 1000);
		}

		return () => {
			clearTimeout(timer);
		};
	}, [inputValue]);

	const inputValueHandler = (event) => {
		const { value } = event.target;
		setInputValue(value);
	};

	let tableData = null;

	if (filteredData) {
		tableData = filteredData?.map((d, index) => (
			<tr key={index}>
				<td>{index + 1}</td>
				<td>{d?.name ? d?.name[lang] : '...'}</td>
				<td style={{ maxWidth: '150px' }}>
					{d?.leader.length < 30 ? d?.leader : d?.leader.slice(0, 30) + '...'}
				</td>
				<td style={{ maxWidth: '150px' }}>
					{d?.legalAddress.length < 30 ? d?.legalAddress : d?.legalAddress.slice(0, 30) + '...'}
				</td>
				<td>{d?.phone}</td>
				<td>{d?.email}</td>
				<td>
					<div className='d-flex justify-content-center'>
						<button
							className={ft.edit__btn}
							onClick={() => {
								setShowEdit(true);
								setId(d?._id);
								setEditData(d);
							}}>
							{edit}
						</button>
						<button
							className={ft.del__btn}
							onClick={() => {
								openDel(d?._id);
							}}>
							{del}
						</button>
					</div>
				</td>
			</tr>
		));
	}

	return (
		<div>
			<Container>
				<div className={ft.content__head}>
					<h5 className={ft.title}>{Translate(lang, 'structure.orgStructure')}</h5>
					<div className={ft.btns}>
						<div className={`${ft.form} d-flex align-items-center`}>
							<Form.Label className='mb-0 position-relative'>
								<Form.Control
									type='text'
									placeholder={Translate(lang, 'global.placeholder')}
									required
									value={inputValue}
									ref={inputRef}
									style={{ paddingRight: '40px' }}
									onChange={(e) => inputValueHandler(e)}
								/>
								<button type='submit' className={st.search_icon}>
									{search}
								</button>
							</Form.Label>
							<button type='button' className={ft.add__btn} onClick={() => setShow(!show)}>
								<span className={ft.icon}>+</span>
								{Translate(lang, 'global.add')}
							</button>
						</div>
					</div>
				</div>

				<div className={ft.table}>
					<table className={ft.table__main}>
						<thead>
							<tr>
								<td>#</td>
								<td>{Translate(lang, 'global.orgName')}</td>
								<td>{Translate(lang, 'global.orgLeader')}</td>
								<td>{Translate(lang, 'global.address')}</td>
								<td>{Translate(lang, 'global.phone')}</td>
								<td>{Translate(lang, 'global.pochta')}</td>
								<td className='text-center'>{Translate(lang, 'global.amal')} </td>
							</tr>
						</thead>

						<tbody>
							{loading ? (
								<Loading big size='md' cl='mt-2' />
							) : filteredData?.length ? (
								tableData
							) : (
								<h6></h6>
							)}
						</tbody>
					</table>
					{!loading && !filteredData?.length && (
						<h6 className={ft.noData}> {Translate(lang, 'global.noInfo')}</h6>
					)}
				</div>
			</Container>

			<StructureAdd lang={lang} show={show} setLoading={setLoading} setData={setData} setShow={setShow} />

			<StructureEdit
				lang={lang}
				id={id}
				setId={setId}
				editData={editData}
				show={showEdit}
				setShow={setShowEdit}
				setLoading={setLoading}
				setData={setData}
			/>

			<Del
				lang={lang}
				id={id}
				setId={setId}
				load={load}
				show={showDel}
				setShow={setShowDel}
				handleDelete={deleteHandler}
			/>
		</div>
	);
};

export default Structure;
