import { useState } from 'react';
import { Form } from 'react-bootstrap';

import { passportApi } from '../../../services/passportService';

import { TextValidate } from '../../../utils';

import Translate from '../../../components/lang';
import Loading from '../../../components/loading';
import CustomModal from '../../../components/customModal';

import ft from '../../../components/mainStyles.module.scss';

const PassportEdit = (props) => {
	const { show, setShow, data, setData, setLoading, lang } = props,
		[load, setLoad] = useState(false),
		[numErr, setNumErr] = useState(false);

	const submitHandler = (e) => {
		e.preventDefault();

		const val = e.target[7].value.match(/^(\+)([998]{3})([\d]{2})(\d+)/);
		if (val && val[4].length === 7) {
			setLoad(true);
			setNumErr(false);
			const data = {
				name: {
					uz: e.target[0].value,
					ru: e.target[1].value,
				},
				leader: e.target[2].value,

				shortName: {
					uz: e.target[3].value,
					ru: e.target[4].value,
				},
				legalAddress: e.target[5].value,
				organizationType: e.target[6].value,
				phoneNumber: e.target[7].value,
				fax: e.target[8].value,
				postIndex: e.target[9].value,
				email: e.target[10].value,
				webSite: e.target[11].value,
			};
			passportApi
				.editProfile(data, {
					headers: {
						Authorization: `Bearer ${sessionStorage.getItem('token')}`,
					},
				})
				.then((res) => {
					if (res.data.success) {
						setLoad(false);
						setLoading(true);
						passportApi
							.profile({
								headers: {
									Authorization: `Bearer ${sessionStorage.getItem('token')}`,
								},
							})
							.then((res) => {
								setData(res.data.data);
								setLoading(false);
							});
						setShow(false);
					}
				})
				.catch((e) => {
					setLoading(false);
				});
			setLoading(false);
		} else {
			setNumErr(true);
		}
	};

	return (
		<CustomModal show={show} setShow={setShow} title={Translate(lang, 'passport.modalTitle1')} size={'lg'}>
			<div>
				<form action='' className='row' onSubmit={submitHandler}>
					<div className={`col-6 ${ft.form}`}>
						<Form.Label className='w-100'>
							<div className={ft.lab}>{Translate(lang, 'global.orgName')} (Uzb)</div>
							<Form.Control
								type='text'
								placeholder=''
								required
								defaultValue={data?.name?.uz}
								onKeyUp={(e) => TextValidate(e.target)}
								maxLength='150'
								disabled
							/>
						</Form.Label>
						<Form.Label className='w-100'>
							<div className={ft.lab}>{Translate(lang, 'global.orgName')} (Рус)</div>
							<Form.Control
								type='text'
								placeholder=''
								required
								defaultValue={data?.name?.ru}
								onKeyUp={(e) => TextValidate(e.target)}
								maxLength='150'
								disabled
							/>
						</Form.Label>
						<Form.Label className='w-100'>
							<div className={ft.lab}>{Translate(lang, 'global.leader')}</div>
							<Form.Control
								type='text'
								placeholder=''
								required
								defaultValue={data?.leader}
								onKeyUp={(e) => TextValidate(e.target)}
								maxLength='30'
							/>
						</Form.Label>

						<Form.Label style={{ width: '50%', marginRight: '5%' }}>
							<div className={ft.lab}>{Translate(lang, 'passport.shortName')} (Uzb)</div>
							<Form.Control
								type='text'
								placeholder=''
								defaultValue={data?.shortName?.uz}
								onKeyUp={(e) => TextValidate(e.target)}
							/>
						</Form.Label>
						<Form.Label style={{ width: '45%' }}>
							<div className={ft.lab}>{Translate(lang, 'passport.shortName')} (Рус)</div>
							<Form.Control
								type='text'
								placeholder=''
								defaultValue={data?.shortName?.ru}
								onKeyUp={(e) => TextValidate(e.target)}
							/>
						</Form.Label>

						<Form.Label className='w-100'>
							<div className={ft.lab}>{Translate(lang, 'global.address')}</div>
							<Form.Control type='text' placeholder='' defaultValue={data?.legalAddress} maxLength='50' />
						</Form.Label>

						<Form.Label className='w-100'>
							<div className={ft.lab}>{Translate(lang, 'passport.orgType')}</div>
							<Form.Control as='select' className='mb-2' defaultValue={data?.organizationType?._id} disabled>
								<option value={data?.organizationType?._id}>
									{data?.organizationType?.name ? data?.organizationType?.name[lang] : null}
								</option>
							</Form.Control>
						</Form.Label>
					</div>

					<div className={`col-6 ${ft.form}`}>
						<Form.Label className='w-100'>
							<div className={ft.lab}>{Translate(lang, 'global.phone')} </div>
							<Form.Control
								type='text'
								placeholder=''
								required
								defaultValue={`${data?.phoneNumber}`}
								style={
									numErr
										? {
												backgroundColor: 'rgba(255, 82, 82, 0.1)',
										  }
										: {}
								}
							/>
						</Form.Label>
						{numErr ? <span className={ft.err__mes}> {Translate(lang, 'global.phoneError')}</span> : ''}
						<Form.Label className='w-100'>
							<div className={ft.lab}> {Translate(lang, 'passport.faks')}</div>
							<Form.Control type='number' placeholder='' defaultValue={data?.fax} />
						</Form.Label>
						<Form.Label className='w-100'>
							<div className={ft.lab}> {Translate(lang, 'passport.pIndex')}</div>
							<Form.Control type='number' placeholder='' maxLength='6' defaultValue={data?.postIndex} />
						</Form.Label>
						<Form.Label className='w-100'>
							<div className={ft.lab}> {Translate(lang, 'passport.email')}</div>
							<Form.Control
								type='email'
								placeholder=''
								required
								defaultValue={data?.email}
								maxLength='20'
							/>
						</Form.Label>
						<Form.Label className='w-100'>
							<div className={ft.lab}> {Translate(lang, 'passport.site')}</div>
							<Form.Control type='text' placeholder='' defaultValue={data?.webSite} maxLength='15' />
						</Form.Label>
					</div>
					<div className='col-12 d-flex justify-content-end my-3'>
						<button type='button' className={ft.close__btn} onClick={() => setShow(false)}>
							{Translate(lang, 'global.cancel')}
						</button>
						<button className={`${ft.save__btn} ml-3`} type='submit'>
							{Translate(lang, 'global.save')} {load ? <Loading size='sm' cl='ml-1' /> : ''}
						</button>
					</div>
				</form>
			</div>
		</CustomModal>
	);
};

export default PassportEdit;
