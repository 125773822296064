import Container from "../../../components/container";
import Circle from "../../../components/circle";
import { userIcon } from "../../../components/icons";
const Comments = (props) => {
    const {num, position, expert} = props;

   
    return (
      <div className="row mt-3">
        <div className="col-7">
          <Container>
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <Circle num={userIcon} col={"7, 162, 135"} size={50} />
                <div className="pl-3">
                  <h6>{expert}</h6>
                  <span
                    style={{
                      color: "#B0B7CE",
                      fontSize: "14px",
                      wordWrap: "break-word",
                    }}
                  >
                    {position}
                  </span>
                </div>
              </div>
              <Circle
                num={num}
                col={"255, 166, 62"}
                size={50}
                fontSize={"24px"}
              />
            </div>
            <p className="mt-4" style={{wordWrap: "break-word"}}>{props.children}</p>
          </Container>
        </div>
      </div>
    );
}

export default Comments;