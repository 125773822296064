import React, { useEffect, useState } from 'react';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';
import { io } from 'socket.io-client';
import './index.scss';

import config from './config';

import { authApi } from './services/authService';
import { homeApi } from './services/homeService';
import { orgApi } from './services/orgListService';
import { docStatisticApi } from './services/doc/docStatisticService';

import Home from './pages/home';
import Login from './pages/login';
import Infos from './pages/infos';
import Tasks from './pages/tasks';
import Advices from './pages/advices';
import Reyting from './pages/reyting';
import Passport from './pages/passport';
import Structure from './pages/structure';
import Suggestion from './pages/suggestion';
import LegalStatus from './pages/legalStatus';
import PropagandaDay from './pages/propagandaDay';
import PasswordEdit from './pages/home/passwordEdit';

import * as Doc from './pages/doc';

import Navbar from './components/navbar';
import Header from './components/header';
import Loading from './components/loading';

function App() {
	const history = useHistory();
	const [lang, setLang] = useState('uz');
	const	[isOpen, setIsOpen] = useState(true);
	const	[user, setUser] = useState(null);
	const	[token, setToken] = useState(null);
	const	[loading, setLoading] = useState(true);
	const	[auth, setAuth] = useState(false);
	const	[periods, setPeriods] = useState([]);
	const	[selPer, setSelPer] = useState(0);
	const	[markingStarted, setMarkingStarted] = useState([]);
	const	[rank, setRank] = useState(null);
	const [badge, setBadge] = useState({
		newReceivedsCount: 0,
		newTasksCount: 0
	});

	const allProps = {
		lang: lang,
		setLang: setLang,
		isOpen: isOpen,
		setIsOpen: setIsOpen,
		token: token,
		setToken: setToken,
		user: user,
		setUser: setUser,
		setAuth: setAuth,
		setGlobalLoading: setLoading,
		periods: periods,
		selPer: selPer,
		setSelPer: setSelPer,
		markingStarted: markingStarted,
		badge: badge,
		rank: rank,
	};

	let socket;

	const getBadge = () => {
		docStatisticApi.getBadge()
			.then(res => {
				setBadge(res.data.data);
			})
			.catch(err => {
				console.log(err);
			});
	}

	useEffect(() => {
		socket = io(config.api.baseUrl);
		socket?.emit('login', { userId: 1 });
	}, [socket]);

	useEffect(() => {
		setAuth();
		setLoading(true);

		let temp = sessionStorage.getItem('token');

		if (sessionStorage.getItem('lang')) {
			setLang(lang);
		}

		if (!!temp) {
			authApi
				.userMe({
					headers: {
						Authorization: `Bearer ${temp}`,
					},
				})
				.then((res) => {
					if (res.data.success) {
						const us = res.data.data;
						const time = res.data.time;
						setUser(us);
						setMarkingStarted(time);
						homeApi
							.period()
							.then((res) => {
								const pers = res.data.data;
								setPeriods(res.data.data);
								orgApi
									.rank({
										start: pers[selPer]?.start,
										end: pers[selPer]?.end,
										period:
											pers?.length === 1
												? pers[0]?._id
												: time?.status === 1
												? pers[1]?._id
												: pers[selPer]?._id,
										id: us?._id,
									})
									.then((res) => {
										setRank(res.data.rank);
										setAuth(true);
										setLoading(false);
									});
							})
							.catch((err) => {
								console.log(err.message);
							});
					} else {
						setAuth(false);
						sessionStorage.clear();
						setLoading(false);
					}
				});
		} else {
			setLoading(false);
			sessionStorage.clear();
			history.push('/login');
			setAuth(false);
		}
	}, [token, selPer]);

	useEffect(() => {
		getBadge();
	}, []);

	if (loading) {
		return (
			<div className='w-100 d-flex align-items-center justify-content-center' style={{ height: '100vh' }}>
				<Loading big={true} size='lg' />
			</div>
		);
	}

	return (
		<div className='position-relative'>
			{!auth && !sessionStorage.getItem('token') ? (
				<Switch>
					<Route>
						<Route path='/login'>
							<Login {...allProps} />
						</Route>

						<Route render={() => <Redirect to='/login' />} />
					</Route>
				</Switch>
			) : (
				<div style={{ minHeight: '100vh' }} className='bg-light'>
					<Navbar {...allProps} />

					<div className={`bg-light position-relative float-right isOpen ${isOpen ? 'isOpen__active' : ''}`}>
						<div style={{ padding: '90px 20px 0 30px' }}>
							<Header {...allProps} />

							<Switch>
								<Route
									path='/doc/for-execution'
									exact
									render={() => (
										<Doc.ForExecution
											{...allProps}
											getBadge={getBadge}
										/>
									)}
								/>
								<Route
									path='/doc/for-information'
									exact
									render={() => (
										<Doc.ForInformation
											{...allProps}
											getBadge={getBadge}
										/>
									)}
								/>
								<Route
									path='/passport'
									exact
									render={() => <Passport {...allProps} />}
								/>
								<Route
									path='/structure'
									exact
									render={() => <Structure {...allProps} />}
								/>
								<Route
									path='/propaganda-day'
									exact
									render={() => <PropagandaDay {...allProps} />}
								/>
								<Route
									path='/suggestion'
									exact
									render={() => <Suggestion {...allProps} />}
								/>
								<Route
									path='/legal-status'
									render={() => <LegalStatus {...allProps} />}
								/>
								<Route
									path='/rating'
									exact
									render={() => <Reyting {...allProps} />}
								/>
								<Route
									path='/tasks'
									exact
									render={() => <Tasks {...allProps} />}
								/>
								<Route
									path='/advices'
									exact
									render={() => <Advices {...allProps} />}
								/>
								<Route
									path='/infos'
									exact
									render={() => <Infos {...allProps} />}
								/>
								<Route
									path='/user-set'
									render={() => <PasswordEdit {...allProps} />}
								/>
								<Route
									path='/'
									exact
									render={() => <Home {...allProps} />}
								/>
								<Redirect to='/' />
							</Switch>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}

export default App;
