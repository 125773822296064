import st from "../../home.module.scss";

const CardItem = (props) => {
  const { itemInfo } = props;
  return (
    <div>
      <div className={st.info__numbers}>{itemInfo.number}</div>
      <p className="text-center" style={{ wordBreak: "break-word" }}>
        {itemInfo.label}
      </p>
    </div>
  );
};

export default CardItem;
