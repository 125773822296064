import Translate from "../lang";
import Loading from "../loading";
import CustomModal from "../customModal";

import ft from "../mainStyles.module.scss";

const Del = ({
  id,
  setId,
  lang,
  show,
  load,
  setShow,
  handleDelete,
}) => (
   <CustomModal
      show={show}
      setShow={setShow}
      del={true}
      size='md'
   >
      <div
         className="position-relative w-100"
         style={{
            backgroundColor: "#fff",
            borderRadius: 10,
         }}
      >
         <h6 style={{ fontSize: "18px" }}>
            {Translate(lang, "global.sure")}
         </h6>

         <div className="d-flex align-items-center justify-content-between w-100">
            <button
               className={`${ft.close__btn} mr-3`}
               onClick={() => {
                  setShow(false);
                  setId(null);
               }}
            >
               {Translate(lang, "global.cancel")}
            </button>

            <button
               className={ft.delete__btn}
               onClick={() => handleDelete(id)}
            >
               {Translate(lang, "global.delete")}
               {load ? <Loading cl="ml-1" size="sm" /> : ""}
            </button>
         </div>
      </div>
   </CustomModal>
);

export default Del;
