import React, { useEffect, useState } from 'react';

import config from '../../../../config';

import { docReceivedApi } from '../../../../services/doc/docReceivedService';

import Translate from '../../../../components/lang';
import Loading from '../../../../components/loading';

import { BeautyFullTime } from '../../../../utils';

import ft from '../../../../components/mainStyles.module.scss';

const View = ({ lang, docReceived, onUpdateStatus }) => {
   const [data, setData] = useState({});
   const [loading, setLoading] = useState(true);

   useEffect(() => {
      setLoading(true);

     docReceivedApi
        .getById(docReceived?._id)
        .then(res => {
           if (res.data.success) {
              setData(res.data.data);
              onUpdateStatus && onUpdateStatus();
           }
        })
        .finally(() => {
           setLoading(false);
        });
   }, []);

   return (
      <div className={ft.table}>

         {!loading && (
            <h6 className={ft.noData}>
               {Translate(lang, 'table.notFound')}
            </h6>
         )}
      </div>
   )
}

export default View;