import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { APIFile } from '../../services/api';

import Translate from '../lang';
import { chevron } from '../icons';

import notfound from '../../images/notfound.png';

import st from './navbar.module.scss';

const home = (
		<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M3 9L12 2L21 9V20C21 20.5304 20.7893 21.0391 20.4142 21.4142C20.0391 21.7893 19.5304 22 19 22H5C4.46957 22 3.96086 21.7893 3.58579 21.4142C3.21071 21.0391 3 20.5304 3 20V9Z'
				stroke='white'
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path d='M9 22V12H15V22' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
		</svg>
	),
	passport = (
		<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M2 3H8C9.06087 3 10.0783 3.42143 10.8284 4.17157C11.5786 4.92172 12 5.93913 12 7V21C12 20.2044 11.6839 19.4413 11.1213 18.8787C10.5587 18.3161 9.79565 18 9 18H2V3Z'
				stroke='white'
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M22 3H16C14.9391 3 13.9217 3.42143 13.1716 4.17157C12.4214 4.92172 12 5.93913 12 7V21C12 20.2044 12.3161 19.4413 12.8787 18.8787C13.4413 18.3161 14.2044 18 15 18H22V3Z'
				stroke='white'
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	),
	structure = (
		<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M18 21C19.6569 21 21 19.6569 21 18C21 16.3431 19.6569 15 18 15C16.3431 15 15 16.3431 15 18C15 19.6569 16.3431 21 18 21Z'
				stroke='white'
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M6 9C7.65685 9 9 7.65685 9 6C9 4.34315 7.65685 3 6 3C4.34315 3 3 4.34315 3 6C3 7.65685 4.34315 9 6 9Z'
				stroke='white'
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M13 6H16C16.5304 6 17.0391 6.21071 17.4142 6.58579C17.7893 6.96086 18 7.46957 18 8V15'
				stroke='white'
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path d='M6 9V21' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
		</svg>
	),
	legalStatus = (
		<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M4 19.5C4 18.837 4.26339 18.2011 4.73223 17.7322C5.20107 17.2634 5.83696 17 6.5 17H20'
				stroke='white'
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M6.5 2H20V22H6.5C5.83696 22 5.20107 21.7366 4.73223 21.2678C4.26339 20.7989 4 20.163 4 19.5V4.5C4 3.83696 4.26339 3.20107 4.73223 2.73223C5.20107 2.26339 5.83696 2 6.5 2V2Z'
				stroke='white'
				strokeWidth='2'
				strokeLinejoin='round'
				strokeLinecap='round'
			/>
		</svg>
	),
	rating = (
		<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path d='M18 20V10' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
			<path d='M12 20V4' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
			<path d='M6 20V14' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
		</svg>
	),
	tasks = (
		<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M16 4H18C18.5304 4 19.0391 4.21071 19.4142 4.58579C19.7893 4.96086 20 5.46957 20 6V20C20 20.5304 19.7893 21.0391 19.4142 21.4142C19.0391 21.7893 18.5304 22 18 22H6C5.46957 22 4.96086 21.7893 4.58579 21.4142C4.21071 21.0391 4 20.5304 4 20V6C4 5.46957 4.21071 4.96086 4.58579 4.58579C4.96086 4.21071 5.46957 4 6 4H8'
				stroke='white'
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M15 2H9C8.44772 2 8 2.44772 8 3V5C8 5.55228 8.44772 6 9 6H15C15.5523 6 16 5.55228 16 5V3C16 2.44772 15.5523 2 15 2Z'
				stroke='white'
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	),
	advices = (
		<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M14 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V8L14 2Z'
				stroke='white'
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path d='M14 2V8H20' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
			<path d='M16 13H8' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
			<path d='M16 17H8' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
			<path d='M10 9H9H8' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
		</svg>
	),
	infos = (
		<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z'
				stroke='white'
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M9.08984 8.99999C9.32495 8.33166 9.789 7.7681 10.3998 7.40912C11.0106 7.05015 11.7287 6.91893 12.427 7.0387C13.1253 7.15848 13.7587 7.52151 14.2149 8.06352C14.6712 8.60552 14.9209 9.29151 14.9198 9.99999C14.9198 12 11.9198 13 11.9198 13'
				stroke='white'
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path d='M12 17H12.01' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
		</svg>
	);

const Navbar = ({ user, lang, badge, isOpen, setIsOpen }) => {
	const history = useHistory();
	const [logo, setLogo] = useState();
	const [link, setLink] = useState('/');
	const [notimg, setNotImg] = useState(false);

	useEffect(() => {
		setLink(history.location.pathname);
	}, []);

	useEffect(() => {
		setLogo(user?.image);
		user?.image ? setNotImg(true) : setNotImg(false);
	}, [user]);

	const toggler = () => {
		const navbar = document.querySelector('#navbar');
		const navbarTog = document.querySelector('#navbarToggler');
		if (navbar.style.width === '300px' || navbar.style.width === '') {
			setIsOpen(false);
			let temp = 300,
				left = 282;
			const g = setInterval(() => {
				temp -= 5;
				left -= 5;
				if (temp <= 95) clearInterval(g);
				navbar.style = `width: ${temp}px`;
				navbarTog.style = `left: ${left}px`;
			}, 5);
		} else {
			setIsOpen(true);
			let temp = 95,
				left = 77;
			const g = setInterval(() => {
				temp += 5;
				left += 5;
				if (temp >= 300) clearInterval(g);
				navbar.style = `width: ${temp}px`;
				navbarTog.style = `left: ${left}px`;
			}, 5);
		}
	};

	const toggleLink = (e) => {
		setLink(e);
	};

	return (
		<div className={st.navbar}>
			<div className={st.navbar__main} id='navbar'>
				<div className={st.nav}>
					<div className={st.navbar__head}>
						<div className={st.navbar__logo}>
							{notimg ? (
								<img src={`${APIFile}${logo}`}  alt='Logo' />
							) : (
								<img style={{ borderRadius: '50%' }} src={`${notfound}`}  alt='Not found' />
							)}
						</div>
						<h5
							className={`mt-2 text-white ${isOpen ? '' : 'd-none'}`}
							style={
								user?.name.oz.length > 150
									? {
											fontWeight: 500,
											lineHeight: '27px',
											height: '200px',
											fontSize: '16px',
									  }
									: {
											fontWeight: 500,
											lineHeight: '27px',
											height: '200px',
									  }
							}>
							{user?.name.oz.length < 60
								? user?.name && user?.name[lang]
								: user?.name && user?.name[lang].slice(0, 60) + '...'}
						</h5>
					</div>

					<ul
						className={st.links}
						style={
							user?.name?.oz?.split(' ').length > 10
								? isOpen
									? {
											top: '250px',
									  }
									: {
											width: '60%',
											left: 0,
											right: 0,
											margin: '0 auto',
									  }
								: isOpen
								? {}
								: {
										width: '60%',
										left: 0,
										right: 0,
										margin: '0 auto',
								  }
						}>
						<h6 className={st.links__title} style={isOpen ? { display: 'block' } : { display: 'none' }}>
							{Translate(lang, 'navbar.fullInfo')}
						</h6>

						<Link
							className={`${st.link} ${link === '/' ? st.link__active : ''}`}
							style={isOpen ? {} : { justifyContent: 'center' }}
							onClick={() => toggleLink('/')}
							to='/'
						>
							<li>
								<span>{home}</span>
								<span className={`ml-3 ${isOpen ? '' : 'd-none'}`} style={{ lineHeight: '14px' }}>
									{Translate(lang, 'navbar.home')}
								</span>
								{isOpen ? '' : <span className={st.tooltip}>{Translate(lang, 'navbar.home')}</span>}
							</li>
						</Link>

						<Link
							className={`${st.link} ${link === '/passport' ? st.link__active : ''}`}
							style={isOpen ? {} : { justifyContent: 'center' }}
							onClick={() => toggleLink('/passport')}
							to='/passport'
						>
							<li>
								<span>{passport}</span>
								<span className={`ml-3 ${isOpen ? '' : 'd-none'}`} style={{ lineHeight: '14px' }}>
									{Translate(lang, 'navbar.passport')}
								</span>
								{isOpen ? '' : <span className={st.tooltip}>{Translate(lang, 'navbar.passport')}</span>}
							</li>
						</Link>

						<Link
							className={`${st.link} ${link === '/structure' ? st.link__active : ''}`}
							style={isOpen ? {} : { justifyContent: 'center' }}
							onClick={() => toggleLink('/structure')}
							to='/structure'
						>
							<li>
								<span>{structure}</span>
								<span className={`ml-3 ${isOpen ? '' : 'd-none'}`}>
									{Translate(lang, 'navbar.structure')}
								</span>
								{isOpen ? '' : <span className={st.tooltip}>{Translate(lang, 'navbar.structure')}</span>}
							</li>
						</Link>

						<Link
							className={`${st.link} ${link === '/propaganda-day' ? st.link__active : ''}`}
							style={isOpen ? {} : { justifyContent: 'center' }}
							onClick={() => toggleLink('/propaganda-day')}
							to='/propaganda-day'
						>
							<li>
								<span>{structure}</span>
								<span className={`ml-3 ${isOpen ? '' : 'd-none'}`}>
									{Translate(lang, 'navbar.propagandaDay')}
								</span>
								{isOpen ? '' : <span className={st.tooltip}>{Translate(lang, 'navbar.propagandaDay')}</span>}
							</li>
						</Link>

						<Link
							className={`${st.link} ${link === '/suggestion' ? st.link__active : ''}`}
							style={isOpen ? {} : { justifyContent: 'center' }}
							onClick={() => toggleLink('/suggestion')}
							to='/suggestion'
						>
							<li>
								<span>{structure}</span>
								<span className={`ml-3 ${isOpen ? '' : 'd-none'}`}>
									{Translate(lang, 'navbar.suggestion')}
								</span>
								{isOpen ? '' : <span className={st.tooltip}>{Translate(lang, 'navbar.suggestion')}</span>}
							</li>
						</Link>

						<Link
							className={`${st.link} ${link === '/doc/for-execution' ? st.link__active : ''}`}
							style={isOpen ? {} : { justifyContent: 'center' }}
							onClick={() => toggleLink('/doc/for-execution')}
							to='/doc/for-execution'
						>
							<li>
								<span>{structure}</span>
								<span className={`ml-3 ${isOpen ? '' : 'd-none'}`}>
									{Translate(lang, 'navbar.forExecution')}
									<span className={st.badge}>{badge.newTasksCount}</span>
								</span>
								{isOpen ? '' : (
									<span className={st.tooltip}>
										{Translate(lang, 'navbar.forExecution')}
										<span className={st.badge}>{badge.newTasksCount}</span>
									</span>
								)}
							</li>
						</Link>

						<Link
							className={`${st.link} ${link === '/doc/for-information' ? st.link__active : ''}`}
							style={isOpen ? {} : { justifyContent: 'center' }}
							onClick={() => toggleLink('/doc/for-information')}
							to='/doc/for-information'
						>
							<li>
								<span>{structure}</span>
								<span className={`ml-3 ${isOpen ? '' : 'd-none'}`}>
									{Translate(lang, 'navbar.forInformation')}
									<span className={st.badge}>{badge.newReceivedsCount}</span>
								</span>
								{isOpen ? '' : (
									<span className={st.tooltip}>
										{Translate(lang, 'navbar.forInformation')}
										<span className={st.badge}>{badge.newReceivedsCount}</span>
									</span>
								)}
							</li>
						</Link>

						<h6 className={st.links__title} style={isOpen ? { display: 'block' } : { display: 'none' }}>
							{Translate(lang, 'navbar.monitoring')}
						</h6>

						<Link
							className={`${st.link} ${link === '/legal-status' ? st.link__active : ''}`}
							style={isOpen ? {} : { justifyContent: 'center' }}
							onClick={() => toggleLink('/legal-status')}
							to='/legal-status'
						>
							<li>
								<span>{legalStatus}</span>
								<span className={`ml-3 ${isOpen ? '' : 'd-none'}`}>
									{Translate(lang, 'navbar.legalStatus')}
								</span>
								{isOpen ? '' : <span className={st.tooltip}>{Translate(lang, 'navbar.legalStatus')}</span>}
							</li>
						</Link>

						<Link
							to='/rating'
							className={`${st.link} ${link === '/rating' ? st.link__active : ''}`}
							style={isOpen ? {} : { justifyContent: 'center' }}
							onClick={() => toggleLink('/rating')}>
							<li>
								<span>{rating}</span>
								<span className={`ml-3 ${isOpen ? '' : 'd-none'}`} style={{ lineHeight: '14px' }}>
									{Translate(lang, 'global.rating')}
								</span>
								{isOpen ? '' : <span className={st.tooltip}>{Translate(lang, 'global.rating')}</span>}
							</li>
						</Link>

						<Link
							className={`${st.link} ${link === '/tasks' ? st.link__active : ''}`}
							style={isOpen ? {} : { justifyContent: 'center' }}
							onClick={() => toggleLink('/tasks')}
							to='/tasks'
						>
							<li>
								<span>{tasks}</span>
								<span className={`ml-3 ${isOpen ? '' : 'd-none'}`} style={{ lineHeight: '14px' }}>
									{Translate(lang, 'navbar.tasks')}
								</span>
								{isOpen ? '' : <span className={st.tooltip}>{Translate(lang, 'navbar.tasks')}</span>}
							</li>
						</Link>

						<Link
							className={`${st.link} ${link === '/advices' ? st.link__active : ''}`}
							style={isOpen ? {} : { justifyContent: 'center' }}
							onClick={() => toggleLink('/advices')}
							to='/advices'
						>
							<li>
								<span>{advices}</span>
								<span className={`ml-3 ${isOpen ? '' : 'd-none'}`} style={{ lineHeight: '14px' }}>
									{Translate(lang, 'navbar.advices')}
								</span>
								{isOpen ? '' : <span className={st.tooltip}>{Translate(lang, 'navbar.advices')}</span>}
							</li>
						</Link>

						<h6 className={st.links__title} style={isOpen ? { display: 'block' } : { display: 'none' }}>
							{Translate(lang, 'navbar.additonalInfo')}
						</h6>

						<Link
							className={`${st.link} ${link === '/infos' ? st.link__active : ''}`}
							style={isOpen ? {} : { justifyContent: 'center' }}
							onClick={() => toggleLink('/infos')}
							to='/infos'
						>
							<li>
								<span>{infos}</span>
								<span className={`ml-3 ${isOpen ? '' : 'd-none'}`} style={{ lineHeight: '14px' }}>
									{Translate(lang, 'navbar.instruction')}
								</span>
								{isOpen ? '' : <span className={st.tooltip}>{Translate(lang, 'navbar.instruction')}</span>}
							</li>
						</Link>
					</ul>

					<div id='navbarToggler' className={st.navbar__toggler} onClick={toggler}>
						{chevron(toggler, st.navbar__toggler__icon, st.navbar__toggler__icon__act, isOpen, '07A287')}
					</div>
				</div>
			</div>
		</div>
	);
}

export default Navbar;
