import axiosInstance from './api';

const api = '/organizations/profile'
const edit = '/organizations/update'

export const passportApi = {
    profile: (headers) => {
        return axiosInstance.get(`${api}`, headers)
    },
    editProfile: (data, headers) => {
        return axiosInstance.put(`${edit}`, data, headers)
    }
}
