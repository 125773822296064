import ft from '../../components/mainStyles.module.scss';
import { useEffect, useState } from 'react';
import Container from '../../components/container';
import { userIcon2, phone, svg } from '../../components/icons';
import Translate from '../../components/lang';
import { infoApi } from '../../services/infoService';
import Loading from '../../components/loading';
import { APIFile } from '../../services/api';
import ReactPlayer from 'react-player';

export default function Infos(props) {
	const [show, setShow] = useState(false);
	const { lang } = props;
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState('');
	const [data2, setData2] = useState('');
	const [page, setPage] = useState({ limit: 10, page: 1 });
	const [page2, setPage2] = useState({ limit: 10, page: 1 });
	const clickHandler = () => {
		setShow(!show);
	};

	useEffect(() => {
		setLoading(true);
		infoApi
			.all(page)
			.then((res) => {
				setData(res.data.data);
				infoApi
					.experts(page2)
					.then((res) => {
						setData2(res.data.data);
						setLoading(false);
					})
					.catch((e) => {
						setLoading(false);
					});
			})
			.catch((e) => {
				setLoading(false);
			});
	}, []);

	if (loading)
		return (
			<div
				className='w-100 d-flex align-items-center justify-content-center'
				style={{ height: 'calc(100vh - 90px)' }}>
				<Loading size='lg' big={true} />
			</div>
		);
	return (
		<div>
			<div className='row'>
				<div className='col-md-6'>
					<Container>
						<div className={ft.content__head}>
							<h5 className={ft.title}>{Translate(lang, 'global.experts')}</h5>
						</div>
						<div className={ft.table}>
							<table className={`${ft.table__main}`} style={{ fontSize: '13px' }}>
								{!loading ? (
									data2?.map((d) => (
										<tbody style={{ fontSize: '13px' }}>
											<tr>
												<td>{userIcon2}</td>
												<td>{d.fullName ? d.fullName : '...'}</td>
												<td>{phone}</td>
												<td>
													<a
														target='_blank'
														style={{
															color: 'rgba(35, 35, 35, 1)',
														}}
														href={`tel:+${d.phone}`}
														rel='noreferrer'>
														{' '}
														+{d.phone}
													</a>
												</td>
												<td>{phone}</td>
												<td>
													{' '}
													<a
														target='_blank'
														style={{
															color: 'rgba(35, 35, 35, 1)',
														}}
														href={`tel:+${d.additionalPhone}`}>
														{' '}
														{d.additionalPhone}
													</a>
												</td>
											</tr>
										</tbody>
									))
								) : (
									<Loading big={true} size='lg' cl='mt-3' />
								)}
							</table>
						</div>
					</Container>
				</div>
				<div className='col-md-6'>
					<Container>
						<div className={ft.content__head}>
							<h5 className={ft.title}>{Translate(lang, 'navbar.instruction')}</h5>
						</div>
						{data?.map((d) => {
							return d.files.map((i) => (
								<button
									style={{
										color: '#29A0E3',
										background: 'none',
										fontSize: '14px',
										backgroundColor: 'rgba(196, 196, 196, 0.15)',
										padding: '15px',
										borderRadius: '8px',
										marginTop: '15px',
										width: '100%',
									}}
									className={ft.export__btn}>
									<a
										target='_blank'
										style={{
											display: 'flex',
											justifyContent: 'space-between',
											width: '100%',
										}}
										href={`${APIFile}${i.path}`}>
										<span> {i.path.slice(15)}</span>
										<span style={{}}>{svg}</span>
									</a>
								</button>
							));
						})}
					</Container>
				</div>
			</div>
			<div className='mt-4'>
				<Container>
					<div className={ft.content__head}>
						<h5 className={ft.title}>{Translate(lang, 'infos.videInfo')}</h5>
					</div>
					<div
						className='row mt-4 d-flex'
						style={{
							justifyContent: 'space-around',
							cursor: 'pointer',
						}}>
						{data?.map((d) =>
							d.link ? (
								<ReactPlayer
									width='550'
									height='350'
									url={d.link}
									style={{
										marginBottom: '3vh',
										margin: '1vw',
										borderRadius: '8px',
									}}
								/>
							) : (
								''
							)
						)}
					</div>
				</Container>
			</div>
		</div>
	);
}
