import axiosInstance from "./api";

const api = "/handbooks/";
const api2 = "/experts";
// http://api.tujjor.org/api/experts/all?limit=10&page=1
export const infoApi = {
  all: (d) => {
    return axiosInstance.get(
      `${api}/all-client?limit=${d.limit}&page=${d.page}`,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    );
  },
  experts: (d) => {
    return axiosInstance.get(
      `${api2}/all-client?limit=${d.limit}&page=${d.page}`,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    );
  },
};
