import axiosInstance from "./api";

const api = "/assignments/get-sum";
// const api2 = "/organizations/final-result-client";

export const homeApi = {
    all: (data) => {
        return axiosInstance.post(`${api}`, data, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
        });
    },
    reyting: (data) => {
        return axiosInstance.post('/ratings/all', data, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
        });
    },
    period: () => {
        return axiosInstance.get(`/evaluation-time/get-period`, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
        });
    },

};
