import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';

import { chevronDown, chevronUp, equal } from '../../components/icons';

import { homeApi } from '../../services/homeService';
import { orgApi } from '../../services/orgListService';
import { orgTypesApi } from "../../services/orgTypesService";

import { SortReyt } from '../../utils';

import EksportModal from './eksportModal';
import Limit from '../../components/limit';
import Filter from '../../components/filter';
import Circle from '../../components/circle';
import Translate from '../../components/lang';
import Loading from '../../components/loading';
import Pagin from '../../components/pagination';
import Container from '../../components/container';
import ExportPdf from '../../components/exportPdf';
import CustomSelect from '../../components/customSelect';

import ft from '../../components/mainStyles.module.scss';
import st from "../../components/header/header.module.scss";

export default function Reyting(props) {
	const { lang, periods, selPer, user } = props,
		[allData, setAllData] = useState([]),
		[options, setOptions] = useState([]),
		[selected, setSelected] = useState([]),
		[orgTypes, setOrgTypes] = useState([]),
		[page, setPage] = useState({ page: 1, limit: 10 }),
		[pageNum, setPageNum] = useState(1),
		[loading, setLoading] = useState(true),
		[showFilter, setShowFilter] = useState(false),
		[pc, setPc] = useState(0),
		[showEdit, setShowEdit] = useState(false),
		[filter, setFilter] = useState({}),
		[data, setData] = useState([]);

	function getList(payload) {
		homeApi
			.reyting(payload)
			.then(res => {
				if (res.data.success) {
					let DATA = [];
					const temp = [];

					if (res.data.data && res.data.data.length) {
						DATA = SortReyt(res.data.data, res.data.oldData);
					}

					DATA.forEach(d => {
						temp.push({
							id: d._id,
							org: d?.organization[0]?.name,
							type: d?.organizationType[0]?.name,
							total: d?.ball?.all ? d?.ball?.all : 0,
							left: d?.ball?.occasion ? d?.ball?.occasion : 0,
							right: d?.ball?.assignment ? d?.ball?.assignment : 0,
							res: d.res,
							placeIcon: d.placeIcon
						});
					});

					setAllData(temp);
					setData(temp.slice(page.page * page.limit - page.limit, page.page * page.limit));
					setPageNum(Math.ceil(temp.length / page.limit));
					setPc((page.page - 1) * page.limit);
				}
			})
			.finally(() => {
				setLoading(false);
			});
	}

	const handleFilter = (e) => {
		e.preventDefault();

		setLoading(true);

		const selId = [];

		selected.map(s => {
			selId.push(s.value);
		});

		const payload = {
			page: 1,
			limit: 10000,
			start: periods[e.target[2].value].start,
			end: periods[e.target[2].value].end,
			period: periods[e.target[2].value]?._id
		};

		if (selId.length) {
			payload.organization = selId;
		}

		if (e.target[1].value !== 'all') {
			payload.type = e.target[1].value;
		}

		setFilter(payload);
		setShowFilter(false);

		getList(payload);
	};

	useEffect(() => {
		const payload = {
			page: 1,
			limit: 10000,
			start: periods[selPer]?.start,
			end: periods[selPer]?.end,
			period: periods[selPer]?._id,
			...filter
		}

		getList(payload);
	}, [page]);

	useEffect(() => {
		orgApi.getAll()
			.then(res => {
				if (res.data.success) {
					const DATA = [];

					res.data.data.map((d) => {
						DATA.push({
							value: d._id,
							label: d.name.uz
						});
					});

					setOptions(DATA);
				}
			})
			.catch(e => {
				console.log(e);
			});

		orgTypesApi.allActive()
			.then(res => {
				setOrgTypes(res.data.data);
			})
			.catch(e => {
				console.log(e);
			});
	}, []);

	return (
		<div>
			<Container>
				<div className={ft.content__head}>
					<h5 className={`${ft.title} pl-3`}>{Translate(lang, 'global.rating')}</h5>

					<div className={ft.btns}>
						<button
							style={{ background: 'transparent' }}
							onClick={() => {
								setShowEdit(true);
							}}>
							<ExportPdf {...props} title={Translate(lang, 'global.export')} />
						</button>
						<div style={{ marginLeft: '-8px' }}>
							<Limit
								{...props}
								setPage={setPage}
								setLoading={setLoading}
								setFilter={setFilter}
							/>
						</div>
						<button className={`${ft.primary__btn} ml-2 mr-3`} onClick={() => setShowFilter(true)}>
							{Translate(lang, 'global.filter')}
						</button>
					</div>
				</div>

				<div className={ft.table}>
					<table className={`${ft.table__main}`} style={{ fontSize: '13px' }} id='table'>
						<thead>
							<tr>
								<td className='pl-3'>#</td>
								<td> {Translate(lang, 'rating.orgName')}</td>
								<td> {Translate(lang, 'rating.orgType')}</td>
								<td className='text-center'>{Translate(lang, 'rating.byEvents')}</td>
								<td className='text-center'>{Translate(lang, 'rating.byTask')}</td>
								<td className='text-center mr-3'>{Translate(lang, 'rating.totalScore')}</td>
							</tr>
						</thead>

						<tbody>
							{!loading ? (
								data.map((d, index) => (
									<tr style={user?._id === d?.id ? { backgroundColor: '#E7F6F3' } : {}}>
										<td className='pl-3'>{pc + index + 1}</td>
										<td>
											<div className='d-flex align-items-center'>
												{d.placeIcon === 'up' ? chevronUp : d.placeIcon === 'down' ? chevronDown : equal}
												{d.org ? d.org[lang] : null}
											</div>
										</td>
										<td>
											{d.type ? d.type[lang] : null}
										</td>
										<td>
											<div className='d-flex justify-content-center'>
												<Circle
													num={periods?.length === 1 ? 0 : Math.round(d?.left * 10) / 10}
													col='88, 85, 214'
													size='40px'
												/>
											</div>
										</td>
										<td>
											<div className='d-flex justify-content-center'>
												<Circle
													num={periods?.length === 1 ? 0 : Math.round(d?.right * 10) / 10}
													col='239, 93, 168'
													size='40px'
												/>
											</div>
										</td>
										<td className='mr-3'>
											<div className='d-flex justify-content-center'>
												<Circle
													num={periods?.length === 1 ? 0 : Math.round(d?.total * 10) / 10}
													col='7, 162, 135'
													size='40px'
												/>
											</div>
										</td>
									</tr>
								))
							) : (
								<Loading big={true} size='md' cl='mt-3' />
							)}
						</tbody>
					</table>

					{!loading && !data?.length && <h6 className={ft.noData}> {Translate(lang, 'global.noInfo')}</h6>}
					{!loading && <Pagin setPage={setPage} page={page} pageNum={pageNum} />}
				</div>
			</Container>

			<Filter show={showFilter} setShow={setShowFilter}>
				<Form className={ft.form} onSubmit={handleFilter}>
					<Form.Label>
						<div className={ft.lab}>{Translate(lang, 'global.orgName')}</div>
						<CustomSelect lang={lang} selected={selected} handleChange={v => setSelected(v)} options={options} />
					</Form.Label>

					<select className='my-2'>
						<option value='all'>{Translate(lang, 'global.all')}</option>
						{orgTypes.map(orgType => (
							<option value={orgType._id} key={orgType._id}>
								{orgType.name[lang]}
							</option>
						))}
					</select>

					<select className='my-2'>
						{periods.map((p, i) => {
							let year = p.end.slice(0, 4);
							let month = Number(p.start.slice(5, 7));
							let start = 2;

							if (Number(p.end.slice(5, 7)) === 1) {
								year -= 1;
							}

							if (month === 12 || month === 1) {
								start = 1;
							}

							return (
								<option value={i} key={i}>
									{
										` ${year}-${Translate(lang, "time.year")} 
											${start}-${Translate(lang,"time.half")}
										`
									}
								</option>
							)
						})}
					</select>

					<button className={`${ft.filter__btn} w-100 mt-2`} type='submit'>
						{Translate(lang, 'global.placeholder')}
					</button>
				</Form>
			</Filter>

			<EksportModal
				lang={lang}
				show={showEdit}
				selPer={selPer}
				periods={periods}
				data={allData}
				setShow={setShowEdit}
				setLoading={setLoading}
			/>
		</div>
	);
}
