import React, { useEffect, useState } from 'react';

import config from '../../../../config';

import { docReceivedAnswerApi } from '../../../../services/doc/docReceivedAnswerService';

import Pagin from '../../../../components/pagination';
import Translate from '../../../../components/lang';
import Loading from '../../../../components/loading';

import { BeautyFullTime } from '../../../../utils';

import ft from '../../../../components/mainStyles.module.scss';

const Answers = ({ lang, taskId }) => {
   const [data, setData] = useState([]);
   const [loading, setLoading] = useState(true);
   const [pc, setPc] = useState(0);
   const [pageNum, setPageNum] = useState(1);
   const [page, setPage] = useState({ limit: 10, page: 1 });

   const getList = () => {
      setLoading(true);

      const data = {
         ...page,
         taskId
      };

      docReceivedAnswerApi
         .getList(data)
         .then(res => {
            if (res.data.success) {
               setPageNum(Math.ceil(res.data.num));
               setPc((page.page - 1) * page.limit);
               setData(res.data.data);
            }
         })
        .catch(err => {
           console.log(err);
        })
         .finally(() => {
            setLoading(false);
         });
   }

   useEffect(() => {
      getList();
   }, [page]);

   return (
      <div className={ft.table}>
         <table id='table' className={ft.table__main}>
            <thead>
               <tr>
                  <td>#</td>
                  <td>{Translate(lang, 'table.link')}</td>
                  <td>{Translate(lang, 'table.reason')}</td>
                  <td>{Translate(lang, 'table.file')}</td>
                  <td>{Translate(lang, 'table.createdAt')}</td>
                  <td>{Translate(lang, 'table.status')}</td>
               </tr>
            </thead>

            <tbody>
               {!loading ? (
                  data.map((item, index) => (
                     <tr key={item._id}>
                        <td>{pc + index + 1}</td>
                        <td>{item.link}</td>
                        <td>{item.reason}</td>
                        <td className='text-nowrap'>
                           {item?.files.map((file, index) => (
                              <div key={index}>
                                 <a target='_blank' href={`${config.api.baseUrl}${file?.path}`}>
                                    {file?.path?.slice(15)}
                                 </a>
                              </div>
                           ))}
                        </td>
                        <td>{BeautyFullTime(item.createdAt)}</td>
                        <td>{Translate(lang, `status.${item.status}`)}</td>
                     </tr>
                  ))
               ) : (
                  <tr>
                     <td colSpan={6}>
                        <Loading cl='mt-3' big={true} size='md' />
                     </td>
                  </tr>
               )}
            </tbody>
         </table>

         {!loading && !data.length && (
            <h6 className={ft.noData}>
               {Translate(lang, 'table.notFound')}
            </h6>
         )}

         {!loading && (
            <Pagin pageNum={pageNum} page={page} setPage={setPage} />
         )}
      </div>
   )
}

export default Answers;