import { useEffect, useState } from 'react';
import st from './passport.module.scss';
import ft from '../../components/mainStyles.module.scss';
import Container from '../../components/container';
import PassportEdit from './passportEdit';
import { edit } from '../../components/icons';
import { passportApi } from '../../services/passportService';
import Loading from '../../components/loading';
import DivisionEdit from './divisionEdit';
import Translate from '../../components/lang';

const Passport = (props) => {
	const [show, setShow] = useState(false),
		[loading, setLoading] = useState(false),
		[data, setData] = useState([]),
		[showDivision, setShowDivision] = useState(false),
		{ lang } = props;

	useEffect(() => {
		setLoading(true);
		passportApi
			.profile({
				headers: {
					Authorization: `Bearer ${sessionStorage.getItem('token')}`,
				},
			})
			.then((res) => {
				setLoading(false);
				setData(res.data.data);
			})
			.catch((e) => {
				setLoading(false);
			});
	}, []);

	if (loading)
		return (
			<div className='d-flex justify-content-center align-items-center'>
				<Loading size='lg' big />
			</div>
		);
	return (
		<div className='row'>
			<div className='col-6'>
				<Container>
					<p className={`${st.title}`}>{Translate(lang, 'passport.titleLeft')}</p>
					<div className={ft.table}>
						<table className={ft.table__main}>
							<tbody>
								<tr>
									<td>{Translate(lang, 'global.orgName')}</td>
									<td className='text-right'>
										<p>{data?.name && data?.name?.length !== 0 ? data?.name[lang] : '___'}</p>
									</td>
								</tr>
								<tr>
									<td>{Translate(lang, 'passport.shortName')}</td>
									<td className='text-right'>
										{data?.shortName && data?.shortName?.length !== 0
											? data?.shortName[lang]
											: '___'}
									</td>
								</tr>
								<tr>
									<td>{Translate(lang, 'global.leader')}</td>
									<td className='text-right'>{data?.leader ? data?.leader : '___'}</td>
								</tr>
								<tr>
									<td>{Translate(lang, 'global.address')}</td>
									<td className='text-right'>{data?.legalAddress ? data?.legalAddress : '___'}</td>
								</tr>
								<tr>
									<td>{Translate(lang, 'global.phone')}</td>
									<td className='text-right'>{data?.phoneNumber ? `${data?.phoneNumber}` : '___'}</td>
								</tr>
								<tr>
									<td>{Translate(lang, 'passport.faks')}</td>
									<td className='text-right'>{data?.fax ? data?.fax : '___'}</td>
								</tr>
								<tr>
									<td>{Translate(lang, 'passport.pIndex')}</td>
									<td className='text-right'>{data?.postIndex ? data?.postIndex : '___'}</td>
								</tr>

								<tr>
									<td>{Translate(lang, 'passport.email')}</td>
									<td className='text-right'>{data.email}</td>
								</tr>

								<tr>
									<td>{Translate(lang, 'passport.site')}</td>
									<td className='text-right'>{data?.webSite ? data?.webSite : '___'}</td>
								</tr>

								<tr>
									<td>{Translate(lang, 'passport.orgType')}</td>
									<td className='text-right'>
										{data?.organizationType?.name[lang]}
									</td>
								</tr>

								<tr>
									<td>
										{Translate(lang, 'passport.lastUpdate')}{' '}
										{`
                                          ${data?.updatedAt
												?.toString()
												.split('')
												.slice(0, 10)
												.join('')
												.split('-')
												.reverse()
												.join('.')}
                                          ${data?.updatedAt?.toString().split('').slice(11, 19).join('')}
                             `}
									</td>
									<td>
										<div className='w-100 d-flex justify-content-end'>
											<button className={ft.edit__btn} onClick={() => setShow(true)}>
												{edit} <span className='pl-2'>{Translate(lang, 'global.edit')}</span>
											</button>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>

					{/* <div className={st.div}>
                       <p>{Translate(lang, "global.orgName")}</p>
                       <p>
                           {data?.name ? data?.name[lang] : "..."}
                       </p>
                    </div> */}
					{/* <div className={st.div}>
                       <p>{Translate(lang, "passport.shortName")}</p>
                       <p>{data?.shortName ? data?.shortName[lang] : "..."}</p>
                    </div> */}
					{/* <div className={st.div}>
                       <p>{Translate(lang, "global.leader")}</p>
                       <p>{data?.leader ? data?.leader : "..."}</p>
                    </div> */}
					{/* <div className={st.div}>
                       <p>{Translate(lang, "global.address")}</p>
                       <p>{data?.legalAddress ? data?.legalAddress : "..."}</p>
                    </div> */}
					{/* <div className={st.div}>
                       <p>{Translate(lang, "global.phone")}</p>
                       <p>{data?.phoneNumber ? `${data?.phoneNumber}` : "..."}</p>
                    </div> */}
					{/* <div className={st.div}>
                       <p>{Translate(lang, "passport.faks")}</p>
                       <p>{data?.fax ? data?.fax : "..."}</p>
                    </div> */}
					{/* <div className={st.div}>
                        <p>{Translate(lang, "passport.pIndex")}</p>
                        <p>{data?.postIndex ? data?.postIndex : "..."}</p>
                    </div> */}
					{/* <div className={st.div}>
                        <p>{Translate(lang, "passport.email")}</p>
                        <p>{data.email}</p>
                    </div> */}
					{/* <div className={st.div}>
                        <p>{Translate(lang, "passport.site")}</p>
                        <p>{data?.webSite ? data?.webSite : "..."}</p>
                    </div> */}
					{/* <div className={st.div}>
                        <p>{Translate(lang, "passport.orgType")}</p>
                        <p>
                            {+data?.organizationType === 0
                                ? `${Translate(lang, "passport.orgName1")}`
                                : `${Translate(lang, "passport.orgName2")}`}
                        </p>
                    </div> */}
					{/* <div className={st.edit}>
                        <p>
                            {Translate(lang, "passport.lastUpdate")}{" "}
                            <span>
                                {`
                                          ${data?.updatedAt
                                        ?.toString()
                                        .split("")
                                        .slice(0, 10)
                                        .join("")
                                        .split("-")
                                        .reverse()
                                        .join(".")}
                                          ${data?.updatedAt
                                        ?.toString()
                                        .split("")
                                        .slice(11, 19)
                                        .join("")}
                             `}
                            </span>
                        </p>
                        <button className={ft.edit__btn} onClick={() => setShow(true)}>
                            {edit}{" "}
                            <span className="pl-2">{Translate(lang, "global.edit")}</span>
                        </button>
                    </div> */}
				</Container>
			</div>
			<div className='col-6'>
				<Container>
					<p className={`${st.title}`}>{Translate(lang, 'passport.titleRight')}</p>
					<div className={ft.table}>
						<table className={ft.table__main}>
							<tbody>
								<tr>
									<td>{Translate(lang, 'passport.depName')}</td>
									<td className='text-right'>
										{data?.divisionName ? data?.divisionName[lang] : '___'}
									</td>
								</tr>
								<tr>
									<td>{Translate(lang, 'passport.depLeader')}</td>
									<td className='text-right'>
										{data?.divisionLeader ? data?.divisionLeader : '___'}
									</td>
								</tr>
								<tr>
									<td>{Translate(lang, 'passport.phone')}</td>
									<td className='text-right'>{data?.leaderPhone ? `${data?.leaderPhone}` : '___'}</td>
								</tr>
								<tr>
									<td>{Translate(lang, 'global.phone')}</td>
									<td className='text-right'>{data?.leaderPhone ? `${data?.leaderPhone}` : '___'}</td>
								</tr>
								<tr>
									<td>{Translate(lang, 'passport.mail')}</td>
									<td className='text-right'>{data?.leaderEmail ? data?.leaderEmail : '___'}</td>
								</tr>
								<tr>
									<td>{Translate(lang, 'passport.workerNum')}</td>
									<td className='text-right'>
										{data?.employeesNumber ? data?.employeesNumber : '___'}
									</td>
								</tr>

								<tr>
									<td>{Translate(lang, 'passport.responsiblePerson')}</td>
									<td className='text-right'>
										{data?.responsiblePerson ? data?.responsiblePerson : '___'}
									</td>
								</tr>

								<tr>
									<td>
										{Translate(lang, 'passport.lastUpdate')}{' '}
										{`
               ${data?.updatedAt?.toString().split('').slice(0, 10).join('').split('-').reverse().join('.')}
               ${data?.updatedAt?.toString().split('').slice(11, 19).join('')}
                       `}
									</td>
									<td>
										<div className='w-100 d-flex justify-content-end'>
											<button className={ft.edit__btn} onClick={() => setShowDivision(true)}>
												{edit} <span className='pl-2'>{Translate(lang, 'global.edit')}</span>
											</button>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</Container>
			</div>
			<PassportEdit
				show={show}
				setShow={setShow}
				data={data}
				setLoading={setLoading}
				setData={setData}
				lang={lang}
			/>
			<DivisionEdit
				show={showDivision}
				setShow={setShowDivision}
				data={data}
				setLoading={setLoading}
				setData={setData}
				lang={lang}
			/>
		</div>
	);
};

export default Passport;
