import React, { useState } from 'react';
import { Form } from 'react-bootstrap';

import { suggestionApi } from '../../../services/suggestionService';

import Translate from '../../../components/lang';
import Loading from '../../../components/loading';
import CustomModal from '../../../components/customModal';

import ft from '../../../components/mainStyles.module.scss';

const Update = ({ lang, id, setId, show, setShow, editData, getList }) => {
  const [loading, setLoading] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();

    const { name, type, description } = e.target.elements;

    const data = {
       name: name.value,
       type: type.value,
       description: description.value
    };

    setLoading(true);

    suggestionApi
       .update(id, data)
       .then(res => {
         if (res.data.success) {
           getList();
           setShow(false);
         }
       })
       .catch(() => {
         setId(null);
       })
       .finally(() => {
         setLoading(false);
       });

    setId(null);
  };

  return (
     <CustomModal
        title={Translate(lang, "global.edit")}
        setShow={setShow}
        setId={setId}
        show={show}
        size='md'
     >
       <Form className={ft.form} onSubmit={onSubmit}>
         <Form.Label className='w-100'>
           <div className={ft.lab}>
             {Translate(lang, "global.name")}
           </div>
           <Form.Control
              name='name'
              defaultValue={editData?.name}
              type='text'
              required
           />
         </Form.Label>

          <Form.Label className='w-100'>
             <div className={ft.lab}>{Translate(lang, 'global.type')}</div>
             <select name='type' defaultValue={editData?.type}>
                <option value='PROBLEM'>{Translate(lang, 'enum.problem')}</option>
                <option value='SPACE'>{Translate(lang, 'enum.space')}</option>
                <option value='CONFLICT'>{Translate(lang, 'enum.conflict')}</option>
             </select>
          </Form.Label>

         <Form.Label className='w-100'>
           <div className={ft.lab}>
             {Translate(lang, "global.description")}
           </div>
           <Form.Control
              name='description'
              defaultValue={editData?.description}
              as='textarea'
              type="text"
              required
           />
         </Form.Label>

         <div className="d-flex justify-content-end mt-2">
           <button
              className={`${ft.close__btn} mr-2`}
              type='button'
              onClick={() => {
                setShow(false);
                setId(null);
              }}
           >
             {Translate(lang, "global.cancel")}
           </button>

           <button className={ft.save__btn} type='submit'>
             {Translate(lang, 'global.save')}{' '}
             {loading ? <Loading cl='ml-1' size='sm' /> : ''}
           </button>
         </div>
       </Form>
     </CustomModal>
  );
};

export default React.memo(
   Update,
  (prevProps, nextProps) => nextProps.show === prevProps.show
);
