import React from "react";
import CustomModal from "../../../components/customModal";
import { Form } from "react-bootstrap";
import ft from "../../../components/mainStyles.module.scss";
import { structureApi } from "../../../services/structureService";
import { useState } from "react";
import Loading from "../../../components/loading";
import Translate from "../../../components/lang";
import { TextValidate } from "../../../utils";

const StructureEdit = (props) => {
  const { show, setShow, setLoading, setData, id, editData, setId, lang } =
      props,
    [load, setLoad] = useState(false),
    [numErr, setNumErr] = useState(false);

  const editHandler = (e) => {
    e.preventDefault();

    const val = e.target[4].value.match(/^(\+)([998]{3})([\d]{2})(\d+)/);
    if (val && val[4].length === 7) {
      setLoad(true);
      setNumErr(false);
      const data = {
        name: {
            uz: e.target[0].value,
            ru: e.target[1].value,
        },
        leader: e.target[2].value,
        legalAddress: e.target[3].value,
        phone: e.target[4].value,
        email: e.target[5].value,
    };
      structureApi
        .edit(id, data)
        .then((res) => {
          setLoad(false);
          if (res.data.success) {
            setLoad(false);
            setLoading(true);
            structureApi
              .all()
              .then((res) => {
                const data = res.data;
                setData(data.data);
                setLoading(false);
              })
              .catch((err) => {
                console.log(err.message);
                setLoading(false);
              });
            setShow(false);
          }
        })
        .catch((err) => {
          setId(null);
        });
    } else {
      setNumErr(true);
    }
    setId(null);
  };

    return (
      <CustomModal
        show={show}
        setShow={setShow}
        setId={setId}
        title={Translate(lang, "global.edit")}
        size={"md"}
      >
        <Form className={ft.form} onSubmit={editHandler}>
          <Form.Label className="w-100">
            <div className={ft.lab}>
              {Translate(lang, "structure.structureName")} (Uzb)
            </div>
            <Form.Control
              type="text"
              placeholder=""
              required
              defaultValue={editData?.name?.uz}
              onKeyUp={(e) => TextValidate(e.target)}
              maxLength="150"
            />
          </Form.Label>
          <Form.Label className="w-100">
            <div className={ft.lab}>
              {Translate(lang, "structure.structureName")}(Рус)
            </div>
            <Form.Control
              type="text"
              placeholder=""
              required
              defaultValue={editData?.name?.ru}
              onKeyUp={(e) => TextValidate(e.target)}
              maxLength="150"
            />
          </Form.Label>
          <Form.Label className="w-100">
            <div className={ft.lab}> {Translate(lang, "structure.leader")}</div>
            <Form.Control
              type="text"
              placeholder=""
              required
              defaultValue={editData?.leader}
              onKeyUp={(e) => TextValidate(e.target)}
              maxLength="30"
            />
          </Form.Label>
          <Form.Label className="w-100">
            <div className={ft.lab}>
              {" "}
              {Translate(lang, "structure.address")}
            </div>
            <Form.Control
              type="text"
              placeholder=""
              required
              defaultValue={editData?.legalAddress}
              maxLength="50"
            />
          </Form.Label>
          <Form.Label className="w-100">
            <div className={ft.lab}> {Translate(lang, "global.phone")}</div>
            <Form.Control
              type="text"
              placeholder=""
              required
              defaultValue={`${editData?.phone}`}
              style={
                numErr
                  ? {
                      backgroundColor: "rgba(255, 82, 82, 0.1)",
                    }
                  : {}
              }
            />
          </Form.Label>
          {numErr ? (
            <span className={ft.err__mes}>
              {Translate(lang, "global.phoneError")}
            </span>
          ) : (
            ""
          )}
          <Form.Label className="w-100">
            <div className={ft.lab}> {Translate(lang, "structure.mail")}</div>
            <Form.Control
              type="email"
              placeholder=""
              required
              defaultValue={editData?.email}
              maxLength="20"
            />
          </Form.Label>
          <div className="d-flex justify-content-end mt-2">
            <button
              className={`${ft.close__btn} mr-2`}
              type="button"
              onClick={() => {
                setShow(false);
                setId(null);
              }}
            >
              {Translate(lang, "global.cancel")}
            </button>
            <button className={ft.save__btn} type="submit">
              {Translate(lang, "global.save")}{" "}
              {load ? <Loading size="sm" cl="ml-1" /> : ""}
            </button>
          </div>
        </Form>
      </CustomModal>
    );
};

export default React.memo(
  StructureEdit,
  (prevProps, nextProps) => nextProps.show === prevProps.show
);
