import axiosInstance from "./api";

const api = "/assignments";

export const taskApi = {
  all: (data) => {
    return axiosInstance.post(`${api}/filter-client`, data,{
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    });
  },
  send: (id, data) => {
    return axiosInstance.put(`${api}/create-response/${id}`, data, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    });
  },
};
