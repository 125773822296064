import CustomModal from '../../../../components/customModal';
import ft from '../../../../components/mainStyles.module.scss';
import { Form } from 'react-bootstrap';
import { legalStatusApi } from '../../../../services/legalStatusService';
import Loading from '../../../../components/loading';
import { useState } from 'react';
import Translate from '../../../../components/lang';

const EditLS10 = (props) => {
	const [load, setLoad] = useState(false),
		{
			lang,
			setLoading,
			setData,
			setShow,
			show,
			id,
			data,
			periods,
			selPer,
		} = props;

	const handleSubmit = (e) => {
		e.preventDefault();
		setLoad(true);
		const DATA = {
			code: 10,
			nameOne: e.target[0].value,
			countTwo: data?.countTwo,
			countOne: +e.target[1].value,
			link: data?.link,
		};

		legalStatusApi.edit(id, DATA).then((res) => {
			if (res.data.success) {
				setLoad(false);
				setLoading(true);
				legalStatusApi
					.all({
						code: 10,
						start: periods[selPer]?.start?.slice(0, 10),
						end: periods[selPer]?.end?.slice(0, 10),
						period: periods[selPer]?._id,
					})
					.then((res) => {
						const DATA = [];
						if (res.data.num.length)
							res.data.num.forEach((d) => {
								if (d.secPer) {
									DATA.push({
										...d,
										lastPer: true,
										countOne: '',
									});
								}
							});

						setData([...DATA, ...res.data.data]);
						setLoading(false);
					})
					.catch((err) => {
						console.log(err.message);
						setLoading(false);
					});
				setShow(false);
			}
		});
	};
	return (
		<CustomModal
			title={Translate(lang, 'L10.title')}
			show={show}
			setShow={setShow}>
			<form action='' className='row' onSubmit={handleSubmit}>
				<div className={`col-12 ${ft.form}`}>
					<Form.Label className='w-100'>
						<div className={ft.lab}>
							{Translate(lang, 'L10.setName')}
						</div>
						<Form.Control
							type='text'
							placeholder=''
							required
							defaultValue={data?.nameOne}
							// disabled
						/>
					</Form.Label>

					<Form.Label className='w-50'>
						<div className={ft.lab}>
							{Translate(lang, 'L10.oldMembers')}
						</div>
						<Form.Control
							type='number'
							placeholder=''
							required
							defaultValue={data?.countOne}
							min='0'
						/>
					</Form.Label>
					<br />
					<Form.Label className='w-50'>
						<div className={ft.lab}>
							{Translate(lang, 'L10.newMembers')}
						</div>
						<Form.Control
							type='number'
							placeholder=''
							required
							defaultValue={
								data?.countThree === -1
									? data?.countTwo
									: data?.countThree
							}
							min='0'
							disabled
						/>
					</Form.Label>
					<Form.Label className='w-100'>
						<div className={ft.lab}>
							{Translate(lang, 'L1.link')}
						</div>
						<Form.Control
							type='text'
							placeholder=''
							required
							defaultValue={data?.link}
							disabled
						/>
					</Form.Label>
				</div>
				<div className='col-12 d-flex justify-content-end my-3'>
					<button
						type='button'
						className={ft.close__btn}
						onClick={() => setShow(false)}>
						{Translate(lang, 'global.cancel')}
					</button>
					<button className={`${ft.save__btn} ml-3`} type='submit'>
						{Translate(lang, 'global.save')}
						{load ? <Loading size='sm' cl='ml-1' /> : ''}
					</button>
				</div>
			</form>
		</CustomModal>
	);
};

export default EditLS10;
