export default function Circle({ num, col, fontSize, size }) {
  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{
        width: size,
        height: size,
        color: `rgb(${col})`,
        backgroundColor: `rgba(${col}, 0.1)`,
        borderRadius: "50%",
        fontSize: fontSize,
        textAlign: "center",
      }}
    >
      {num}
    </div>
  );
}
