import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import axios from 'axios';

import config from '../../config';

import { compIcon } from '../../components/icons';

import { authApi } from '../../services/authService';

import Loading from '../../components/loading';
import Translate from '../../components/lang';

import st from './login.module.scss';
import ft from '../../components/mainStyles.module.scss';

const ERROR_TYPE = {
	INVALID_EMAIL_OR_PASSWORD: 'INVALID_EMAIL_OR_PASSWORD',
	LARGE_INCORRECT_ATTEMPT: 'LARGE_INCORRECT_ATTEMPT',
	ORGANIZATION_INACTIVE: 'ORGANIZATION_INACTIVE'
}

const THREE_MINUTE = 180; // In the seconds

export default function Login({ setToken, lang }) {
	let history = useHistory();
	const	[view, setView] = useState(false);
	const	[load, setLoad] = useState(false);
	const [error, setError] = useState(null);
	const [time, setTime] = useState(0);

	const minute = useMemo(() => Math.trunc(time / 60), [time]);

	const second = useMemo(() => {
		const num = time - Math.trunc(time / 60) * 60;
		return num > 9 ? num : '0' + num;
	}, [time])

	const handleSubmit = (e) => {
		e.preventDefault();

		setError(null);
		setLoad(true);

		authApi.login({ login: e.target[0].value, password: e.target[1].value })
			.then(res => {
				if (res.data) {
					axios.get('https://ipapi.co/json/')
						.then(res => {
							const data = { login: e.target[0].value, ip: res.data.ip, region: res.data.region };

							axios.post(`${config.api.baseUrl}/api/visits/add`, data)
								.then(res => {
									console.log();
								})
								.catch(err => {
									console.log("Visit Error: ", err);
								});
						})
						.catch(err => {
							console.log("Ipi Error: ", err);
						});

					setToken(res.data.token);
					sessionStorage.setItem('token', res.data.token);
					history.push('/');
				}
			})
			.catch(err => {
				const type = err.response.data.type;
				if (type === ERROR_TYPE.LARGE_INCORRECT_ATTEMPT) {
					localStorage.setItem('error', JSON.stringify({ type, date: new Date() }));
					setTime(THREE_MINUTE);
				}
				setError(type);
			})
			.finally(() => {
				setLoad(false);
			});
	};

	useEffect(() => {
		const error = JSON.parse(localStorage.getItem('error'));

		if (error) {
			const errorDate = new Date(error.date);

			const updateTime = setInterval(() => {
				const pastTime = ((new Date()) - errorDate) / 1000;
				if (pastTime < THREE_MINUTE) {
					setError(ERROR_TYPE.LARGE_INCORRECT_ATTEMPT);
					setTime(Math.trunc(THREE_MINUTE - pastTime));
				} else {
					clearInterval(updateTime);
					localStorage.removeItem('error');
					setError(null);
				}
			}, 1000);
		}
	}, [error]);

	return (
		<div className={st.login}>
			<div className={st.bgColor}>{compIcon(st)}</div>

			<div className={st.login__content}>
				<h4>{Translate(lang, 'login.title')}</h4>

				<Form className={`${ft.form} ${st.form}`} onSubmit={handleSubmit}>
					<p>{Translate(lang, 'login.signIn')}</p>

					<Form.Label>
						<div className={ft.lab}>{Translate(lang, 'login.login')}</div>
						<Form.Control type='text' required />
					</Form.Label>

					<Form.Label>
						<div className={ft.lab}>{Translate(lang, 'login.parol')}</div>
						<div className='position-relative'>
							<Form.Control type={view ? 'text' : 'password'} required />
							<i
								className={`far fa-eye${view ? '' : '-slash'} position-absolute`}
								style={{ top: '50%', right: 0, transform: 'translate(-10px, -8px)', cursor: 'pointer' }}
								onClick={() => setView(!view)}
							/>
						</div>
					</Form.Label>

					{error && (
						<div className='alert alert-danger py-1 text-center' role='alert'>
							{error === ERROR_TYPE.INVALID_EMAIL_OR_PASSWORD ? (
								Translate(lang, 'login.invalidEmailOrPassword')
							) : error === ERROR_TYPE.LARGE_INCORRECT_ATTEMPT ? (
								<>
									{Translate(lang, 'login.largeIncorrectAttempt')} {' '} {minute}:{second}
								</>
							) : error === ERROR_TYPE.ORGANIZATION_INACTIVE ? (
								Translate(lang, 'login.noActiveOrg')
							) : null}
						</div>
					)}

					<button
						className={`${ft.save__btn} d-flex align-items-center justify-content-center w-100 mt-4`}
						type='submit'
						disabled={load || error === ERROR_TYPE.LARGE_INCORRECT_ATTEMPT}
					>
						{Translate(lang, 'login.sign')}
						{load && <Loading size='sm' cl='ml-1' />}
					</button>
				</Form>

				<p className={st.copyright}>&copy; {Translate(lang, 'login.text')}</p>
			</div>
		</div>
	);
}
