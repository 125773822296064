import React, { useEffect, useState } from 'react';
import Back from '../../../components/back';
import { Link } from 'react-router-dom';
import Translate from '../../../components/lang';
import notfound from '../../../images/notfound.png';
import st from './passwordEdit.module.scss';
import { ValidatePassword, GeneratePassword } from '../../../utils';
import { icon } from '../../../components/icons';
import {
	changeImgApi,
	passwordApi,
} from '../../../services/passwordEditService';
import Loading from '../../../components/loading';
import { APIFile } from '../../../services/api';
const PasswordEdit = (props) => {
	const [oldPassword, setOldPassWord] = useState(),
		[password, setPassWord] = useState(),
		[confirmPassword, setConfirmPassWord] = useState(),
		[file, setFile] = useState(),
		[notimg, setNotImg] = useState(false),
		[warning, setWarning] = useState(false),
		[view, setView] = useState(false),
		[view2, setView2] = useState(false),
		[view3, setView3] = useState(false),
		[isValidate, setIsValidate] = useState(true),
		[loading, setLoading] = useState(false),
		{ setUser, lang, user } = props,
		[errData, setErrData] = useState(''),
		[alert, setAlert] = useState(false);

	useEffect(() => {
		setFile(user?.image);
		user?.image ? setNotImg(true) : setNotImg(false);
	}, [user]);
	const handleChangeImg = (e) => {
		e.preventDefault();
		setFile(user?.image);
		const fd = new FormData();
		fd.append('image', e.target.files[0]);

		changeImgApi
			.orgImg(fd, {
				headers: {
					Authorization: `Bearer ${sessionStorage.getItem('token')}`,
				},
			})
			.then((res) => {
				window.location.reload(true);
				setFile(user.image);
				setUser(user?.image);
			})
			.catch((err) => {
				if (err) {
					setWarning(false);
					setLoading(false);
				}
			});
	};

	const handleChangeInfo = (e) => {
		e.preventDefault();
		setIsValidate(true);
		setLoading(true);
		setOldPassWord('');
		setPassWord('');
		setConfirmPassWord('');
		if (ValidatePassword(password)) {
			if (password === confirmPassword && password !== oldPassword) {
				setWarning(false);
				passwordApi
					.password(
						{
							oldPassword,
							password,
						},
						{
							headers: {
								Authorization: `Bearer ${sessionStorage.getItem(
									'token'
								)}`,
							},
						}
					)
					.then((res) => {
						setErrData(`${Translate(lang, 'profil.parolChanged')}`);
						setAlert(false);
						setWarning(true);
						setLoading(false);
					})
					.catch((err) => {
						setErrData(`${Translate(lang, 'profil.oldpnotmatch')}`);
						setAlert(true);
						setWarning(true);
						setLoading(false);
					});
			} else {
				setLoading(false);
				setWarning(true);
				setErrData(`${Translate(lang, 'profil.parolIncorrect')}`);
			}
		} else {
			setLoading(false);
			setWarning(false);
			setIsValidate(false);
		}
	};
	return (
		<div className='p-4' style={{ height: '100vh' }}>
			<Link to='/'>
				<Back lang={lang} />
			</Link>
			<div className='d-flex justify-content-center my-4 w-100'>
				<div className={st.img__card}>
					<h5>{Translate(lang, 'profil.changeImg')}</h5>
					<div className='text-center'>
						{notimg ? (
							<img className={st.img} src={`${APIFile}${file}`} />
						) : (
							<img className={st.img} src={`${notfound}`} />
						)}
					</div>

					<input
						id='files'
						type='file'
						onChange={handleChangeImg}
						className={`${st.btn} ${st.color}`}
						placeholder={Translate(lang, 'profil.uploadnewImg')}
						style={{ visibility: 'hidden' }}
						// style={{ display: "none" }}
					/>
					<label
						for='files'
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							cursor: 'pointer',
							margin: '18px auto',
							width: '85%',
						}}
						className={`${st.btn} ${st.color}`}>
						{Translate(lang, 'profil.uploadImg')}
					</label>
				</div>
				<div className='w-50'>
					<div className={st.form__card}>
						<h5 style={{ color: '#07A287' }}>
							{Translate(lang, 'profil.changeLogin')}
						</h5>
						<div className={st.card}>
							<div className='w-50 text-center'>{icon}</div>

							<form
								action=''
								onSubmit={handleChangeInfo}
								className={st.form}>
								<label className={st.label}>
									{Translate(lang, 'profil.oldPass')}
								</label>
								<div className='position-relative'>
									<input
										id='oldPasswordInput'
										type={view ? 'text' : 'password'}
										required
										className={st.input}
										value={oldPassword}
										onChange={(e) =>
											setOldPassWord(e.target.value)
										}
									/>
									<i
										className={`far fa-eye${
											view ? '' : '-slash'
										} position-absolute`}
										style={{
											top: '50%',
											right: 0,
											transform: 'translate(-10px, -8px)',
											cursor: 'pointer',
										}}
										onClick={() => setView(!view)}
									/>
								</div>
								<label className={st.label}>
									{Translate(lang, 'profil.newPass')}
								</label>
								<span
									className={`${st.label}`}
									style={{
										cursor: 'pointer',
										marginLeft: '50%',
									}}
									onClick={() => {
                    const temp = GeneratePassword();
                    setPassWord(temp);
                    setConfirmPassWord(temp);
										// password.current.value = temp
										// passwordConfirm.current.value = temp
									}}>
									{Translate(lang, 'global.genPass')}
								</span>
								<div className='position-relative'>
									<input
										// disabled={disabled}
										id='passwordInput'
										type={view2 ? 'text' : 'password'}
										required
										className={st.input}
										value={password}
										onChange={(e) =>
											setPassWord(e.target.value)
										}
									/>
									<i
										className={`far fa-eye${
											view2 ? '' : '-slash'
										} position-absolute`}
										style={{
											top: '50%',
											right: 0,
											transform: 'translate(-10px, -8px)',
											cursor: 'pointer',
										}}
										onClick={() => setView2(!view2)}
									/>
								</div>
								<label className={st.label}>
									{Translate(lang, 'profil.confirmP')}
								</label>
								<div className='position-relative'>
									<input
										// disabled={disabled}
										type={view3 ? 'text' : 'password'}
										required
										className={st.input}
										value={confirmPassword}
										onChange={(e) =>
											setConfirmPassWord(e.target.value)
										}
									/>
									<i
										className={`far fa-eye${
											view3 ? '' : '-slash'
										} position-absolute`}
										style={{
											top: '50%',
											right: 0,
											transform: 'translate(-10px, -8px)',
											cursor: 'pointer',
										}}
										onClick={() => setView3(!view3)}
									/>
								</div>
								{!isValidate ? (
									<span
										style={{
											fontSize: '16px',
											color: 'red',
										}}>
										{Translate(lang, 'global.errPass')}
									</span>
								) : (
									''
								)}
								{warning ? (
									alert ? (
										<div
											id='alert'
											class='alert alert-danger py-2 text-center'
											role='alert'>
											{Translate(
												lang,
												'profil.incorrect'
											)}
										</div>
									) : (
										<div
											id='alert'
											className={`alert alert-${
												errData ==
												Translate(
													lang,
													'profil.parolIncorrect'
												)
													? 'danger'
													: 'success'
											} py-2 text-center`}
											role='alert'>
											{errData}
										</div>
									)
								) : (
									''
								)}
								{loading ? (
									<Loading size='sm' />
								) : (
									<button className={`${st.btn} ${st.color}`}>
										{' '}
										{Translate(lang, 'profil.change')}{' '}
									</button>
								)}
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default PasswordEdit;
