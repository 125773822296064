import axiosInstance from "./api";

const api = "/organizations",
    headers = {
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
    };

export const orgApi = {
    getAll: () => {
        return axiosInstance.get(`${api}/get-all-client`, headers);
    },
    all: (d, data) => {
        return axiosInstance.get(
            `${api}/all?limit=${d.limit}&page=${d.page}`,
            data
        );
    },
    delete: (id, data) => axiosInstance.delete(`${api}/delete/${id}`, data),
    rank: (data) => axiosInstance.post(`${api}/get-rank-client`, data, headers)
};
