import ft from '../../../components/mainStyles.module.scss'
import Circle from '../../../components/circle'
import Translate from '../../../components/lang'
import { Link } from 'react-router-dom'
import Loading from '../../../components/loading'

export default function LeftSide(props) {
	const { lang, data, loading2, isHund, setPageUrl, markingStarted } = props

	const firstColor = (score) => {
		if (score === 0) return '51, 51, 51'
		else if (score === 1) return '255, 62, 62'
		else if (score === 2 || score === 3) return '255, 166, 62'
		else if (score >= 4) return '7, 162, 135'
	} //1

	const secColor = (max, score) => {
		const temp = (score * 100) / max
		if (temp === 0) return '51, 51, 51'
		else if (temp > 0 && temp <= 30) return '255, 62, 62'
		else if (temp > 30 && temp <= 70) return '255, 166, 62'
		else if (temp > 71) return '7, 162, 135'
	} //2

	const thirdColor = (max, score) => {
		const temp = (score * 100) / max
		if (temp === 0) return '51, 51, 51'
		else if (temp > 0 && temp <= 42.9) return '255, 62, 62'
		else if (temp > 42.9 && temp <= 71.5) return '255, 166, 62'
		else if (temp > 71.5) return '7, 162, 135'
	} //3 & 4

	const fifthColor = (max, score) => {
		const temp = (score * 100) / max
		if (temp === 0) return '51, 51, 51'
		else if (temp > 0 && temp <= 37.5) return '255, 62, 62'
		else if (temp > 37.5 && temp <= 87.5) return '255, 166, 62'
		else if (temp > 87.5) return '7, 162, 135'
	} //5

	const sixColor = (max, score) => {
		const temp = (score * 100) / max
		if (temp === 0) return '51, 51, 51'
		else if (temp > 0 && temp <= 35) return '255, 62, 62'
		else if (temp > 37.5 && temp <= 70) return '255, 166, 62'
		else if (temp > 70) return '7, 162, 135'
	} //6

	const sevenColor = (score, max) => {
		const temp = (score * 100) / max
		if (temp === 0) return '51, 51, 51'
		else if (temp > 0 && temp <= 35) return '255, 62, 62'
		else if (temp > 37.5 && temp <= 70) return '255, 166, 62'
		else if (temp > 70) return '7, 162, 135'
	} //7 & 11

	const eightColor = (max, score) => {
		const temp = (score * 100) / max
		if (temp === 0) return '51, 51, 51'
		else if (temp > 0 && temp <= 40) return '255, 62, 62'
		else if (temp > 40 && temp <= 70) return '255, 166, 62'
		else if (temp > 71) return '7, 162, 135'
	} //8 & 9

	const tenColor = (max, score) => {
		const temp = (score * 100) / max
		if (temp === 0) return '51, 51, 51'
		else if (temp > 0 && temp <= 40) return '255, 62, 62'
		else if (temp > 40 && temp <= 70) return '255, 166, 62'
		else if (temp > 71) return '7, 162, 135'
	} //10

	const elevnColor = (max, score) => {
		const temp = (score * 100) / max
		if (temp === 0) return '51, 51, 51'
		else if (temp > 0 && temp <= 41.7) return '255, 62, 62'
		else if (temp > 41.7 && temp <= 75) return '255, 166, 62'
		else if (temp > 75) return '7, 162, 135'
	} //11

	const twelfColor = (max, score) => {
		const temp = (score * 100) / max
		if (temp === 0) return '51, 51, 51'
		else if (temp > 0 && temp <= 33.35) return '255, 62, 62'
		else if (temp > 33.35 && temp <= 66.67) return '255, 166, 62'
		else if (temp > 66.67) return '7, 162, 135'
	} //12


	return (
		<div className='p-0'>
			<div className={ft.content__head}>
				<div className={ft.table}>
					<table className={ft.table__main}>
						<thead>
							<tr>
								<td>#</td>
								<td>
									{Translate(lang, 'leftSideTab.indicator')}
								</td>
								<td>
									{Translate(lang, 'leftSideTab.maxScore')}
								</td>
								<td>{Translate(lang, 'leftSideTab.result')}</td>
								<td>{Translate(lang, 'leftSideTab.expert')}</td>
								<td className='text-center'>
									{Translate(lang, 'global.amal')}
								</td>
							</tr>
						</thead>
						<tbody>
							{!loading2 ? (
								data.map((d, index) => {
									if (index !== 13)
										return (
											<tr>
												<td>1.{index + 1}</td>
												<td>{d.title[lang]}</td>
												<td>
													<div className='d-flex justify-content-center'>
														<Circle
															num={d.maxNum}
															col={'7, 162, 135'}
															size='35px'
															fs={15}
														/>
													</div>
												</td>

												<td>
													<div className='d-flex justify-content-center'>
														{markingStarted?.status ===
														1 ? (
															'___'
														) : d?.score !==
																undefined &&
														  d.score !== -1 ? (
															index === 0 ? (
																<Circle
																	num={
																		d.score
																	}
																	col={firstColor(
																		d.score
																	)}
																	size='38px'
																	fs={15}
																/>
															) : index === 1 ? (
																<Circle
																	num={
																		d.score
																	}
																	col={secColor(
																		d.maxNum,
																		d.score
																	)}
																	size='38px'
																	fs={15}
																/>
															) : index === 2 ||
															  index === 3 ? (
																<Circle
																	num={
																		d.score
																	}
																	col={thirdColor(
																		d.maxNum,
																		d.score
																	)}
																	size='38px'
																	fs={15}
																/>
															) : index === 4 ? (
																<Circle
																	num={
																		d.score
																	}
																	col={fifthColor(
																		d.maxNum,
																		d.score
																	)}
																	size='38px'
																	fs={15}
																/>
															) : index === 5 ? (
																<Circle
																	num={
																		d.score
																	}
																	col={sixColor(
																		d.maxNum,
																		d.score
																	)}
																	size='38px'
																	fs={15}
																/>
															) : index === 6 ||
															  index === 10 ? (
																<Circle
																	num={
																		d.score
																	}
																	col={sevenColor(
																		d.score,
																		d.maxNum
																	)}
																	size='38px'
																	fs={15}
																/>
															) : index === 7 ||
															  index === 8 ? (
																<Circle
																	num={
																		d.score
																	}
																	col={eightColor(
																		d.maxNum,
																		d.score
																	)}
																	size='38px'
																	fs={15}
																/>
															) : index === 9 ? (
																<Circle
																	num={
																		d.score
																	}
																	col={tenColor(
																		d.maxNum,
																		d.score
																	)}
																	size='38px'
																	fs={15}
																/>
															) : index === 11 ? (
																<Circle
																	num={
																		d.score
																	}
																	col={elevnColor(
																		d.maxNum,
																		d.score
																	)}
																	size='38px'
																	fs={15}
																/>
															) : (
																<Circle
																	num={
																		d.score
																	}
																	col={twelfColor(
																		d.maxNum,
																		d.score
																	)}
																	size='38px'
																	fs={15}
																/>
															)
														) : (
															<div>___</div>
														)}
													</div>
												</td>
												<td>
													{markingStarted?.status ===
													1 ? (
														'___'
													) : d?.expert?.fullName
															?.length ? (
														d.expert.fullName
													) : (
														<div>___</div>
													)}
												</td>
												<td>
													<button
														className={ft.info__btn}
														style={{
															padding: '5px 15px',
															borderRadius:
																'20px',
														}}>
														<Link
															to={`/legal-status/${
																index + 1
															}`}
															onClick={() =>
																setPageUrl(
																	index + 1
																)
															}>
															{Translate(
																lang,
																'leftSideTab.seeFull'
															)}
														</Link>
													</button>
												</td>
											</tr>
										)
								})
							) : (
								<Loading big={true} size='md' cl='mt-3' />
							)}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	)
}
