import axiosInstance from '../api';

const api = '/doc-received-answers';

const	headers = {
	headers: {
		Authorization: `Bearer ${sessionStorage.getItem('token')}`
	}
};

export const docReceivedAnswerApi = {
	getList: (data) => axiosInstance.post(`${api}/list`, data, headers),
	create: (data) => axiosInstance.post(api, data, headers),
	update: (id, data) => axiosInstance.put(`${api}/${id}`, data, headers),
	delete: (id) => axiosInstance.delete(`${api}/${id}`, headers),
}