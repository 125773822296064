import React, {useState, useRef, useEffect} from "react";
import axios from "axios";
import Translate from "../lang";
import st from "./uploadFiles.module.scss";
import ft from "../uploadImage/uploadImage.module.scss";
import ht from "../mainStyles.module.scss";
import {del, fileIcon, filesIcon, imagesIcon, imgIcon} from "../icons";
import {API} from "../../services/api";

export default function UploadFiles({
                                        lang,
                                        selFiles,
                                        setSelFiles,
                                        newFiles,
                                        setNewFiles,
                                        prots,
                                        setProts,
                                        handleDeleteFile,
                                        setErrFiles,
                                        setUploadedFiles,
                                    }) {
    const [uploading, setUploading] = useState(false);
    const [previews, setPreviews] = useState([]);
    const [isDrag, setDrag] = useState(false);
    const fileInput = useRef();
    const [send, setSend] = useState([]);
    const [sizeErr, setSizeErr] = useState(false);

    // useEffect(() => {
    // 	if (selFiles.length) {
    // 		async function getPreviews() {
    // 			if (selFiles?.type) {
    // 				const promises = selFiles.map(getPreview)
    // 				setPreviews(await Promise.all(promises))
    // 			} else {
    // 				setPreviews((prev) => {
    // 					let temp = []
    // 					selFiles.forEach((f) => {
    // 						if (
    // 							f?.name?.includes('.jpg') ||
    // 							f?.name?.includes('.png')
    // 						)
    // 							temp.push(imagesIcon)
    // 						else temp.push(filesIcon)
    // 					})
    // 					return temp
    // 				})
    // 			}
    // 		}
    // 		getPreviews()
    // 	}
    // }, [selFiles])

    useEffect(() => {
        async function getPreviews() {
            if (newFiles?.type) {
                const promises = newFiles.map(getPreview);
                setPreviews(await Promise.all(promises));
            } else {
                setPreviews((prev) => {
                    let temp = [];
                    newFiles.forEach((f) => {
                        if (f?.name?.includes(".jpg") || f?.name?.includes(".png"))
                            temp.push(imagesIcon);
                        else temp.push(filesIcon);
                    });
                    return temp;
                });
            }
        }

        getPreviews();
    }, [newFiles]);
    //
    // useEffect(() => {
    //     setSizeErr(false)
    // }, [newFiles])

    useEffect(() => {
        setSizeErr(false);
        if (newFiles.length) {
            newFiles?.forEach((file, index) => {
                if (file.size / (1024 * 1024) > 30) {
                    setSizeErr(true);
                }
            });
        }
    }, [newFiles]);

    const handleDrop = (e) => {
        e.preventDefault();

        const draggedFiles = [];

        if (
            (selFiles?.length ? selFiles.length : 0) +
            (newFiles?.length ? newFiles.length : 0) <
            3
        ) {
            if (e.dataTransfer.items) {
                Array.from(e.dataTransfer.items).forEach((item, i) => {
                    if (item.kind === "file") {
                        let file = item.getAsFile();
                        draggedFiles.push(file);
                    }
                });
            } else {
                Array.from(e.dataTransfer.files).forEach((file, i) => {
                    draggedFiles.push(file);
                });
            }
        }

        switch (selFiles?.length) {
            case 1:
                setNewFiles([...newFiles, ...draggedFiles].slice(0, 2));
                break;
            case 2:
                setNewFiles([...newFiles, ...draggedFiles].slice(0, 1));
                break;
            default:
                setNewFiles([...newFiles, ...draggedFiles].slice(0, 3));
                break;
        }

        setDrag(false);
    };

    const handleChange = (e) => {
        // if ((newFiles?.length ? newFiles.length : 0) + (selFiles?.length ? selFiles.length : 0) < 3) {
        // setNewFiles([...newFiles, ...Array.from(e.target.files)].slice(0, 3))

        switch (selFiles?.length) {
            case 1:
                setNewFiles([...newFiles, ...Array.from(e.target.files)].slice(0, 2));
                break;
            case 2:
                setNewFiles([...newFiles, ...Array.from(e.target.files)].slice(0, 1));
                break;
            default:
                setNewFiles([...newFiles, ...Array.from(e.target.files)].slice(0, 3));
                break;
        }

        setProts((prev) => {
            let temp = [...prev];
            Array.from(e.target.files).forEach(() => temp.push(0));
            return temp;
        });
        // }
    };

    const handleUpload = () => {
        if (!newFiles.length) {
            return;
        }

        if (sizeErr) {
            return;
        }

        newFiles?.forEach((file, index) => {
            setUploading(true);
            setErrFiles(false);
            if (!file.success) {
                const data = new FormData();
                const source = axios.CancelToken.source();
                data.append("file", file);
                axios
                    .post(`${API}file/upload`, data, {
                        onUploadProgress: function (progressEvent) {
                            setProts((prev) => {
                                prev[index] = Math.round(
                                    (progressEvent.loaded * 100) / progressEvent.total
                                );
                                return [...prev];
                            });
                            setSend((prev) => {
                                prev[index] = source;
                                return [...prev];
                            });
                        },
                        cancelToken: source.token,
                    })
                    .then((res) => {
                        setNewFiles((prev) => {
                            let temp = [...prev];
                            temp[index].success = true;
                            return temp;
                        });
                        setUploadedFiles((prev) => {
                            let temp = [...prev];
                            temp.push(res.data.data);
                            return temp;
                        });
                        setUploading(false);
                    })
                    .catch((error) => {
                        setUploading(false);
                        console.log(error.message);
                    });
            } else setUploading(false);
        });
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        setDrag(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        setDrag(false);
    };

    const openFileDialog = () => {
        fileInput.current.click();
    };

    return (
        <div className={st.uploadFiles}>
            <div
                className={`file-upload-container ${isDrag ? "drag" : ""} ${
                    uploading ? "uploading" : ""
                }`}
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
            >
                <input ref={fileInput} type="file" multiple onChange={handleChange}/>
                <button
                    type="button"
                    onClick={() => {
                        if (
                            (newFiles?.length ? newFiles.length : 0) +
                            (selFiles?.length ? selFiles.length : 0) <
                            3
                        ) {
                            openFileDialog();
                        }
                    }}
                    className={`${ft.upload__btn}`}
                    disabled={uploading}
                >
                    <span>{fileIcon}</span> <span className="ml-2">{imgIcon}</span>
                    <p className="mt-2">{Translate(lang, "global.imgUpload")}</p>
                </button>
                <ul className="upload-preview">
                    {!!selFiles?.length &&
                    selFiles?.map((file, i) => (
                        <li key={file.name}>
                            <div className={st.svg}>
                                {file.name.includes(".png") ||
                                file.name.includes(".jpg") ||
                                file.name.includes(".bmp") ||
                                file.name.includes(".webp")
                                    ? imagesIcon
                                    : filesIcon}
                            </div>
                            <div className="w-100 px-2">
                                <div>{file.name}</div>
                                <div
                                    style={{
                                        marginTop: 5,
                                        height: "6px",
                                        width: "100%",
                                        backgroundColor: "#eee",
                                        borderRadius: "50px",
                                        position: "relative",
                                    }}
                                >
                                    <div
                                        style={{
                                            position: "absolute",
                                            top: 0,
                                            left: 0,
                                            width: "100%",
                                            height: "6px",
                                            backgroundColor: "#07A287",
                                            borderRadius: "50px",
                                        }}
                                    />
                                </div>
                            </div>
                            <div>
                                <button
                                    type="button"
                                    className={ft.del__btn}
                                    style={{
                                        height: "fit-content",
                                        backgroundColor: "rgba(255, 82, 82, 0.1)",
                                        borderRadius: 4,
                                        padding: 4,
                                    }}
                                    onClick={() => handleDeleteFile(file._id)}
                                >
                                    {del}
                                </button>
                            </div>
                        </li>
                    ))}
                    {!!newFiles?.length &&
                    newFiles?.map((file, i) => (
                        <li key={file.name}>
                            <div className={st.svg}>{previews[i]}</div>
                            <div className="w-100 px-2">
                                <div>
                                    {file.name} (
                                    {Math.floor((file.size / (1024 * 1024)) * 100) / 100}mb)
                                </div>
                                <div
                                    style={{
                                        marginTop: 5,
                                        height: "6px",
                                        width: "100%",
                                        backgroundColor: "#eee",
                                        borderRadius: "50px",
                                        position: "relative",
                                    }}
                                >
                                    <div
                                        style={{
                                            position: "absolute",
                                            top: 0,
                                            left: 0,
                                            width: `${prots[i]}%`,
                                            height: "6px",
                                            backgroundColor: "#07A287",
                                            borderRadius: "50px",
                                        }}
                                    />
                                </div>
                            </div>
                            <div>
                                <button
                                    type="button"
                                    className={ft.del__btn}
                                    style={{
                                        height: "fit-content",
                                        backgroundColor: "rgba(255, 82, 82, 0.1)",
                                        borderRadius: 4,
                                        padding: 4,
                                    }}
                                    onClick={() => {
                                        setNewFiles((prev) => {
                                            let temp = [...prev];
                                            temp.splice(i, 1);
                                            return temp;
                                        });
                                        if (file.success)
                                            setUploadedFiles((prev) => {
                                                let temp = [...prev];
                                                temp.splice(i, 1);
                                                return temp;
                                            });
                                        setProts((prev) => {
                                            let temp = [...prev];
                                            temp.splice(i, 1);
                                            return temp;
                                        });
                                        send[i]?.cancel();
                                    }}
                                >
                                    {del}
                                </button>
                            </div>
                            <br/>
                        </li>
                    ))}
                </ul>
            </div>

            {sizeErr && (
                <span className={ht.err__mes}>
          {Translate(lang, "global.maxfilelength")}
        </span>
            )}

            {newFiles.length ? (
                <button
                    className="upload-btn"
                    type="button"
                    onClick={handleUpload}
                    disabled={uploading}
                >
                    {Translate(lang, "global.uploading")}
                </button>
            ) : null}
        </div>
    );
}

function getPreview(file) {
    return new Promise((resolve) => {
        if (file && file.type.includes("image")) {
            resolve(imagesIcon);
        } else {
            resolve(filesIcon);
        }
    });
}
