import ft from '../../../components/mainStyles.module.scss';
import RightSideModal from './rightSideModal';
import Translate from '../../../components/lang';
import Loading from '../../../components/loading';
import { svg } from '../../../components/icons';
import { APIFile } from '../../../services/api';
import RightSideEdit from './rightSideEdit/rightSideEdit';
import { useState } from 'react';

export default function LeftSide(props) {
	const [show, setShow] = useState(false),
		[editData, setEditData] = useState([]),
		[id, setId] = useState(null),
		[showEdit, setShowEdit] = useState(false),
		[loading, setLoading] = useState(false),
		{
			lang,
			periods,
			selPer,
			markingStarted,
			data2,
			setData2,
			// setLoading,
			// loading,
		} = props;
	return (
		<div className='p-0'>
			<div className={ft.content__head}>
				<div className={ft.table}>
					<table className={`${ft.table__main}`} style={{ fontSize: '13px' }}>
						<thead>
							<tr>
								<td>#</td>
								<td>{Translate(lang, 'rightSideTab.meaningTask')}</td>
								<td>{Translate(lang, 'rightSideTab.duration')}</td>
								<td>{Translate(lang, 'global.fileName')}</td>
								<td>{Translate(lang, 'rightSideTab.file')}</td>
								<td>{Translate(lang, 'rightSideTab.dateCom')}</td>
								<td>{Translate(lang, 'rightSideTab.ijroFile')}</td>
								<td>{Translate(lang, 'rightSideTab.status')}</td>
								{periods[selPer]?.status === 1 ? (
									markingStarted?.status === 1 ? null : (
										<td className='text-center'>{Translate(lang, 'global.amal')}</td>
									)
								) : null}
							</tr>
						</thead>
						<tbody>
							{loading ? (
								<Loading className='mt-2' big={true} size='md' />
							) : data2?.data?.length ? (
								data2?.data?.map((item, index) => (
									<tr>
										<td>{index + 1}</td>
										<td>{item?.description}</td>
										<td>
											{item?.deadline
												?.toString()
												.split('')
												.slice(0, 10)
												.join('')
												.split('-')
												.reverse()
												.join('.')}{' '}
										</td>

										<td>
											{/* {item?.files?.map((file) => ( */}
											<p> {item?.docName} </p>
											{/* ))} */}
										</td>

										<td>
											{item?.files?.map((file) => (
												<a
													target='_blank'
													rel='noreferrer'
													href={`${APIFile}${file.path}`}
													style={{ display: 'block' }}>
													{file?.path?.slice(15)} ({file?.size}mb) {svg}
												</a>
											))}
										</td>
										<td>
											{item?.complete
												? item?.complete
														?.toString()
														.split('')
														.slice(0, 10)
														.join('')
														.split('-')
														.reverse()
														.join('.')
												: '---'}
										</td>
										<td>
											{item?.respondFiles?.length
												? item?.respondFiles?.map((file) => (
														<a
															target='_blank'
															rel='noreferrer'
															href={`${APIFile}${file.path}`}
															style={{ display: 'block' }}>
															{file?.path?.slice(15)} ({file?.size}mb) {svg}
														</a>
												  ))
												: '---'}
										</td>
										<td>
											{item?.status === 0 ? (
												<span
													style={{
														color: '#ff5252',
														fontWeight: 500,
													}}
													className={ft.isNotDone}>
													{Translate(lang, 'home.unfulfilled')}
												</span>
											) : item?.status === 1 ? (
												<span
													style={{
														color: '#FFBA52',
														fontWeight: 500,
													}}
													className={ft.isWaiting}>
													{Translate(lang, 'tasks.sent')}
												</span>
											) : item?.status === 2 ? (
												<span
													span
													style={{
														color: '#07a287',
														fontWeight: 500,
													}}
													className={ft.isDone}>
													{Translate(lang, 'tasks.accepted')}
												</span>
											) : (
												<span
													style={{
														color: '#ff5252',
														fontWeight: 500,
													}}
													className={ft.isNotDone}>
													{Translate(lang, 'tasks.unaccepted')}
												</span>
											)}
										</td>
										{periods[selPer]?.status === 1 ? (
											markingStarted?.status === 1 ? null : (
												<td>
													<div className='text-center'>
														{item?.status === 0 ? (
															<button
																style={{ fontSize: '13px' }}
																className={ft.info__btn}
																onClick={() => {
																	setShow(true);
																	setId(item?._id);
																}}>
																{Translate(lang, 'global.do')}
															</button>
														) : item?.status === 1 ? (
															<button
																style={{ fontSize: '13px' }}
																className={ft.info__btn}
																onClick={() => {
																	setShowEdit(true);
																	setEditData(item);
																	setId(item?._id);
																}}>
																{Translate(lang, 'global.edit')}
															</button>
														) : item?.status === 2 ? (
															'---'
														) : (
															'---'
														)}
													</div>
												</td>
											)
										) : null}
									</tr>
								))
							) : (
								<h6> </h6>
							)}
						</tbody>
					</table>
					{!loading && !data2?.data?.length && (
						<h6 className={ft.noData}> {Translate(lang, 'global.rightSideNotFound')}</h6>
					)}
				</div>
			</div>

			<RightSideModal
				id={id}
				show={show}
				lang={lang}
				periods={periods}
				selPer={selPer}
				setData2={setData2}
				setShow={setShow}
				setLoading={setLoading}
			/>

			<RightSideEdit
				id={id}
				lang={lang}
				show={showEdit}
				data={editData}
				periods={periods}
				selPer={selPer}
				setData2={setData2}
				setShow={setShowEdit}
				setLoading={setLoading}
			/>
		</div>
	);
}
