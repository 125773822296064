import axiosInstance from './api'

const api = '/auth/users/';

export const authApi = {
	login: (data) => {
		return axiosInstance.post(`${api}login`, data)
	},
	userMe: (headers) => axiosInstance.get(`${api}me`, headers),
}

