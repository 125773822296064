import axiosInstance from '../api';

const api = '/doc-statistics';

const	headers = {
	headers: {
		Authorization: `Bearer ${sessionStorage.getItem('token')}`
	}
};

export const docStatisticApi = {
	getBadge: () => axiosInstance.get(`${api}/badge`, headers),
}
