import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';

import { propagandaDayApi } from '../../../services/propagandaDayService';

import Translate from '../../../components/lang';
import Loading from '../../../components/loading';
import CustomModal from '../../../components/customModal';
import UploadFiles from '../../../components/uploadFiles';

import ft from '../../../components/mainStyles.module.scss';

const Update = ({ lang, show, setShow, editData, getList }) => {
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [errFiles, setErrFiles] = useState(false);
  const [prots, setProts] = useState([]);
  const [selFiles, setSelFiles] = useState([]);
  const [newFiles, setNewFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const onClose = () => {
    setShow(false);
    setErrFiles(false);
    setProts([]);
    setNewFiles([]);
    setUploadedFiles([]);
  }

  const onSubmit = (e) => {
    e.preventDefault();

    const { topic, shape } = e.target.elements;

    const files = []
    selFiles.map(file => {
        if (file.success) {
            files.push({ file: file._id, path: file.path, size: file.size });
        }
    })
    uploadedFiles.map(file => {
        files.push({ file: file._id, path: file.path, size: file.size });
    });

    const data = {
       topic: topic.value,
       shape: shape.value,
       files: files
    };

    setLoading(true);

    propagandaDayApi
       .update(editData?._id, data)
       .then(res => {
         if (res.data.success) {
           onClose();
           getList();
         }
       })
       .catch(err => {
           console.log(err);
       })
       .finally(() => {
         setLoading(false);
       });
  };

  useEffect(() => {
    const FILES = [];

    editData?.files?.forEach(file => {
      FILES.push({
        _id: file._id,
        name: file.path.slice(15),
        file: file.file,
        path: file.path,
        success: true
      });
    });

    setSelFiles(FILES);
  }, [editData]);

  return (
     <CustomModal
        title={Translate(lang, "global.edit")}
        setShow={setShow}
        show={show}
        size='md'
     >
       <Form className={ft.form} onSubmit={onSubmit}>
         <Form.Label className='w-100'>
           <div className={ft.lab}>
             {Translate(lang, "global.topic")}
           </div>
           <Form.Control
              name='topic'
              defaultValue={editData?.topic}
              type='text'
              required
           />
         </Form.Label>

         <Form.Label className='w-100'>
           <div className={ft.lab}>
             {Translate(lang, "global.shape")}
           </div>
           <Form.Control
              name='shape'
              defaultValue={editData?.shape}
              type="text"
              required
           />
         </Form.Label>

         <Form.Label>
           <div className={ft.lab}>{Translate(lang, 'doc.files')}</div>
           <UploadFiles
             setUploadedFiles={setUploadedFiles}
             setNewFiles={setNewFiles}
             setErrFiles={setErrFiles}
             selFiles={selFiles}
             setProts={setProts}
             newFiles={newFiles}
             setLoad={setLoad}
             prots={prots}
             load={load}
             lang={lang}
           />
           {errFiles && (
             <span className={ft.err__mes}>
               {Translate(lang, 'global.notUploaded')}
             </span>
           )}
         </Form.Label>

         <div className="d-flex justify-content-end mt-2">
           <button
              className={`${ft.close__btn} mr-2`}
              type='button'
              onClick={onClose}
           >
             {Translate(lang, "global.cancel")}
           </button>

           <button className={ft.save__btn} type='submit'>
             {Translate(lang, 'global.save')}{' '}
             {loading ? <Loading cl='ml-1' size='sm' /> : ''}
           </button>
         </div>
       </Form>
     </CustomModal>
  );
};

export default React.memo(
   Update,
  (prevProps, nextProps) => nextProps.show === prevProps.show
);
