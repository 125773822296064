import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { CountUp } from 'use-count-up';
import { easeQuadInOut } from 'd3-ease';
import 'react-circular-progressbar/dist/styles.css';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

import { chevronDown, chevronUp, equal } from '../../components/icons';

import { homeApi } from '../../services/homeService';
import { taskApi } from '../../services/taskService';
import { advicesApi } from '../../services/advicesService';

import { SortReyt } from '../../utils';

import HomeCard from './homeCard';
import Loading from '../../components/loading';
import Translate from '.././../components/lang';
import AnimatedProgressProvider from '../../components/progresBar/index';

import st from './home.module.scss';
import ft from '../../components/mainStyles.module.scss';

export default function Home(props) {
	const { lang, user, periods, selPer, markingStarted } = props;
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);
	const [data2, setData2] = useState([]);
	const [newTask, setNewTask] = useState(0);
	const [advice, setAdvice] = useState(0);

	let rating;
	const temp = {};

	useEffect(() => {
		setLoading(true);

		homeApi
			.all({
				period: periods[selPer]?._id,
				start: periods[selPer]?.start?.slice(0, 10),
				end: periods[selPer]?.end?.slice(0, 10),
			})
			.then(res => {
				const filterData = {
					page: 1,
					limit: 10000,
					period: periods[selPer]?._id,
					start: periods[selPer]?.start,
					end: periods[selPer]?.end,
					type: user.organizationType
				};

				homeApi
					.reyting(filterData)
					.then(res => {
						if (res.data.success) {
							if (res.data.data && res.data.data.length) {
								const DATA = SortReyt(res.data.data, res.data.oldData);
								setData2(DATA.slice(0, 10));
							} else {
								setData2([]);
							}
						}

						taskApi
							.all({
								limit: 10000,
								page: 1,
								start: periods[selPer]?.start?.slice(0, 10),
								end: periods[selPer]?.end?.slice(0, 10),
								period:
									periods?.length === 1
										? periods[0]?._id
										: markingStarted?.status === 1
										? periods[1]?._id
										: periods[selPer]?._id,
							})
							.then(res => {
								const DATA = res.data.data;
								let temp = 0;
								DATA.forEach(item => {
									if (item?.status === 0) {
										temp += 1;
									}
								});
								setNewTask(temp);

								setLoading(false);
							})
							.catch(e => {
								setLoading(false);
								console.log(e.message);
							});

						advicesApi.all()
							.then(res => {
								const DATA = res.data.data;
								let temp = 0;
								DATA?.forEach(d => {
									if (d?.status === false) temp += 1;
								});
								setAdvice(temp);
							});
						})
					.catch(e => {
						setLoading(false);
						console.log(e.message);
					});

				setData(res.data.data);
			})
			.catch(e => {
				console.log(e);
				setLoading(false);
			});
	}, []);

	let place;

	data2?.map((item, index) => {
		if (item?.id === user?._id) {
			rating = item;
			place = index + 1;
		}
	});

	const Progress = (props) => {
		const [style, setStyle] = useState({});

		setTimeout(() => {
			const newStyle = {
				width: `${props.done}%`,
			};
			setStyle(newStyle);
		}, 200);

		return (
			<div className={st.progress}>
				<div className={st.progress__bar} style={{ ...style, backgroundColor: `${props.color}` }}>
					<div className={st.progress__value} style={{ backgroundColor: `${props.color}` }}>
						<span>{props.done}</span>
					</div>
				</div>
			</div>
		);
	};

	if (temp) {
		temp.bandSuccess = 0;
		temp.code = [];
		data?.bands?.forEach((d) => {
			if ((d.files.length || d?.code > 0) && !temp?.code?.filter((t) => t === d?.code).length) {
				temp.bandSuccess += 1;
				temp.code.push(d.code);
			}
		});

		temp.bandNotSuccess = 13 - temp.bandSuccess;
	}

	const infos =
		place <= 10
			? [
					{
						title: `${Translate(lang, 'global.rating')}`,
						label:
							periods?.length === 1
								? null
								: `${place ? place + '-' + Translate(lang, 'home.place') : ''} `,
						itemInfo: [
							{
								label: `${Translate(lang, 'home.events')}`,
								number: (
									<CountUp
										isCounting
										start={0}
										end={
											periods?.length === 1
												? 0
												: rating?.right
												? rating?.total - rating?.right
												: rating?.total
												? rating?.total
												: 0
										}
										duration={1}
										easing='linear'
									/>
								),
							},
							{
								label: `${Translate(lang, 'navbar.tasks')}`,
								number: (
									<CountUp
										isCounting
										duration={1}
										easing='linear'
										start={0}
										end={periods?.length === 1 ? 0 : rating?.right ? rating?.right : 0}
									/>
								),
							},
							{
								label: `${Translate(lang, 'home.total')}`,
								number: (
									<CountUp
										isCounting
										duration={1}
										easing='linear'
										start={0}
										end={periods?.length === 1 ? 0 : rating?.total ? rating?.total : 0}
									/>
								),
							},
						],
					},
					{
						title: `${Translate(lang, 'home.busy')}`,
						label: null,
						itemInfo: [
							{
								label: `${Translate(lang, 'home.completed')}`,
								number: (
									<CountUp
										isCounting
										duration={1}
										easing='linear'
										start={0}
										end={temp?.bandSuccess !== 0 ? temp?.bandSuccess : 0}
									/>
								),
							},
							{
								label: `${Translate(lang, 'home.uncompleted')}`,
								number: (
									<CountUp
										isCounting
										duration={1}
										easing='linear'
										start={0}
										end={temp?.bandNotSuccess ? temp?.bandNotSuccess : 0}
									/>
								),
							},
							{
								label: `${Translate(lang, 'home.all')}`,
								number: <CountUp isCounting duration={1} easing='linear' start={0} end={13} />,
							},
						],
					},
					{
						title: `${Translate(lang, 'navbar.tasks')}`,
						label: newTask ? `${newTask} ${Translate(lang, 'home.newTask')}` : '',
						itemInfo: [
							{
								label: `${Translate(lang, 'home.accepted')}`,
								number: (
									<CountUp
										isCounting
										duration={1}
										easing='linear'
										start={0}
										end={data?.count1 ? data?.count1 : 0}
									/>
								),
							},
							{
								label: `${Translate(lang, 'home.notAccepted')}`,
								number: (
									<CountUp
										isCounting
										duration={1}
										easing='linear'
										start={0}
										end={data?.count ? data?.count - (data?.count1 ? data?.count1 : 0) : 0}
									/>
								),
							},
							{
								label: `${Translate(lang, 'home.total')}`,
								number: (
									<CountUp
										isCounting
										duration={1}
										easing='linear'
										start={0}
										end={data?.count ? data?.count : 0}
									/>
								),
							},
						],
					},
					{
						title: `${Translate(lang, 'navbar.advices')}`,
						label: advice ? `${advice} ${Translate(lang, 'home.newAdvice')}` : '',
						itemInfo: [
							{
								label: `${Translate(lang, 'home.seen')}`,
								number: (
									<CountUp
										isCounting
										duration={1}
										easing='linear'
										start={0}
										end={data.rec ? data?.rec - data?.rec1 : 0}
									/>
								),
							},
							{
								label: `${Translate(lang, 'home.unseen')}`,
								number: (
									<CountUp
										isCounting
										duration={1}
										easing='linear'
										start={0}
										end={data.rec1 ? data?.rec1 : 0}
									/>
								),
							},
							{
								label: `${Translate(lang, 'home.total')}`,
								number: (
									<CountUp
										isCounting
										duration={1}
										easing='linear'
										start={0}
										end={data.rec ? data?.rec : 0}
									/>
								),
							},
						],
					},
			  ]
			: [
					{
						title: `${Translate(lang, 'global.rating')}`,
						itemInfo: [
							{
								label: `${Translate(lang, 'home.events')}`,
								number: (
									<CountUp
										isCounting
										start={0}
										end={periods?.length === 1 ? 0 : rating?.res?.total ? rating?.res?.total : 0}
										duration={1}
										easing='linear'
									/>
								),
							},
							{
								label: `${Translate(lang, 'navbar.tasks')}`,
								number: (
									<CountUp
										isCounting
										duration={1}
										easing='linear'
										start={0}
										end={
											periods?.length === 1
												? 0
												: rating?.res?.right?._id
												? rating?.res?.right?._id
												: 0
										}
									/>
								),
							},
							{
								label: `${Translate(lang, 'home.total')}`,
								number: (
									<CountUp
										isCounting
										duration={1}
										easing='linear'
										start={0}
										end={
											periods?.length === 1
												? 0
												: rating?.res?.total
												? rating?.res?.total
													? rating?.res?.total
													: 0 + rating?.res?.right._id
													? rating?.res?.right._id
													: 0
												: 0
										}
									/>
								),
							},
						],
					},
					{
						title: `${Translate(lang, 'home.busy')}`,
						label: null,
						itemInfo: [
							{
								label: `${Translate(lang, 'home.completed')}`,
								number: (
									<CountUp
										isCounting
										duration={1}
										easing='linear'
										start={0}
										end={temp?.bandSuccess !== 0 ? temp?.bandSuccess : 0}
									/>
								),
							},
							{
								label: `${Translate(lang, 'home.uncompleted')}`,
								number: (
									<CountUp
										isCounting
										duration={1}
										easing='linear'
										start={0}
										end={temp?.bandNotSuccess ? temp?.bandNotSuccess : 0}
									/>
								),
							},
							{
								label: `${Translate(lang, 'home.all')}`,
								number: <CountUp isCounting duration={1} easing='linear' start={0} end={13} />,
							},
						],
					},
					{
						title: `${Translate(lang, 'navbar.tasks')}`,
						label: newTask ? `${newTask} ${Translate(lang, 'home.newTask')}` : '',
						itemInfo: [
							{
								label: `${Translate(lang, 'home.accepted')}`,
								number: (
									<CountUp
										isCounting
										duration={1}
										easing='linear'
										start={0}
										end={data?.count1 ? data?.count1 : 0}
									/>
								),
							},
							{
								label: `${Translate(lang, 'home.notAccepted')}`,
								number: (
									<CountUp
										isCounting
										duration={1}
										easing='linear'
										start={0}
										end={data?.count ? data?.count - (data?.count1 ? data?.count1 : 0) : 0}
									/>
								),
							},
							{
								label: `${Translate(lang, 'home.total')}`,
								number: (
									<CountUp
										isCounting
										duration={1}
										easing='linear'
										start={0}
										end={data?.count ? data?.count : 0}
									/>
								),
							},
						],
					},
					{
						title: `${Translate(lang, 'navbar.advices')}`,
						label: advice ? `${advice} ${Translate(lang, 'home.newAdvice')}` : '',
						itemInfo: [
							{
								label: `${Translate(lang, 'home.seen')}`,
								number: (
									<CountUp
										isCounting
										duration={1}
										easing='linear'
										start={0}
										end={data.rec ? data?.rec - data?.rec1 : 0}
									/>
								),
							},
							{
								label: `${Translate(lang, 'home.unseen')}`,
								number: (
									<CountUp
										isCounting
										duration={1}
										easing='linear'
										start={0}
										end={data.rec1 ? data?.rec1 : 0}
									/>
								),
							},
							{
								label: `${Translate(lang, 'home.total')}`,
								number: (
									<CountUp
										isCounting
										duration={1}
										easing='linear'
										start={0}
										end={data.rec ? data?.rec : 0}
									/>
								),
							},
						],
					},
			  ];

	let homeCard = infos.map((info, index) => (
		<HomeCard key={index} title={info?.title} label={info?.label} index={index} itemInfo={info?.itemInfo} />
	));

	if (loading)
		return (
			<div
				className='w-100 d-flex align-items-center justify-content-center'
				style={{ height: 'calc(100vh - 90px)' }}>
				<Loading size='lg' big={true} />
			</div>
		);

	return (
		<div className={`${st.home}`}>
			<div className={`${st.home__info}`}>{homeCard}</div>
			<div className={st.home__body}>
				<div className={st.body__card}>
					<div className='d-flex justify-content-between align-items-center'>
						<h6 className={st.card__title}>{Translate(lang, 'home.highReitedOrg')}</h6>

						<button className={ft.primary__btn}>
							<Link to='/rating'>
								{Translate(lang, 'home.seeAll')}
							</Link>
						</button>
					</div>

					<div className={`${ft.table} overflow-hidden`}>
						<table className={`${ft.table__main} ${st.table}`}>
							<thead>
								<tr>
									<td className={st.home__table__header}>#</td>
									<td className={st.home__table__header}>{Translate(lang, 'home.orgName')}</td>
									<td className={st.home__table__header} style={{ width: '50%' }}>
										{Translate(lang, 'home.earnedPoint')}
									</td>
								</tr>
							</thead>

							<tbody>
								{!loading ?
									data2?.map((item, index) => (
										<tr key={index} className={st.home__table__body}>
											<td>{index + 1}</td>
											<td>
												{item.placeIcon === 'up' ? chevronUp : item.placeIcon === 'down' ? chevronDown : equal}
												{item?.organization[0]?.name?.uz}
											</td>
											<td>
												<Progress
													done={
														periods?.length === 1
															? 0
															: item?.totalBall
																? item?.totalBall
																: 0
													}
													color={
														index + 1 < 4
															? '#07a287'
															: index + 1 < 8 && index + 1 >= 4
																? '#0099B5'
																: '#FF9F9F'
													}
												/>
											</td>
										</tr>))
									: ''
								}
							</tbody>
						</table>
					</div>
				</div>

				<div className={st.right__card}>
					<h6 className={st.card__title}>{Translate(lang, 'home.earnedPointEvent')}</h6>
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							flexDirection: 'column',
							alignItems: 'center',
						}}>
						<div className={st.circle}>
							<AnimatedProgressProvider
								valueStart={0}
								valueEnd={
									periods?.length === 1
										? 0
										: place
										? ((rating?.total - (rating?.right || rating?.right > -1 ? rating.right : 0)) /
												(rating?.right || rating?.right > -1 ? 70 : 100)) *
										  100
										: 0
								}
								duration={1.4}
								easingFunction={easeQuadInOut}>
								{(value) => {
									const roundedValue = Math.round(value);
									console.log(roundedValue || roundedValue > -1 ? roundedValue : 0);
									return (
										<CircularProgressbar
											value={value || value > -1 ? value : 0}
											text={`${roundedValue || roundedValue > -1 ? roundedValue : 0}%`}
											styles={buildStyles({
												pathTransition: 'none',
												pathColor: `#5855D6`,
												textColor: '#5855D6',
												trailColor: '#d6d6d6',
											})}
										/>
									);
								}}
							</AnimatedProgressProvider>
						</div>
						<h6 className='text-center mt-4'>{Translate(lang, 'home.legalEvents1')}</h6>
					</div>
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							flexDirection: 'column',
							alignItems: 'center',
							marginTop: '30px',
						}}>
						<div className={st.circle}>
							<AnimatedProgressProvider
								valueStart={0}
								valueEnd={periods?.length === 1 ? 0 : rating?.right ? rating?.right / 0.3 : 0}
								duration={1.4}
								easingFunction={easeQuadInOut}>
								{(value) => {
									const roundedValue = Math.round(value);
									return (
										<CircularProgressbar
											value={value}
											text={`${roundedValue}%`}
											styles={buildStyles({
												pathTransition: 'none',
												pathColor: '#EF5DA8',
												textColor: '#EF5DA8',
												trailColor: '#d6d6d6',
											})}
										/>
									);
								}}
							</AnimatedProgressProvider>
						</div>
						<h6 className='text-center mt-4'>{Translate(lang, 'home.legalEvents2')}</h6>
					</div>
				</div>
			</div>
		</div>
	);
}
