import { Modal } from "react-bootstrap";
import st from "./customModal.module.scss";
import { modalIcon } from "../icons";

export default function CustomModal(props) {
  const { show, setShow, title, size, children, lg, del, noHead } = props;

  return (
    <Modal
      size={size}
      show={show}
      backdrop="static"
      onHide={() => setShow(!show)}
      className={del ? "d-flex align-items-center" : ""}
      style={del ? { height: "100vh" } : {}}
    >
      <Modal.Header
        className={`align-items-center justify-content-between ${
          del ? "d-none" : "d-flex"
        }`}
      >
        <h5
          className={`m-0 ${noHead ? "d-none" : "d-block"}`}
          style={lg ? { fontSize: "24px" } : { fontSize: "20px" }}
        >
          {title}
        </h5>
        <span
          style={{ cursor: "pointer" }}
          onClick={() => {
            setShow(false);
          }}
        >
          {modalIcon}
        </span>
      </Modal.Header>
      <Modal.Body className={st.modalBody} style={{ height: "fit-content" }}>
        {children}
      </Modal.Body>
      <style jsx>
        {`
          h5 {
            color: #07a287;
          }
        `}
      </style>
    </Modal>
  );
}
